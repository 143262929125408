<template>
  <PageLayout @refreshData="refreshData">
    <template v-slot:header>
      <PageHeader :with-back-button="true" :title="$t('news.news')">
        <template v-slot:action>
          <IonIcon
            @click="
              () => {
                filterModal.active = true
              }
            "
            class="ion-nav-icon"
            src="/assets/icon/filter.svg"
          ></IonIcon>
        </template>
      </PageHeader>
    </template>

    <IonRow>
      <IonCol size="12">
        <BaseField v-model="search" :placeholder="$t('news.search_news')">
          <template v-slot:append>
            <IonIcon @click="getData" color="grey" src="/assets/icon/search.svg" />
          </template>
        </BaseField>
      </IonCol>
    </IonRow>
    <div v-if="!!newsArr.length">
      <h5>{{ $t('news.lastest') }}</h5>

      <ContentCard
        v-for="(news, i) in newsArr"
        :key="i"
        :data="news"
        @click="newsCardClicked(news)"
      />
      <IonRow v-if="ionInfiniteDisable" class="ion-justify-content-center pt-5 pb-12">
        <IonCol size="auto">
          <p class="text-grey-smoke">{{ $t('news.thats_all_now') }}</p>
        </IonCol>
      </IonRow>
      <IonInfiniteScroll
        @ionInfinite="loadData"
        threshold="1px"
        id="infinite-scroll"
        :disabled="ionInfiniteDisable"
      >
        <IonInfiniteScrollContent loadingSpinner="crescent"> </IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </div>
    <div v-else>
      <EmptyListPlaceHolder :content="$t('label.data_not_found')" />
    </div>

    <Filter
      :isOpen="filterModal.active"
      transKey="news"
      :title="$t('label.filter')"
      :filter-list-arr="filterModal.list"
      :filter-content="filterModal.content"
      :on-cancel="canceled"
      :on-submit="filterSubmitted"
    />
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { PageLayout, PageHeader } from '@/components/Layout'
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonItem,
  IonIcon,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonModal,
  IonContent
} from '@ionic/vue'

import BaseField from '@/components/fields/BaseField.vue'
import Card from '@/components/Card/Card.vue'
import CardImages from '@/components/image/CardImages.vue'
import axiosNoLoader from '@/http-no-loading'
import Filter from '@/components/filters/Filter.vue'
import ContentCard from '@/components/content/ContentCard.vue'
import EmptyListPlaceHolder from '@/components/EmptyListPlaceHolder.vue'
class Props {}

@Component({
  components: {
    EmptyListPlaceHolder,
    Filter,
    CardImages,
    Card,
    BaseField,
    PageHeader,
    PageLayout,
    IonGrid,
    IonButton,
    IonRow,
    IonCol,
    IonInput,
    IonItem,
    IonIcon,
    IonText,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonModal,
    IonContent,
    ContentCard
  },
  props: []
})
export default class NewsHomePage extends Vue.with(Props) {
  search = ''
  newsArr = [] as {
    id: string
    title: string
    content: string
    image: string[]
    projectId: number
    userId: number
    projectNameTh: string
    projectNameEn: string
    createdAt: string
    updatedAt: string
  }[]

  filterModal = {
    active: false,
    list: [
      // { name: 'news_types_filter', key: 'types', filters: [{ name: 'all', key: '*' }] },
      {
        name: 'date_filter',
        key: 'date',
        filters: [
          { name: 'today', key: 'today' },
          {
            name: 'yesterday',
            key: 'yesterday'
          },
          {
            name: 'last_7_days',
            key: 'last_7_days'
          },
          {
            name: 'last_14_days',
            key: 'last_14_days'
          },
          {
            name: 'last_30_days',
            key: 'last_30_days'
          },
          { name: 'all', key: 'all' }
        ]
      }
    ],
    content: { date: 'all', types: '*', orderField: 'createdAt', orderBy: 'desc' }
  }
  ionInfiniteDisable = false
  pagination = 0

  async mounted() {
    await this.getData()
    await this.markNewsRead()
  }

  getData() {
    this.pagination = 0
    this.$http
      .get('/news', {
        params: {
          ...this.filterModal.content,
          skip: this.pagination,
          search: this.search
        }
      })
      .then(res => {
        this.newsArr = res.data
      })
      .catch(err => {
        console.log(err)
      })
  }

  refreshData(e: any) {
    this.pagination = 0
    setTimeout(() => {
      axiosNoLoader
        .get('/news', {
          params: {
            ...this.filterModal.content,
            skip: this.pagination,
            search: this.search
          }
        })
        .then(res => {
          this.newsArr = res.data
        })
        .catch(err => {
          console.log(err)
        })
      e.target.complete()
    }, 1000)
    console.log(e)
  }

  loadData(e: any) {
    this.pagination += 1
    axiosNoLoader
      .get('/news', {
        params: {
          ...this.filterModal.content,
          skip: this.pagination * 20,
          search: this.search
        }
      })
      .then(res => {
        setTimeout(() => {
          this.ionInfiniteDisable = !res.data.length
          res.data.forEach((v: any) => {
            this.newsArr.push(v)
          })
          e.target.complete()
        }, 500)
      })
      .catch(err => {
        console.log(err)
      })
  }

  newsRender(content: string) {
    if (content) {
      return content.replace(/<[^>]*>/g, '')
    } else {
      return ''
    }
  }

  newsCardClicked(news: any) {
    this.$router.push({
      name: 'News Show',
      params: { id: news._id, data: JSON.stringify(news) }
    })
  }

  filterSubmitted(data: any) {
    this.filterModal.content = data
    this.getData()
    this.filterModal.active = false
  }

  canceled() {
    this.filterModal.active = false
  }
  async markNewsRead() {
    try {
      await this.$http.patch(`/capi/user/`, {
        newsViewCount: 0
      })
    } catch (e) {
      console.log('Failed', e)
    }
  }
}
</script>

<style lang="scss" scoped></style>
