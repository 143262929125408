
import { Options as Component, Vue } from 'vue-class-component'
import EmptyListPlaceHolder from '@/components/EmptyListPlaceHolder.vue'
import SeeDetails from '@/components/SeeDetails.vue'
import { BillCard } from '@/modules/bills'
import BillGroupCard from '@/modules/bills/BillGroupCard.vue'
import { IonRow, IonCol, IonButton } from '@ionic/vue'

class Props {
  invoices!: []
  desktop?: boolean | false
}
@Component({
  components: {
    BillCard,
    BillGroupCard,
    SeeDetails,
    EmptyListPlaceHolder,
    IonRow,
    IonCol,
    IonButton
  },
  props: ['invoices', 'desktop']
})
export default class QuickActionBlock extends Vue.with(Props) {
  cardHover = [] as boolean[]
  hoverHandler(i: number, isHover: boolean) {
    this.cardHover[i] = isHover
  }
}
