
import { Options as Component, Vue } from 'vue-class-component'
import { IonButton, IonIcon } from '@ionic/vue'
import { PageHeader } from '@/components/Layout'

class Props {
  invoice!: any
  route!: string
}

@Component({
  components: { IonButton, IonIcon, PageHeader },
  props: ['invoice', 'route']
})
export default class BillShowHeader extends Vue.with(Props) {}
