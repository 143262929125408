<template>
  <TermsModal
    id="privacy-policy"
    v-if="privacyAndPolicy"
    :title="$t('login.privacy_policy')"
    :contentEn="modal.contentEn"
    :contentTh="modal.contentTh"
    :forceRead="modal.forceRead"
    v-on:close="modal.isOpen = false"
    v-on:accept="acceptPolicy"
  />
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import TermsModal from '@/components/modals/TermsModal.vue'
import { getLogInDevice } from '@/utils/deviceUuid'

@Component({
  components: {
    TermsModal
  },
  props: []
})
export default class PrivacyPolicyModal extends Vue {
  privacyAndPolicy = null as any
  contentEn = ''
  contentTh = ''

  get modal() {
    return this.$store.state.modal.policy
  }

  mounted() {
    this.fetchLatestPolicy()
  }

  async fetchLatestPolicy() {
    await this.$http.get('/policy/latest').then(res => {
      const { privacyAndPolicy } = res.data
      this.privacyAndPolicy = privacyAndPolicy
      this.modal.contentEn = privacyAndPolicy.contentEn
      this.modal.contentTh = privacyAndPolicy.contentTh
    })
  }

  acceptPolicy() {
    const { uuid, source } = getLogInDevice()

    const params = {
      privacyAndPolicyId: this.privacyAndPolicy?._id,
      deviceId: uuid,
      deviceSource: source
    }

    this.$http.post('/policy/accept', params).catch(console.log)
  }
}
</script>
