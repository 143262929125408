
import { Options as Component, Vue } from 'vue-class-component'
import PageLayout from '@/components/Layout/PageLayout.vue'
import * as yup from 'yup'
import { Form, Field, ErrorMessage } from 'vee-validate'
import {
  IonRow,
  IonCol,
  IonCard,
  IonItem,
  IonLabel,
  IonInput,
  IonButton
} from '@ionic/vue'
class Props {}

@Component({
  components: {
    PageLayout,
    Form,
    Field,
    ErrorMessage,
    IonRow,
    IonCol,
    IonCard,
    IonItem,
    IonLabel,
    IonInput,
    IonButton
  },
  props: []
})
export default class Forms extends Vue.with(Props) {
  formData = {
    firstName: ''
  }
  formDataValidate = yup.object({
    firstName: yup.string().typeError('must be string').required('Required')
  })
  formSubmitted() {
    console.log('formSubmitted')
  }
}
