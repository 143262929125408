import { createI18n } from 'vue-i18n'
import * as en from './locales/en'
import * as th from './locales/th'

const languages = {
  en: en,
  th: th
}

const messages = Object.assign(languages)

export default createI18n({
  locale: 'th',
  fallbackLocale: 'th',
  messages,
  silentFallbackWarn: true
})
