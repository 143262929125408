
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonModal, IonContent, IonButton } from '@ionic/vue'

class Props {
  isOpen!: boolean
  title!: string
  filterListArr!: any[]
  filterContent!: any
  transKey?: string
  onCancel!: Function
  onSubmit!: Function
}

@Component({
  components: { IonRow, IonCol, IonModal, IonContent, IonButton },
  props: {
    isOpen: {
      type: Boolean
    },
    title: {
      type: String,
      default: ''
    },
    filterListArr: {
      type: Array,
      default: []
    },
    filterContent: {
      type: Array,
      default: {}
    },
    transKey: {
      type: String
    },
    onCancel: {
      type: Function,
      default: () => {}
    },
    onSubmit: {
      type: Function,
      default: () => {}
    }
  }
})
export default class Filter extends Vue.with(Props) {
  filterData = {} as any

  mounted() {
    this.filterData = this.filterContent
  }

  filterClicked(list: any, filter: any) {
    console.log(list, filter)
    this.filterData[list.key] = filter.key
  }
  submitBtnClicked() {
    this.onSubmit(this.filterData)
  }

  cancelBtnClicked() {
    this.onCancel()
  }
}
