<template>
  <FloatModal mode="alert">
    <template v-slot:content>
      <div class="content">
        <slot name="content" />
      </div>
    </template>
    <template v-slot:footer>
      <IonButton class="close-button my-3" color="medium" @click="$emit('close')">
        {{ $t('button.close') }}
      </IonButton>
    </template>
  </FloatModal>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import FloatModal from './FloatModal.vue'
import { IonButton } from '@ionic/vue'

@Component({
  components: { FloatModal, IonButton },
  emits: ['close']
})
export default class AlertModal extends Vue {}
</script>
<style scoped>
.content {
  padding: 5px 5px 5px 5px;
  color: #333333;
  font-size: 16px;
}
.close-button {
  font-size: 16px;
  width: 95px;
  height: 30px;
}
</style>
