
import { Options as Component, Vue } from 'vue-class-component'
import { IonItem, IonLabel, IonInput } from '@ionic/vue'

class Props {
  name?: string
  label?: string
  modelValue!: string
  placeholder?: string
  autocomplete?: string
  type?: string | 'text'
  inputmode?: string
  pattern?: string
  maxlength?: [string, number]
  required?: boolean | false
  autofocus?: boolean | false
}

@Component({
  components: { IonItem, IonLabel, IonInput },
  props: [
    'name',
    'label',
    'modelValue',
    'placeholder',
    'autocomplete',
    'type',
    'inputmode',
    'pattern',
    'maxlength',
    'required',
    'autofocus'
  ],
  emits: ['update:modelValue']
})
export default class BaseField extends Vue.with(Props) {
  changeValue(event: any) {
    this.$emit('update:modelValue', event.target.value)
  }
}
