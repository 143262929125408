<template>
  <IonRow class="ion-align-items-center fill-height">
    <IonCol size="12">
      <IonRow class="ion-justify-content-center">
        <IonCol size="auto">
          <IonText
            class="modal-title"
            v-for="(text, i) in $t('logout.logout_text').split('\n')"
            :key="i"
          >
            {{ text }}
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow class="ion-justify-content-center pt-2">
        <IonCol size="auto">
          <IonButton class="btn btn-cancel mx-2" color="medium" @click="closeModal">
            {{ $t('logout.button.cancel') }}
          </IonButton>
          <IonButton class="btn btn-logout mx-2" color="danger" @click="logoutSubmit">
            {{ $t('logout.button.logout') }}
          </IonButton>
        </IonCol>
      </IonRow>
    </IonCol>
  </IonRow>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { isPlatform } from '@ionic/vue'
import firebase from 'firebase/app'
import {
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonText,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonCardHeader,
  IonContent,
  toastController
} from '@ionic/vue'
import { FCM } from '@capacitor-community/fcm'
class Props {
  closeModal!: Function
}

@Component({
  components: {
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonText,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonCardHeader,
    IonContent
  },
  props: ['closeModal']
})
export default class ConfirmLogout extends Vue.with(Props) {
  async logoutSubmit() {
    try {
      if (isPlatform('android') || isPlatform('ios')) {
        try {
          const fcm = new FCM()
          const { token } = await fcm.getToken()
          const userRef = firebase
            .firestore()
            .collection('users')
            .doc(firebase.auth()!.currentUser!.uid)
          const docSnapshot = await userRef.get()
          let existingTokens = docSnapshot.get('registration_tokens')
          existingTokens = existingTokens.filter((e: string) => e !== token)
          const tokens = existingTokens
          await userRef.set(
            {
              registration_tokens: [...tokens]
            },
            {
              merge: true
            }
          )
        } catch (e) {
          console.log(e)
        }
      }
      await firebase.auth().signOut()
      this.closeModal()
      await this.$router.push('/login')
    } catch (e) {
      console.log('e', e)
      this.closeModal()
      await (
        await toastController.create({
          message: 'Logout failed. Please try again later.',
          color: 'danger',
          duration: 3000
        })
      ).present()
    }
  }
}
</script>

<style scoped lang="scss">
.modal-content {
  text-align: center;
  opacity: 1;
  width: 100%;
  height: 100%;
}
.modal-title {
  font: normal normal 300 14px/20px Prompt;
  letter-spacing: 0px;
  color: #333333;
}
.btn {
  top: 276px;
  left: 189px;
  width: 95px;
  height: 29px;
  text-align: center;
  font: normal normal normal 12px/18px Prompt;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 5px;
}
.btn-cancel {
  font-size: 14px;
  background-color: #afafaf;
}
.btn-logout {
  font-size: 14px;
  background-color: #d93838 !important;
}
</style>
