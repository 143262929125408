import store from '@/store'
import firebase from 'firebase/app'
import http from '@/http-no-firebase'
import { refreshSTSToken } from '@/utils/objectStorage'
export const login = async () => {
  return new Promise((resolve, reject) => {
    const auth = firebase.auth()
    auth.onAuthStateChanged(async user => {
      if (user) {
        try {
          const token = await user?.getIdToken()
          const tokenDetail = await user?.getIdTokenResult()
          store.state.firebase.token = token
          store.state.firebase.expiration = tokenDetail.expirationTime
          const userResponse = await http.get('/capi/user')
          const userData = userResponse.data
          store.state.user = userData

          store.state.permissions = userData.permissions
          store.state.navigationPermissions = userData.navigationPermissions
          await refreshSTSToken()
          resolve(true)
        } catch (e) {
          console.log('Error', e)
          resolve(false)
        }
      } else {
        console.log('no user')
        resolve(false)
      }
    })
  })
}
export const refreshFirebaseToken = () => {
  return new Promise((resolve, reject) => {
    if (
      !store.state.firebase.token ||
      new Date() > new Date(store.state.firebase.expiration)
    ) {
      login()
        .then(data => {
          resolve(data)
        })
        .catch(e => {
          reject(e)
        })
    } else {
      resolve(true)
    }
  })
}
