<template>
  <PageLayout>
    <template v-slot:header>
      <PageHeader :title="title" />
    </template>
    <!--project name + time-->
    <div class="mb-2 pt-3">
      <IonText color="primary">
        {{ content.project?.name || content.company?.name }}
      </IonText>
      <IonText class="text-grey"> - {{ DATE_TIME_RELATIVE(content.updatedAt) }} </IonText>
    </div>

    <!--title-->
    <h4 class="my-1 text-black">{{ contentRender(content.title) }}</h4>

    <!--content-->
    <p class="pa-0 ma-0 text-grey" v-html="content.content"></p>

    <h5 class="pb-3" v-if="content.imagePaths && content.imagePaths.length > 0">
      {{ $t('label.relative_image') }}
    </h5>
    <ImagesDialog :src="content.imagePaths" :title="title" />
    <div style="height: 80px"></div>
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonText } from '@ionic/vue'
import PageLayout from '@/components/Layout/PageLayout.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import ImagesDialog from '@/components/image/ImagesDialog.vue'
class Props {
  title!: string | ''
  content!: {
    id: string
    title: string
    content: string
    imagePaths: string[]
    projectId: number
    userId: number
    projectNameTh: string
    projectNameEn: string
    createdAt: string
    updatedAt: string
  }
}

@Component({
  components: { ImagesDialog, PageHeader, PageLayout, IonRow, IonCol, IonText },
  props: ['title', 'content']
})
export default class ContentShow extends Vue.with(Props) {
  contentRender(content: string) {
    if (content) {
      return content.replace(/<[^>]*>/g, '')
    } else {
      return ''
    }
  }
}
</script>

<style scoped lang="scss"></style>
