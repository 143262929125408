
import { Options as Component, Vue } from 'vue-class-component'
import { IonApp, IonRouterOutlet, IonFooter, toastController } from '@ionic/vue'
import AppModal from '@/components/modals/AppModal.vue'
import Loading from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
import FreshChat from '@/components/FreshChat.vue'
import { isPlatform } from '@ionic/vue'
import DialogMaintenance from '@/components/DialogMaintenance.vue'
import FloatModal from '@/components/modals/FloatModal.vue'
import ForceUpdateModal from '@/components/modals/ForceUpdateModal.vue'
import { ScreenOrientation } from '@ionic-native/screen-orientation'
import AlertModal from '@/components/modals/AlertModal.vue'
import { FCM } from '@capacitor-community/fcm'
import firebase from 'firebase'
@Component({
  components: {
    AlertModal,
    ForceUpdateModal,
    FloatModal,
    DialogMaintenance,
    IonApp,
    IonRouterOutlet,
    Loading,
    IonFooter,
    AppModal,
    FreshChat
  }
})
export default class App extends Vue {
  get freshChatData() {
    const { _id, isEnabled, freshChatToken, userTokens } =
      this.$store.state.currentProject?.freshChatIntegration || {}
    const userRestoreId = userTokens?.find(
      userToken => userToken?.userId == this.$store.state.user.id
    )?.restoreId
    return { _id, isEnabled, freshChatToken, userTokens, userRestoreId }
  }
  mounted() {
    // document.body.classList.toggle('dark', false)
    // document.body.classList.toggle('light', true)

    // StatusBar.overlaysWebView(true)
    // StatusBar.backgroundColorByHexString('#ffffff')
    // StatusBar.styleLightContent()
    const language = localStorage.getItem('language')
    if (language === 'th' || language === 'en') this.$i18n.locale = language
    //  lock screen orientation
    ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT)
  }
  isIos() {
    return isPlatform('ios')
  }

  async logout() {
    try {
      if (isPlatform('android') || isPlatform('ios')) {
        try {
          const fcm = new FCM()
          const { token } = await fcm.getToken()
          const userRef = firebase
            .firestore()
            .collection('users')
            .doc(firebase.auth()!.currentUser!.uid)
          const docSnapshot = await userRef.get()
          let existingTokens = docSnapshot.get('registration_tokens')
          existingTokens = existingTokens.filter((e: string) => e !== token)
          const tokens = existingTokens
          await userRef.set(
            {
              registration_tokens: [...tokens]
            },
            {
              merge: true
            }
          )
        } catch (e) {
          console.log(e)
        }
      }
      await firebase.auth().signOut()
      await this.$router.push('/login')
      this.$store.state.user = {
        id: -1,
        firstName: '',
        lastName: '',
        newsViewCount: 0,
        postViewCount: 0,
        profileImage: '/assets/default_profile_picture.svg',
        freshChatRestoreID: '',
        email: '',
        phoneNumber: '',
        userCompanyState: { isActive: true, id: '' }
      }
    } catch (e) {
      console.log('e', e)
      await (
        await toastController.create({
          message: 'Logout failed. Please try again later.',
          color: 'danger',
          duration: 3000
        })
      ).present()
    }
  }
}
