
import { Options as Component, Vue } from 'vue-class-component'
import RedDot from '@/components/badge/RedDot.vue'
import { IonRow, IonCol, IonRippleEffect, IonImg } from '@ionic/vue'

class Props {
  'name'!: string
  'src'!: string
  'srcHover'?: string
  'redDot'!: {
    count?: number | 0
    hidden?: boolean | true
  }
  'routerLink'!: string
  'disabled'!: boolean | false
  desktop!: boolean | false
}

@Component({
  components: { IonRow, IonCol, RedDot, IonRippleEffect, IonImg },
  props: ['name', 'src', 'srcHover', 'redDot', 'routerLink', 'disabled', 'desktop']
})
export default class HomeMenu extends Vue.with(Props) {
  hovered = false
  homeMenuClicked() {
    this.$router.push(this.routerLink)
  }
  getCount = () => this.redDot.count
}
