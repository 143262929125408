<template>
  <div class="empty-list-placeholder"> {{ content }} </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
class Props {
  content!: string
}
@Component({
  components: {},
  props: ['content']
})
export default class EmptyListPlaceHolder extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
.empty-list-placeholder {
  height: 100%;
  //background-color: #ffffff;
  margin: 100px 50px 100px 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-medium);
}
</style>
