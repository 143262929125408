
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonButton,
  IonCol,
  IonRow,
  IonItem,
  IonLabel,
  IonInput,
  IonGrid
} from '@ionic/vue'

import BaseField from '@/components/fields/BaseField.vue'

class Props {
  displayAmount!: number
}

@Component({
  components: {
    IonButton,
    IonCol,
    IonRow,
    IonItem,
    IonLabel,
    IonInput,
    BaseField,
    IonGrid
  },
  props: ['displayAmount'],
  emits: ['submit']
})
export default class GbPrimeCardForm extends Vue.with(Props) {
  form = {
    number: '',
    expirationMonth: '',
    expirationYear: '',
    securityCode: ''
  }

  handleSubmit(event: any) {
    event.preventDefault()
    this.$emit('submit', this.form)
  }
}
