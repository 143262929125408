<template>
  <IonCard :button="button" :key="id" class="project-card-wrapper" @click="onClick?.()">
    <div :style="backgroundStyle" class="background-wrapper">
      <div class="text-wrapper">
        <div>
          {{ title }}
        </div>
        <div>
          {{ subTitle }}
        </div>
      </div>
    </div>
  </IonCard>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'

import { IonCard } from '@ionic/vue'

class Props {
  id!: number
  title!: string
  subTitle!: string
  imagePath!: string
  onClick?: Function
  button?: any
}
@Component({
  components: { IonCard },
  props: ['id', 'title', 'subTitle', 'imagePath', 'onClick', 'button']
})
export default class ProjectCard extends Vue.with(Props) {
  get backgroundStyle() {
    const imagePath = this.imagePath
      ? this.GET_SECURED_IMAGE(this.imagePath)
      : '/assets/sky_logo.svg'

    return `background-image:
              linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(156,156,156,0) 80%),
              url(${imagePath})
            `
  }
}
</script>

<style scoped lang="scss">
.project-card-wrapper {
  height: 160px;
  position: relative;

  .background-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;

    .text-wrapper {
      position: absolute;
      width: 100%;
      padding: 16px;
      bottom: 0;
      font-size: 24px;
      color: white;
      .card-title {
        position: absolute;
        top: 36%;
        font-size: 2em;
        width: 100%;
        font-weight: bold;
        color: #fff;
      }

      .card-subtitle {
        font-size: 1em;
        position: absolute;
        top: 52%;
        width: 100%;
        color: #fff;
      }
    }
  }
}
</style>
