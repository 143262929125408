
import { Options as Component, Vue } from 'vue-class-component'

class Props {}

@Component({
  components: {},
  props: []
})
export default class SeeDetails extends Vue.with(Props) {}
