
import { Options as Component, Vue } from 'vue-class-component'

import { IonCard } from '@ionic/vue'

class Props {
  id!: number
  title!: string
  subTitle!: string
  imagePath!: string
  onClick?: Function
  button?: any
}
@Component({
  components: { IonCard },
  props: ['id', 'title', 'subTitle', 'imagePath', 'onClick', 'button']
})
export default class ProjectCard extends Vue.with(Props) {
  get backgroundStyle() {
    const imagePath = this.imagePath
      ? this.GET_SECURED_IMAGE(this.imagePath)
      : '/assets/sky_logo.svg'

    return `background-image:
              linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(156,156,156,0) 80%),
              url(${imagePath})
            `
  }
}
