
import { Options as Component, Vue } from 'vue-class-component'
import { PageHeader, PageLayout } from '@/components/Layout/'
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonList,
  IonRow,
  IonThumbnail,
  isPlatform
} from '@ionic/vue'
import { b64toBlob } from '@/utils/base64'
import BaseField from '@/components/fields/BaseField.vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as yup from 'yup'
import { CameraResultType, CameraSource, Filesystem, Plugins } from '@capacitor/core'

class Props {}

@Component({
  components: {
    BaseField,
    PageLayout,
    PageHeader,
    IonImg,
    IonItem,
    IonThumbnail,
    IonList,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    IonIcon,
    Form,
    Field,
    ErrorMessage
  },
  props: []
})
export default class Profile extends Vue.with(Props) {
  formDataValidate = yup.object({
    firstName: yup
      .string()
      .typeError('This field must be a string.')
      .required('This field is required.'),
    lastName: yup
      .string()
      .typeError('This field must be a string.')
      .required('This field is required.'),
    email: yup
      .string()
      .nullable()
      .email('This email is incorrect')
  })
  isEdit = false
  user = {
    profileImagePreview: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    displayName: ''
  }

  get isPlatformIos() {
    return isPlatform('ios')
  }

  get getImagePath() {
    if (this.user.profileImagePreview === '') return '/assets/default_profile_picture.svg'
    else if (this.user.profileImagePreview != this.$store.state.user.profileImage) {
      return 'data:image/jpeg;base64,' + this.user.profileImagePreview
    } else {
      return this.GET_SECURED_IMAGE(this.user.profileImagePreview)
    }
  }

  async getImage() {
    try {
      const { Camera } = Plugins
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Base64,
        source: CameraSource.Photos
      })
      if (image && image.base64String) {
        this.user.profileImagePreview = image.base64String
      }
    } catch (e) {
      console.log('getImage error', e)
    }
  }

  async handleSubmit() {
    const formData = new FormData()
    if (this.user.profileImagePreview != this.$store.state.user.profileImage) {
      const imageBff = await b64toBlob(this.user.profileImagePreview, 'image/jpeg')
      formData.append('image', imageBff)
    }
    formData.append('firstName', this.user.firstName)
    formData.append('lastName', this.user.lastName)
    formData.append('email', this.user.email)
    this.$http
      .patch('/capi/user', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        this.user.displayName = this.user.firstName
      })
      .finally(() => {
        this.isEdit = false
      })
  }

  mounted() {
    this.user = {
      profileImagePreview: this.$store.state.user.profileImage,
      firstName: this.$store.state.user.firstName,
      lastName: this.$store.state.user.lastName,
      email: this.$store.state.user.email,
      phoneNumber: this.$store.state.user.phoneNumber,
      displayName: this.$store.state.user.firstName
    }
  }
}
