<template>
  <HomePageDesktop
    v-if="$platform.is.desktop"
    :announcements="announcements"
    :check-disable-home-menu="checkDisableHomeMenu"
    :invoice-groups="invoiceGroups"
    :invoices="invoices"
    :menu-data="menuData"
  />

  <HomePageMobile
    v-else
    :announcements="announcements"
    :check-disable-home-menu="checkDisableHomeMenu"
    :invoice-groups="invoiceGroups"
    :invoices="invoices"
    :menu-data="menuData"
  />
</template>

<script lang="ts">
import { Options as Component, setup, Vue } from 'vue-class-component'
import { IonButton, IonCol, IonImg, IonRow } from '@ionic/vue'
import PageLayout from '@/components/Layout/PageLayout.vue'
import ProjectCard from '@/components/ProjectCard.vue'
import QuickActionBlock from '@/components/QuickActionBlock.vue'
import HomeMenu from '@/components/HomeMenu.vue'
import PushNotification from '@/components/PushNotification/PushNotification.vue'
import { reactive } from 'vue'
import AnnouncementSlide from '@/components/Announcement/AnnouncementSlide.vue'
import Profile from '@/components/ProfileBanner.vue'
import BottomNavigationMenu from '@/views/BottomNavigationMenu.vue'
import _ from 'lodash'
import DesktopPageLayout from '@/components/Layout/DesktopPageLayout.vue'
import HomePageDesktop from '@/components/page/home/HomePageDesktop.vue'
import HomePageMobile from '@/components/page/home/HomePageMobile.vue'

const useHomeMenu = () => {
  const countUnreadNotifications = reactive({
    postCount: 0,
    newsCount: 0,
    invoiceCount: 0
  })
  const menuList = reactive([
    {
      src: '/assets/icon/menu/invoice_icon_lb.png',
      srcHover: '/assets/icon/menu/invoice_icon_db.png',
      redDot: {
        count: 0
      },
      name: 'bill',
      routerLink: 'bills',
      rules: ['bill_read', 'bill_process']
    },
    {
      src: '/assets/icon/menu/news_icon_lb.png',
      srcHover: '/assets/icon/menu/news_icon_db.png',
      redDot: {
        count: 0
      },
      name: 'news',
      routerLink: '/news'
    },
    // {
    //   src: '/assets/icon/post_homeicons.png',
    //   redDot: {
    //     count: 0
    //   },
    //   name: 'post',
    //   routerLink: '/posts'
    // },
    // {
    //   src: '/assets/icon/visitors_homeicons.png',
    //   redDot: {
    //     count: 0
    //   },
    //   name: 'visitor',
    //   routerLink: '/visitors'
    // },
    {
      src: '/assets/icon/menu/maintenance_icon_lb.png',
      srcHover: '/assets/icon/menu/maintenance_icon_db.png',
      redDot: {
        count: 0
      },
      name: 'maintenance',
      routerLink: '/maintenance'
    },
    {
      src: '/assets/icon/menu/services_icon_lb.png',
      srcHover: '/assets/icon/menu/services_icon_db.png',
      redDot: {
        hidden: true
      },
      name: 'service',
      routerLink: '/services'
    }
    // {
    //   src: '/assets/icon/space_booking_homeicon.png',
    //   redDot: {
    //     count: 0
    //   },
    //   name: 'space_booking',
    //   routerLink: '/home'
    // }
  ])
  return { menuList, countUnreadNotifications }
}
class Props {
  $refs!: any
}
declare const window: any
@Component({
  components: {
    HomePageMobile,
    HomePageDesktop,
    DesktopPageLayout,
    BottomNavigationMenu,
    Profile,
    HomeMenu,
    QuickActionBlock,
    ProjectCard,
    PageLayout,
    IonRow,
    IonCol,
    PushNotification,
    AnnouncementSlide,
    IonButton,
    IonImg
  }
})
export default class Home extends Vue.with(Props) {
  async mounted() {
    console.log(this.$platform.is.desktop, '$platform.is.desktop')
    await this.getData()
  }

  displayRoutes = ['/home']
  announcements = []
  user = {
    profileImage: '',
    firstName: ''
  }
  invoices = []
  countUnreadMenuNotification = {
    postCount: 0,
    newsCount: 0,
    invoiceCount: 0
  }
  invoiceGroups: any = []

  menuData = setup(() => {
    return useHomeMenu()
  })
  get freshChat() {
    const { _id, isEnabled, freshChatToken, userTokens } =
      this.$store.state.currentProject?.freshChatIntegration || {}
    return {
      _id,
      isEnabled,
      freshChatToken,
      userTokens
    }
  }

  get userRestoreId() {
    return this.freshChat?.userTokens?.find(
      userToken => userToken?.userId == this.$store.state.user.id
    )?.restoreId
  }
  get freshChatData() {
    const { _id, isEnabled, freshChatToken, userTokens } =
      this.$store.state.currentProject?.freshChatIntegration || {}
    const userRestoreId = userTokens?.find(
      userToken => userToken?.userId == this.$store.state.user.id
    )?.restoreId
    return { _id, isEnabled, freshChatToken, userTokens, userRestoreId }
  }

  async getData() {
    return new Promise(resolve => {
      this.$http
        .all([
          this.$http
            .get('/invoice', {
              params: {
                isGrouped: 0,
                state: ['pending', 'overdue'],
                page: 1,
                perPage: 3,
                orderBy: 'asc',
                orderField: 'dueAt'
              }
            })
            .catch(console.log),
          this.$http
            .get('/invoice/group', {
              params: {
                state: ['pending', 'overdue'],
                page: 1,
                perPage: 3,
                orderBy: 'asc',
                orderField: 'dueAt'
              }
            })
            .catch(console.log),
          this.$http
            .get('/announcement', { params: { perPage: 5, highlighted: 1 } })
            .catch(console.log),
          this.$http.get('/capi/project').catch(console.log)
        ])
        .then(resArr => {
          //  set user
          this.user = this.$store.state.user

          //  set invoices
          if (resArr[0]) this.invoices = resArr[0].data?.data

          //  set invoice groups
          if (resArr[1]) this.invoiceGroups = resArr[1].data?.data

          //set announcement array
          if (resArr[2]) this.announcements = resArr[2].data

          //menu notification
          if (resArr[0]) {
            const { unreadInvoiceCount } = resArr[0].data
            this.menuData.menuList[0].redDot.count = unreadInvoiceCount
          }
          const { newsViewCount, postViewCount } = this.$store.state.user
          this.menuData.menuList[1].redDot.count = newsViewCount
          this.menuData.menuList[2].redDot.count = postViewCount

          // set current project & company
          if (resArr[3]) {
            const projects = resArr[3].data
            if (this.$store.state.currentProject?.id < 0) {
              this.$store.state.currentProject = projects[0]
              this.$store.state.currentCompany.id = projects[0]?.companyId
            }
          }
          resolve(true)
        })
    })
  }

  checkDisableHomeMenu(navigationPermissions: any, rules: any): boolean {
    return !_.intersection(navigationPermissions, rules).length
  }

  setShowSelectProjectModal() {
    console.log('setShowSelectProjectModal')
  }

  initFreshDesk() {
    window.fwSettings = {
      widget_id: 70000000510
    }
    const freshdesk = () => {
      if ('function' != typeof window.FreshworksWidget) {
        const n: any = function() {
          // eslint-disable-next-line prefer-rest-params
          n.q.push(arguments)
        }
        ;(n.q = []), (window.FreshworksWidget = n)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.home-page {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);

  .home-content {
    padding-bottom: 60px;
    --background: white url('/assets/img/content-bg.png') no-repeat 100%/100%;

    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .profile-box {
        display: flex;
        flex-direction: row;
        height: 50px;

        .profile-image {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .text-box {
          height: 50px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          h4 {
            margin: 0;
            color: #2baae1;
          }

          h6 {
            margin: 0;
            color: #61656a;
          }
        }
      }
      .select-project-button {
        width: 80px;
        height: 23px;
        background: #01b3bf 0% 0% no-repeat padding-box;
        border-radius: 5px;
        opacity: 1;
        font: normal normal normal 12px/30px Prompt;
        color: #ffffff;
      }
    }
  }

  .margin-top {
    margin-top: 20px;
  }

  .margin-side {
    margin-left: 10px;
    margin-right: 10px;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}
</style>
