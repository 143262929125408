<template>
  <PageLayout>
    <PageHeader :title="$t('maintenance.title')" :withBackButton="true">
      <template v-slot:action>
        <IonButton mode="md" fill="clear" :style="{ float: 'right' }">
          <IonIcon color="primary" icon="duplicate" />
        </IonButton>
      </template>
    </PageHeader>

    <iframe
      title="Feedback Form"
      class="freshwidget-embedded-form"
      id="freshwidget-embedded-form"
      src="https://kepm.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded"
      :style="`height: ${isIos() ? 'calc(100vh - 115px);' : 'calc(100vh - 65px);'}`"
      width="100%"
      scrolling="no"
      frameborder="0"
    >
    </iframe>
  </PageLayout>
</template>

<!--<script-->
<!--  type="text/javascript"-->
<!--  src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"-->
<!--&gt;</script>-->

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonIcon, isPlatform } from '@ionic/vue'
import { PageLayout, PageHeader } from '@/components/Layout/'
class Props {}

@Component({
  components: { PageLayout, PageHeader, IonIcon },
  props: []
})
export default class Maintenance extends Vue.with(Props) {
  isIos() {
    return isPlatform('ios')
  }

  checkIframeLoading() {
    const iframeEle = document.getElementById('freshwidget-embedded-form')
    iframeEle!.addEventListener('load', function() {
      iframeEle!.style.opacity = 1 as any
    })
  }
  mounted() {
    let e = null
    ;(e = document.createElement('script')).id = 'freshwidget-embedded-form'
    ;(e.async = !0),
      (e.src = 'https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js'),
      document.head.appendChild(e)
    this.checkIframeLoading()
  }
}
</script>

<style scoped lang="scss">
@import url('https://assets.freshdesk.com/widget/freshwidget.css');
@import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
//#freshwidget-embedded-form {
//  opacity: 0;
//  height: 600px;
//  width: 100%;
//}
</style>
