
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonPage,
  IonContent,
  IonGrid,
  IonRefresher,
  IonRefresherContent
} from '@ionic/vue'
import DesktopNavBar from '@/components/desktop/DesktopNavBar.vue'
import ProfileBanner from '@/components/ProfileBanner.vue'

class Props {
  background?: string
  isFullScreen?: {
    type: boolean
    default: false
  }
  isScrollY?: {
    type: boolean
    default: true
  }
}

@Component({
  components: {
    ProfileBanner,
    DesktopNavBar,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonPage,
    IonContent,
    IonGrid,
    IonRefresher,
    IonRefresherContent
  },
  props: ['background', 'isFullScreen', 'isScrollY']
})
export default class DesktopPageLayout extends Vue.with(Props) {
  get backgroundClass() {
    return this.background ? 'st-background-' + this.background : 'st-background-grey'
  }
  get getClass() {
    if (this.isFullScreen) return 'px-0'
    else return 'px-2'
  }
}
