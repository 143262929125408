import { Options as Component, Vue } from 'vue-class-component'
import { popoverController } from '@ionic/vue'
import SortByPopover from '@/components/SortByPopover.vue'

@Component({})
export default class SortBy extends Vue {
  get sortByPopover() {
    return {
      options: [
        {
          label: 'Option 1',
          value: '1'
        },
        {
          label: 'Option 2',
          value: '2'
        }
      ],
      optionValue: '1'
    }
  }

  async handleShowSortByPopover(ev: any) {
    let popover: any = await popoverController.create({
      showBackdrop: false,
      event: ev,
      translucent: true,
      component: SortByPopover,
      componentProps: {
        ...this.sortByPopover,
        handleSort: (orderField: string) => {
          popover.dismiss().then(() => (popover = null))
          this.handleSortBy(orderField)
        }
      }
    })
    return popover.present()
  }

  handleSortBy(orderField: string) {
    alert('Define "handleSortBy" function')
  }
}
