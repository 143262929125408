
import { Options as Component, Vue } from 'vue-class-component'
import PageLayout from '@/components/Layout/PageLayout.vue'

class Props {}

@Component({
  components: { PageLayout },
  props: []
})
export default class Posts extends Vue.with(Props) {
  async markPostRead() {
    try {
      await this.$http.patch(`/capi/user/${this.$store.state.user.id}`, {
        postViewCount: 0
      })
    } catch (e) {
      console.log('Failed', e)
    }
  }
  async mounted() {
    await this.markPostRead()
  }
}
