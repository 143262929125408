
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonCol,
  IonButton,
  IonGrid,
  IonCheckbox,
  isPlatform
} from '@ionic/vue'
import firebase from 'firebase/app'
import PageLayout from '@/components/Layout/PageLayout.vue'
import LogInErrorModal from '@/modules/authentication/LogInErrorModal.vue'
import { FirebaseX } from '@ionic-native/firebase-x'
import BaseField from '@/components/fields/BaseField.vue'

@Component({
  components: {
    BaseField,
    PageLayout,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonCol,
    IonButton,
    IonGrid,
    IonCheckbox,
    LogInErrorModal
  }
})
export default class Login extends Vue {
  termsAndPolicyAccepted = false
  policyModalOpen = false
  termsModalOpen = false
  phoneNumber = {
    number: ''
  } as any
  showErrorModal = false

  get policyModal() {
    return this.$store.state.modal.policy
  }

  get termsModal() {
    return this.$store.state.modal.terms
  }

  handleOpenPolicyModal() {
    this.policyModal.forceRead = false
    this.policyModal.isOpen = true
  }

  handleOpenTermsModal() {
    this.termsModal.forceRead = false
    this.termsModal.isOpen = true
  }

  phoneInputChanged(v: object) {
    this.phoneNumber = v
    this.$forceUpdate()
  }

  async login() {
    try {
      await this.$http.post('/capi/auth/check_phone_number', {
        phoneNumber: '+66' + this.phoneNumber.number
      })

      await new Promise(resolve => {
        if (isPlatform('ios') && isPlatform('cordova')) {
          const response = FirebaseX.verifyPhoneNumber(
            '+66' + this.phoneNumber.number,
            300000
          )
          resolve(response)
        } else {
          const response = firebase.auth().signInWithPhoneNumber(
            // const response = await FirebaseAuthentication.verifyPhoneNumber(
            '+66' + this.phoneNumber.number,
            new firebase.auth.RecaptchaVerifier('recaptcha-container', {
              size: 'invisible'
            })
          )
          resolve(response)
        }
      }).then((response: any) => {
        this.$router.push({
          path: '/verify_otp',
          query: {
            verificationId: response.verificationId,
            phoneNumber: this.phoneNumber.number
          }
        })
      })
    } catch (e) {
      console.log(e)
      alert(e)
      this.showErrorModal = true
    }
  }
}
