
import { Options as Component, Vue } from 'vue-class-component'
import FloatModal from './FloatModal.vue'
import { IonButton } from '@ionic/vue'

@Component({
  components: { FloatModal, IonButton },
  emits: ['close']
})
export default class ErrorModal extends Vue {}
