
import { Options as Component, Vue } from 'vue-class-component'

class Props {
  wrapperStyle?: string
  hidden?: boolean | false
  count!: number
}

@Component({
  components: {},
  props: ['hidden', 'wrapperStyle', 'count']
})
export default class RedDot extends Vue.with(Props) {}
