
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonModal, IonCard } from '@ionic/vue'

class Props {
  mode?: string | 'blank' | 'alert'
  size?: string | 'small' | 'large'
}

@Component({
  components: {
    IonRow,
    IonCol,
    IonModal,
    IonCard
  },
  props: {
    mode: {
      type: String,
      default: 'blank'
    },
    size: {
      type: String,
      default: 'small'
    }
  }
})
export default class FloatModal extends Vue.with(Props) {
  get modalSizeClass(): string {
    return this.size === 'large' ? 'big-float-modal-wrapper' : ''
  }
}
