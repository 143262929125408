<template>
  <div class="checkbox" @click="handleChange">
    <span v-if="value" class="check-mark">✓</span>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'

class Props {
  value?: boolean
}

@Component({
  components: {},
  props: ['value'],
  emits: ['change']
})
export default class CheckBox extends Vue.with(Props) {
  handleChange() {
    this.$emit('change', !this.value)
  }
}
</script>

<style scoped lang="scss">
.checkbox {
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  width: 26px;
  height: 24px;
  box-shadow: 0px 3px 6px #00000029;

  .check-mark {
    color: $color-primary;
    position: absolute;
    font: italic normal bold 28px Prompt;
    margin-top: -12px;
  }
}
</style>
