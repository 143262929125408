import store from '@/store'
import axios from '@/http-no-firebase'
import OSS from 'ali-oss'

export const refreshSTSToken = async () => {
  return await new Promise(resolve => {
    if (
      store.state.firebase.token &&
      (store.state.ossSTSToken.SecurityToken == '' ||
        new Date(store.state.ossSTSToken.Expiration) < new Date() ||
        store.state.ossClient == {})
    ) {
      axios.get('/oss/sts').then(res => {
        store.state.ossSTSToken = res.data
        store.state.ossClient = new OSS({
          endpoint: process.env.VUE_APP_ALIBABA_ENDPOINT,
          accessKeyId: store.state.ossSTSToken.AccessKeyId,
          accessKeySecret: store.state.ossSTSToken.AccessKeySecret,
          stsToken: store.state.ossSTSToken.SecurityToken,
          bucket: process.env.VUE_APP_ALIBABA_BUCKET,
          secure: true
        })
        resolve(true)
      })
    } else {
      resolve(false)
    }
  })
}
