<template>
  <IonModal :isOpen="true" cssClass="modal-wrapper">
    <IonContent :fullscreen="true">
      <div
        :class="{ 'pa-3': true, fullHeight: true, blur: $store.state.modal.alert.isOpen }"
      >
        <div class="header">
          {{ $t('payment_method.mobile_banking') }}
          <IonIcon name="close" @click="handleCloseModal" />
        </div>

        <div class="details">
          <div>{{ $t('bill.label.pay_to_gb_prime') }}</div>
          <div>{{ $t('bill.label.ref') }} 1 {{ invoice.number }}</div>
        </div>

        <p>
          {{ $t('bill.label.select_bank') }}
        </p>
        <IonSelect
          v-model="form.bankCode"
          :okText="$t('button.select')"
          :cancelText="$t('button.cancel')"
          :placeholder="$t('bill.label.select_bank')"
          :interfaceOptions="{ header: $t('bill.label.select_bank') }"
        >
          <IonSelectOption
            v-for="bank in bankOptions"
            :key="bank.code"
            :value="bank.code"
          >
            {{ $t(`bill.bank.${bank.name}`) }}
          </IonSelectOption>
        </IonSelect>

        <IonButton @click="handlePayment" expand="block">
          {{ $t(`bill.label.pay`) }} {{ displayAmount }}
        </IonButton>
      </div>
    </IonContent>
  </IonModal>
</template>

<script lang="ts">
import { Options as Component, Vue, mixins } from 'vue-class-component'
import { Plugins } from '@capacitor/core'
import {
  IonModal,
  IonIcon,
  IonButton,
  IonItem,
  IonSelect,
  IonLabel,
  IonSelectOption,
  IonContent
} from '@ionic/vue'

import { BASE_API } from '@/constants'
import PageLayout from '@/components/Layout/PageLayout.vue'
import PaymentModal from './PaymentModal.vue'

class Props {
  invoice!: any
}

@Component({
  components: {
    IonModal,
    IonIcon,
    PageLayout,
    IonButton,
    IonItem,
    IonSelect,
    IonLabel,
    IonSelectOption,
    IonContent
  },
  props: ['invoice'],
  emits: ['close', 'createTransaction']
})
export default class GbPrimeMobileBankingModal extends mixins(
  Vue.with(Props),
  PaymentModal
) {
  brand = 'gb_prime'
  method = 'mobile_banking'

  bankOptions = [
    {
      name: 'kbank',
      code: '004'
    },
    {
      name: 'scb',
      code: '014'
    },
    {
      name: 'krungsri',
      code: '025'
    }
  ]

  form = {
    bankCode: '004'
  }

  handleCloseModal() {
    this.$emit('close')
  }

  async handlePayment() {
    try {
      const { paymentUrl, paymentReference }: any = await this.createPayment(
        this.invoice,
        this.form.bankCode,
        this.charge,
        this.paymentMethod
      )

      this.$emit('createTransaction', paymentReference)
      await Plugins.Browser.open({ url: paymentUrl })
      this.$emit('reload')
    } catch (error) {
      this.$emit('reload')
      console.log(error)
      alert(error)
    }
  }

  async createPayment(invoice: any, bankCode: string, charge: any, paymentMethod: any) {
    const data: any = {
      bankCode,
      amount: charge?.amount?.decimal,
      serviceFee: charge?.serviceFee?.decimal,
      paymentGatewayFee: charge?.paymentGatewayFee?.decimal,
      brand: paymentMethod?.brand,
      method: paymentMethod?.method,
      invoiceId: invoice._id
    }

    if (invoice.entity === 'invoiceGroup') {
      delete data.invoiceId
      data.invoiceGroupId = invoice._id
    }

    const response: any = await this.$http.post(`/invoice/transaction`, data)
    const payment = response?.data
    if (!payment?.referenceNo) {
      throw new Error(payment?.failureCode || this.$t('bill.error.create_payment_failed'))
    }

    const paymentReference = payment?.referenceNo
    const paymentUrl = `${BASE_API}/invoice/transaction/${payment._id}/form?bankCode=${bankCode}&method=${this.method}&brand=${this.brand}`

    return { paymentUrl, paymentReference }
  }
}
</script>

<style scoped lang="scss">
.modal-wrapper {
  .fullHeight {
    height: 100%;
  }

  .blur {
    background: rgb(170, 170, 170) !important;
    --background: rgb(170, 170, 170) !important;
    & * {
      background: rgb(170, 170, 170) !important;
      --background: rgb(170, 170, 170) !important;
    }
  }
  
  & div.ion-page {
    padding: 20px;
    justify-content: flex-start;
    font-family: 'Prompt', Helvetica, Arial, sans-serif;
    padding-top: env(--ion-safe-area-top, 20px);
  }

  .header {
    font-size: 20px;
    font-weight: 700;
    width: 100%;
    margin-bottom: 20px;

    ion-icon {
      position: absolute;
      font-size: 28px;
      right: 0px;
    }
  }

  .details {
    font-size: 12px;
    color: #333333;
    letter-spacing: 0.35px;
    margin-bottom: 25px;

    div {
      margin-bottom: 3px;
    }

    div:first-child {
      font-weight: bold;
    }
  }

  ion-select {
    border: 1px solid #ececec;
    border-radius: 2px;
    height: 35px;
    padding-left: 5px;
    color: #333;
  }

  ion-button {
    margin-top: 25px;
  }
}
</style>
