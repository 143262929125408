<template>
  <div class="bill-download">
    <IonRow>
      <IonCol class="bill-download-label">
        {{ $t('bill.label.documents_download') }}
      </IonCol>
    </IonRow>

    <IonRow class="download-buttons-wrappers">
      <IonCol>
        <IonButton
          expand="block"
          class="primary-button"
          @click="$emit('downloadInvoice')"
        >
          {{ $t('bill.label.download_invoice') }}
        </IonButton>
      </IonCol>

      <IonCol v-if="showCreditNote">
        <IonButton
          expand="block"
          class="primary-dark-button"
          @click="$emit('downloadCreditNote')"
        >
          {{ $t('bill.label.download_credit') }}
        </IonButton>
      </IonCol>

      <IonCol>
        <IonButton
          expand="block"
          :class="[receiptDisabled ? 'secondary-button' : 'royal-button']"
          :disabled="receiptDisabled"
          @click="$emit('downloadReceipt')"
        >
          {{ $t('bill.label.download_receipt') }}
        </IonButton>
      </IonCol>
    </IonRow>

    <IonRow>
      <IonCol size="12">
        <div class="bill-underline" />
      </IonCol>
    </IonRow>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonCol, IonRow, IonButton } from '@ionic/vue'

class Props {
  receiptDisabled?: boolean
  showCreditNote?: boolean
}

@Component({
  components: {
    IonCol,
    IonRow,
    IonButton
  },
  props: ['receiptDisabled', 'showCreditNote'],
  emits: ['downloadInvoice', 'downloadReceipt', 'downloadCreditNote']
})
export default class DocumentDownload extends Vue.with(Props) {
  emitDownloadReceipt() {
    this.$emit('downloadReceipt')
  }
}
</script>

<style scoped lang="scss">
.bill-download {
  margin-top: 15px;
}

.bill-download-label {
  text-align: left;
  padding-left: 4px;
  margin-bottom: 3px;
}

.download-buttons-wrappers {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bill-underline {
  margin-top: 15px;
  border-bottom: 2px solid #ffffff;
}

ion-button {
  height: 30px;
}
</style>
