
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonPage,
  IonRow,
  isPlatform
} from '@ionic/vue'
import PageLayout from '@/components/Layout/PageLayout.vue'
import VerifyOtpErrorModal from '@/modules/authentication/VerifyOtpErrorModal.vue'
import firebase from 'firebase/app'
import { getLogInDevice } from '@/utils/deviceUuid'
import { FirebaseX } from '@ionic-native/firebase-x'

@Component({
  components: {
    PageLayout,
    IonPage,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    VerifyOtpErrorModal
  }
})
export default class VerifyOtp extends Vue {
  otp = (null as unknown) as number
  counter = { min: 3, sec: 0, display: '3:00' } as any
  errorModalOpen = false
  errorCode = ''

  mounted() {
    this.startTimer()
  }

  startTimer() {
    setInterval(() => {
      if (!this.counter.sec && !!this.counter.min) {
        this.counter.min--
        this.counter.sec = 60
      }

      if (this.counter.sec) {
        this.counter.sec--
      }
      this.counter.display =
        (this.counter.min < 10 ? '0' + this.counter.min : this.counter.min) +
        ':' +
        (this.counter.sec < 10 ? '0' + this.counter.sec : this.counter.sec)
    }, 1000)
  }

  verify() {
    const phoneCredential = firebase.auth.PhoneAuthProvider.credential(
      this.$route.query.verificationId as string,
      this.otp.toString()
    )

    firebase
      .auth()
      .signInWithCredential(phoneCredential)
      .then(async userCredential => {
        if (userCredential.additionalUserInfo?.isNewUser) {
          const data = {
            externalId: userCredential.user?.uid,
            phoneNumber: userCredential.user?.phoneNumber
          }
          console.log(data, 'data')
          await this.createUserWithBasicAuth(data)
        }
      })
      .then(() => this.acceptPolicyAndTerms())
      .catch(error => {
        console.log('error ', error)
        this.errorModalOpen = true
        this.errorCode = error?.code
      })
  }

  acceptPolicyAndTerms() {
    const { uuid, source } = getLogInDevice()
    const params = { deviceId: uuid, deviceSource: source }

    this.$http
      .post('/policy/latest/accept', params)
      .then(() => this.$router.push('/home'))
      .catch(console.log)
  }
  //TODO: @din please fix this
  async createUserWithBasicAuth(data: any) {
    const username = process.env.VUE_APP_BASIC_AUTH_USERNAME
    const password = process.env.VUE_APP_BASIC_AUTH_PASSWORD
  }

  async resendOtp() {
    await new Promise(resolve => {
      if (isPlatform('ios') && isPlatform('cordova')) {
        const response = FirebaseX.verifyPhoneNumber(
          '+66' + this.$route.query.phoneNumber,
          300000
        )
        resolve(response)
      } else {
        const response = firebase.auth().signInWithPhoneNumber(
          // const response = await FirebaseAuthentication.verifyPhoneNumber(
          '+66' + this.$route.query.phoneNumber,

          new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            size: 'invisible'
          })
        )
        resolve(response)
      }
    }).then((response: any) => {
      this.$router.push({
        path: '/verify_otp',
        query: {
          verificationId: response.verificationId,
          phoneNumber: this.$route.query.phoneNumber
        }
      })
    })
  }
}
