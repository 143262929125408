<template>
  <div>
    <IonRow>
      <IonCol class="px-2 pb-2" size="12" sizeMd="4" :key="i" v-for="(image, i) in src">
        <div
          @click="imageClicked(i)"
          :style="'background-image: url(' + GET_SECURED_IMAGE(image) + ');'"
          class="card-img mb-3"
        />
      </IonCol>
    </IonRow>

    <IonModal :isOpen="modal.isActive" :backdropDismiss="false">
      <IonContent>
        <IonRow class="pa-3 fill-height">
          <IonCol>
            <IonRow class="ion-justify-content-between ion-align-items-center text mb-5">
              <IonCol size="1" />

              <IonCol size="auto" class="f-18"> {{ title }}</IonCol>
              <IonCol size="1">
                <IonIcon
                  size="large"
                  src="/assets/icon/close-round-white.svg"
                  @click="modal.isActive = false"
                />
              </IonCol>
            </IonRow>

            <!--<IonRow class="ion-justify-content-center mb-3">-->
            <!--  <IonCol size="auto" class="f-14">-->
            <!--    {{ modal.startIndex }}-->
            <!--    of-->
            <!--    {{ src.length }}-->
            <!--  </IonCol>-->
            <!--</IonRow>-->
            <IonSlides
              ref="slides"
              :pager="true"
              :options="{
                initialSlide: modal.startIndex,
                speed: 400
              }"
              @ionSlideNextEnd="slideChanged(+1)"
              @ionSlidePrevEnd="slideChanged(-1)"
            >
              <IonSlide v-for="(image, i) in src" :key="i">
                <img :src="GET_SECURED_IMAGE(image)" alt="image" width="100%" />
              </IonSlide>
            </IonSlides>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonModal>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonRow,
  IonCol,
  IonModal,
  IonContent,
  IonIcon,
  IonSlides,
  IonSlide,
  IonImg
} from '@ionic/vue'
import PageLayout from '@/components/Layout/PageLayout.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'

class Props {
  src!: string
  title?: string
}

@Component({
  components: {
    PageHeader,
    PageLayout,
    IonRow,
    IonCol,
    IonModal,
    IonContent,
    IonIcon,
    IonSlides,
    IonSlide,
    IonImg
  },
  props: {
    src: {
      default: ['https://th-test-11.slatic.net/p/5568633b84a71986d67de8c5f290ddae.jpg'],
      type: Array
    },
    title: {
      default: 'Images',
      type: String
    }
  }
})
export default class ImagesDialog extends Vue.with(Props) {
  modal = {
    isActive: false,
    startIndex: -1
  }

  // slideChanged(v: number) {
  //   this.modal.startIndex += v
  // }
  //
  imageClicked(i: number) {
    this.modal.startIndex = i
    this.modal.isActive = true
  }
}
</script>

<style lang="scss" scoped>
.text {
  color: #fff;
}

.card-img {
  border-radius: 10px;
  height: 180px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

ion-content {
  --background: #333333 !important;
}
</style>
