<template>
  <ErrorModal v-on:close="$emit('close')">
    <template v-slot:content>
      <div>
        {{ $t('login.modal.otp_error.your_otp_is') }}
        <strong>{{ $t('login.modal.otp_error.incorrect_or_expired') }}</strong>
      </div>
      <div> {{ $t('login.modal.otp_error.please_check_again') }} </div>
      <!--<div> ({{ errorCode }}) </div>-->
    </template>
  </ErrorModal>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonButton } from '@ionic/vue'
import ErrorModal from '@/components/modals/ErrorModal.vue'

@Component({
  components: { IonButton, ErrorModal },
  props: ['errorCode'],
  emits: ['close']
})
export default class VerifyOrpErrorModal extends Vue {}
</script>

<style scoped lang="scss"></style>
