<template>
  <IonRow class="outlined rounded full-width">
    <IonCol size="6">
      <IonRow>
        <IonCol size="12">
          <div
            class="announcement-desktop"
            :style="
              `background-image:
                    url(${
                      announcement.imagePaths && announcement.imagePaths.length
                        ? GET_SECURED_IMAGE(announcement.imagePaths[0])
                        : '/assets/img/default-content-image.png'
                    })
                  `
            "
          />
        </IonCol>
        <IonCol
          size="auto"
          class="pr-2 pt-2"
          v-for="(img, i) in announcement.imagePaths.slice(1, 7)"
          :key="'sub' + i"
        >
          <div
            class="announcement-desktop--small"
            :style="
              `background-image:
                        url(${GET_SECURED_IMAGE(img)})`
            "
          >
          </div>
        </IonCol>
      </IonRow>
    </IonCol>

    <IonCol size="6">
      <div class="text-wrapper mt-2">
        <div class="card-title">
          {{ REMOVE_HTML_TAG(announcement.title) }}
        </div>
        <p class="card-subtitle ion-text-justify">
          <span class="read-more--12ln">
            {{ REMOVE_HTML_TAG(announcement.content) }}
          </span>
          <IonText
            v-if="announcement.content.length > 1"
            class="pa-0 ion-text-end cursor-pointer"
            color="primary"
            @click="$router.push(`/announcements/${announcement._id}`)"
          >
            <b>
              <u> {{ $t('button.read_more') }} </u>
            </b>
          </IonText>
        </p>
      </div>
    </IonCol>
  </IonRow>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonText, IonButton } from '@ionic/vue'

class Props {
  announcement!: any
}

@Component({
  components: { IonRow, IonCol, IonText, IonButton },
  props: ['announcement']
})
export default class DesktopAnnouncementCard extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
.text-wrapper {
  padding: 8px 24px;
  bottom: 0;
  text-align: left;

  .card-title {
    font-size: 16px;
    letter-spacing: 0.47px;
  }

  .card-subtitle {
    font-size: 12px;
    letter-spacing: 0.35px;
  }
}

@mixin announcement-desktop-var($size) {
  border-radius: 5px;
  height: $size;
  //background-size: 100% 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.announcement-desktop {
  &--small {
    @include announcement-desktop-var(88px);
    width: 88px;
  }
  & {
    @include announcement-desktop-var(300px);
  }
}
</style>
