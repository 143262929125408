
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonText,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonCardHeader,
  IonContent,
  IonImg,
  IonModal
} from '@ionic/vue'
class Props {}
@Component({
  components: {
    IonModal,
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonText,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonCardHeader,
    IonContent,
    IonImg
  },
  props: []
})
export default class DialogMaintenance extends Vue.with(Props) {}
