<template>
  <div class="attribute__wrapper">
    <label v-if="strong">
      <h2
        ><b>{{ label }}</b></h2
      >
    </label>
    <label v-else>
      {{ label }}
    </label>

    <slot />
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'

class Props {
  label?: string
  strong?: boolean | false
}

@Component({
  components: {},
  props: ['label', 'strong']
})
export default class Attribute extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
.attribute__wrapper {
  & > label {
    display: block;
    color: #aaa;
    font-size: 14px;
    margin-bottom: 8px;
  }
}
</style>
