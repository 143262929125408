<template>
  <PageHeader :title="$t('bill.bill_details')" :withBackButton="true">
    <template v-slot:action> </template>
  </PageHeader>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonButton, IonIcon } from '@ionic/vue'
import { PageHeader } from '@/components/Layout'

class Props {
  invoice!: any
  route!: string
}

@Component({
  components: { IonButton, IonIcon, PageHeader },
  props: ['invoice', 'route']
})
export default class BillShowHeader extends Vue.with(Props) {}
</script>

<style lang=""></style>
