<template>
  <div>
    <div>
      <IonRow class="ion-justify-content-between">
        <IonCol size="auto">
          <div class="quick-action-title f-18">{{ $t('home.quick_action') }}</div>
        </IonCol>
        <IonCol size="auto">
          <IonButton size="small" class="f-12" @click="$router.push('/bills')">
            {{ $t('home.see_more') }}
          </IonButton>
        </IonCol>
      </IonRow>

      <EmptyListPlaceHolder :content="$t('home.no_quick_action')" v-if="!invoices" />

      <div v-else>
        <IonRow class="margin-auto">
          <!--          <div class="quick-action-title">{{ $t('home.quick_action') }}</div>-->
          <IonCol
            :class="desktop ? 'pa-2' : null"
            :size="desktop ? 4 : 12"
            v-for="(invoice, i) in invoices"
            :key="i"
            @mouseover="hoverHandler(i, true)"
            @mouseleave="hoverHandler(i, false)"
          >
            <BillCard
              v-if="invoice.entity === 'invoice'"
              :invoice="invoice"
              :cardStyle="desktop && !cardHover[i] ? 'outline' : null"
              cssStyle=""
            />
            <BillGroupCard
              v-if="invoice.entity === 'invoiceGroup'"
              :minimized="true"
              :invoiceGroup="invoice"
              :cardStyle="desktop && !cardHover[i] ? 'outline' : null"
            />
          </IonCol>
        </IonRow>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import EmptyListPlaceHolder from '@/components/EmptyListPlaceHolder.vue'
import SeeDetails from '@/components/SeeDetails.vue'
import { BillCard } from '@/modules/bills'
import BillGroupCard from '@/modules/bills/BillGroupCard.vue'
import { IonRow, IonCol, IonButton } from '@ionic/vue'

class Props {
  invoices!: []
  desktop?: boolean | false
}
@Component({
  components: {
    BillCard,
    BillGroupCard,
    SeeDetails,
    EmptyListPlaceHolder,
    IonRow,
    IonCol,
    IonButton
  },
  props: ['invoices', 'desktop']
})
export default class QuickActionBlock extends Vue.with(Props) {
  cardHover = [] as boolean[]
  hoverHandler(i: number, isHover: boolean) {
    this.cardHover[i] = isHover
  }
}
</script>

<style lang="scss">
.router-link {
  color: #92949c;
  text-decoration: none;
  cursor: pointer;
}

.quick-action-title {
  height: 30px;
  text-align: left;
  //font: normal normal normal 20px/30px Prompt;
  color: #333333;
  text-transform: capitalize;
}
</style>
