
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonText, IonButton } from '@ionic/vue'

class Props {
  announcement!: any
}

@Component({
  components: { IonRow, IonCol, IonText, IonButton },
  props: ['announcement']
})
export default class DesktopAnnouncementCard extends Vue.with(Props) {}
