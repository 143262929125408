
import { Options as Component, Vue } from 'vue-class-component'
import ServiceHomeMobile from '@/components/page/services/ServiceHomeMobile.vue'
import { ServiceDataInterface } from '@/interfaces/service'
import ServiceHomeDesktop from '@/components/page/services/ServiceHomeDesktop.vue'

class Props {}

@Component({
  components: {
    ServiceHomeDesktop,
    ServiceHomeMobile
  },
  props: []
})
export default class Services extends Vue.with(Props) {
  serviceList = [
    {
      src: '/assets/img/banner-seekster.png',
      name: 'Seekster',
      title: 'Seekster',
      content:
        'บริการทำความสะอาดสำหรับร้านค้า การันตีคุณภาพ ได้มาตรฐาน เรียกใช้ง่าย รับประกันงานและความเสียหาย',
      iframeLink: 'https://get.seekster.co/kegroup/'
    },
    {
      src: '/assets/img/services/FoodStory.png',
      name: 'Foodstory',
      title: 'Foodstory',
      content:
        'แหล่งรวมวัตถุดิบออนไลน์ ที่จะทำให้ร้านอาหารหรือคาเฟ่ของคุณประหยัดได้มากกว่าเดิม ทั้งประหยัดเวลา ประหยัดที่จัดเก็บ และประหยัดเงิน ส่งวัตถุดิบสดใหม่ได้คุณภาพถึงร้านค้าของคุณ',
      iframeLink: 'https://skycommercial.skyos.tech/landingfoodstory'
    },
    {
      src: '/assets/img/services/Marketing.png',
      name: 'Marketing',
      title: 'Marketing',
      content:
        'เคอี กรุ๊ป ร่วมกับ Fastwork แพลตฟอร์มรวมกลุ่มมืออาชีพด้านการตลาด เพื่อนำเสนอบริการด้านการตลาดหลากหลายประเภท เพื่อตอบโจทย์การทำการตลาดของร้านค้าได้มากกว่าเดิม',
      iframeLink: 'https://skycommercial.skyos.tech/landingmarket'
    }
  ] as ServiceDataInterface[]
}
