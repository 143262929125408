<template>
  <PageLayout>
    <PageHeader
      :title="$t('setting.change_language')"
      :withBackButton="true"
      backTo="/settings"
      class="mb-10"
    />
    <IonRow>
      <IonCol size="12">
        <SettingMenuItem
          :isSelected="language === $i18n.locale"
          v-for="(language, i) in languages"
          :key="i"
          @click="changeLocale(language)"
        >
          {{ $t(`setting.languages.${language}`) }}
        </SettingMenuItem>
      </IonCol>
    </IonRow>
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonGrid, IonRow, IonCol } from '@ionic/vue'
import { PageLayout, PageHeader } from '@/components/Layout'
import SettingMenuItem from '@/components/ListItem/SettingMenuItem.vue'

class Props {}

@Component({
  components: {
    PageLayout,
    PageHeader,
    IonGrid,
    IonRow,
    IonCol,
    SettingMenuItem
  },
  props: []
})
export default class ChangeLanguage extends Vue.with(Props) {
  get languages() {
    return this.$i18n.availableLocales
  }

  changeLocale(language: string) {
    localStorage.setItem('language', language)
    this.$i18n.locale = language
  }
}
</script>
