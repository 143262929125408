
import { Options as Component, Vue } from 'vue-class-component'

class Props {
  value?: boolean
}

@Component({
  components: {},
  props: ['value'],
  emits: ['change']
})
export default class CheckBox extends Vue.with(Props) {
  handleChange() {
    this.$emit('change', !this.value)
  }
}
