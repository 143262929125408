<template>
  <ContentShow :content="news" :title="$t('news.news')" />
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import _ from 'lodash'
import ContentShow from '@/components/content/ContentShow.vue'
class Props {}

@Component({
  components: {
    ContentShow
  },
  props: []
})
export default class NewsShowPage extends Vue.with(Props) {
  news = {} as {
    id: string
    title: string
    content: string
    image: string[]
    projectId: number
    userId: number
    projectNameTh: string
    projectNameEn: string
    createdAt: string
    updatedAt: string
  }

  async getNewsById(newsId: string) {
    try {
      this.news = (await this.$http.get(`/news/${newsId}`)).data
    } catch (e) {
      await this.$router.push('/home')
    }
  }
  async mounted() {
    if (_.get(this.$route.params, 'data')) {
      Object.assign(this.news, JSON.parse(this.$route.params.data.toString()))
    } else if (_.get(this.$route.params, 'id')) {
      await this.getNewsById(this.$route.params.id as string)
    } else {
      await this.$router.push('/home')
    }
  }
}
</script>

<style scoped lang="scss"></style>
