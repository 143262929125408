<template>
  <IonToast
    :isOpen="toastData.isShow"
    :onDidDismiss="
      () => {
        toastData = {
          isShow: false,
          message: ''
        }
      }
    "
    :header="'Seek.town.new.not'"
    :message="toastData.message"
    position="top"
    :color="'danger'"
    :duration="5000"
  />
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import registerPushNotifications from '@/utils/pushNotification'
import 'firebase/auth'
import 'firebase/firestore'
import firebase from 'firebase/app'
import { IonToast } from '@ionic/vue'
import { Plugins } from '@capacitor/core'
import router from '@/router'
// import { PushNotifications } from '@capacitor/push-notifications'
const { LocalNotifications } = Plugins
class Props {}

@Component({
  components: { IonToast },
  props: []
})
export default class PushNotification extends Vue.with(Props) {
  toastData = {
    isShow: false,
    message: ''
  }
  updateRegistrationTokens = async (token: any) => {
    try {
      const userRef = firebase
        .firestore()
        .collection('users')
        .doc(firebase.auth()!.currentUser!.uid)
      const docSnapshot = await userRef.get()
      const existingTokens = docSnapshot.get('registration_tokens')
      if (existingTokens && existingTokens.includes(token)) return 0
      const tokens = existingTokens || []
      return userRef.set(
        {
          registration_tokens: [...tokens, token]
        },
        {
          merge: true
        }
      )
    } catch (error) {
      console.log('updateRegistrationTokens error', error)
    }
  }
  addNotificationEventListener = () => {
    try {
      Plugins.PushNotifications.addListener('pushNotificationReceived', notification => {
        // App is Opening.
        console.log('push foreground', notification)
        const { body } = notification
        this.toastData = { isShow: true, message: body! }
      })
    } catch (e) {
      console.log(' Error while adding PushNotification listener: ', e)
    }
  }
  addNotificationActionListener = () => {
    try {
      Plugins.PushNotifications.addListener('pushNotificationActionPerformed', data => {
        // App is Open.
        const { type, id } = data.notification.data
        router.push(`/${type}/${id}`)
      })
    } catch (e) {
      console.log(' Error while adding PushNotification listener: ', e)
    }
  }

  beforeMount() {
    console.log('before mounted')
    registerPushNotifications()
      .then((token: any) => {
        if (
          token &&
          firebase.auth() &&
          firebase.auth().currentUser &&
          firebase.auth().currentUser!.uid
        ) {
          this.updateRegistrationTokens(token)
        }
      })
      .catch(e => {
        console.log('registerPushNotification failed', e)
      })
    try {
      this.addNotificationEventListener()
      this.addNotificationActionListener()
    } catch (e) {
      console.log(e)
    }
  }
}
</script>

<style scoped lang="scss"></style>
