<template>
  <div class="see-details-container"> See details </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'

class Props {}

@Component({
  components: {},
  props: []
})
export default class SeeDetails extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
.see-details-container {
  color: #2baae1;
  &::after {
    content: ' >';
    vertical-align: middle;
  }
}
</style>
