<template>
  <IonModal
    :showBackdrop="true"
    :isOpen="true"
    :cssClass="`float-modal-wrapper ${modalSizeClass}`"
    :backdropDismiss="false"
  >
    <IonCard>
      <template v-if="mode === 'alert'">
        <IonRow class="ion-justify-content-center">
          <IonCol size="auto">
            <slot name="header" />
          </IonCol>
        </IonRow>

        <IonRow class="ion-justify-content-center">
          <IonCol size="auto" class="card-content">
            <slot name="content" />
          </IonCol>
        </IonRow>

        <IonRow class="ion-justify-content-center">
          <IonCol size="auto">
            <slot name="footer" />
          </IonCol>
        </IonRow>
      </template>

      <template v-if="mode === 'blank'">
        <slot name="header" />
        <slot name="content" />
        <slot name="footer" />
      </template>
    </IonCard>
  </IonModal>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonModal, IonCard } from '@ionic/vue'

class Props {
  mode?: string | 'blank' | 'alert'
  size?: string | 'small' | 'large'
}

@Component({
  components: {
    IonRow,
    IonCol,
    IonModal,
    IonCard
  },
  props: {
    mode: {
      type: String,
      default: 'blank'
    },
    size: {
      type: String,
      default: 'small'
    }
  }
})
export default class FloatModal extends Vue.with(Props) {
  get modalSizeClass(): string {
    return this.size === 'large' ? 'big-float-modal-wrapper' : ''
  }
}
</script>

<style lang="scss">
.float-modal-wrapper {
  --width: 80%;
  --min-width: 250px;
  --max-width: 400px;
  --background: none;
  --box-shadow: none !important;

  ion-card {
    margin-top: 55%;
    padding: 5px 5px 5px 5px;

    ion-col.card-content {
      text-align: center;
      font-size: 12px;
    }
  }
}

.big-float-modal-wrapper {
  --width: 95%;

  ion-card {
    margin-top: 15%;
  }
}
</style>
