
import { Options as Component, Vue } from 'vue-class-component'
import { isPlatform } from '@ionic/vue'
import firebase from 'firebase/app'
import {
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonText,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonCardHeader,
  IonContent,
  toastController
} from '@ionic/vue'
import { FCM } from '@capacitor-community/fcm'
class Props {
  closeModal!: Function
}

@Component({
  components: {
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonText,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonCardHeader,
    IonContent
  },
  props: ['closeModal']
})
export default class ConfirmLogout extends Vue.with(Props) {
  async logoutSubmit() {
    try {
      if (isPlatform('android') || isPlatform('ios')) {
        try {
          const fcm = new FCM()
          const { token } = await fcm.getToken()
          const userRef = firebase
            .firestore()
            .collection('users')
            .doc(firebase.auth()!.currentUser!.uid)
          const docSnapshot = await userRef.get()
          let existingTokens = docSnapshot.get('registration_tokens')
          existingTokens = existingTokens.filter((e: string) => e !== token)
          const tokens = existingTokens
          await userRef.set(
            {
              registration_tokens: [...tokens]
            },
            {
              merge: true
            }
          )
        } catch (e) {
          console.log(e)
        }
      }
      await firebase.auth().signOut()
      this.closeModal()
      await this.$router.push('/login')
    } catch (e) {
      console.log('e', e)
      this.closeModal()
      await (
        await toastController.create({
          message: 'Logout failed. Please try again later.',
          color: 'danger',
          duration: 3000
        })
      ).present()
    }
  }
}
