
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRefresher,
  IonRefresherContent
} from '@ionic/vue'

class Props {
  background?: string
  isFullScreen?: {
    type: boolean
    default: false
  }
  isScrollY?: {
    type: boolean
    default: true
  }
}

@Component({
  components: { IonPage, IonContent, IonGrid, IonRefresher, IonRefresherContent },
  props: ['background', 'isFullScreen', 'isScrollY']
})
export default class PageLayout extends Vue.with(Props) {
  get backgroundClass() {
    return this.background ? 'st-background-' + this.background : 'st-background-grey'
  }
  get getClass() {
    if (this.isFullScreen) return 'px-0'
    else return 'px-2'
  }
}
