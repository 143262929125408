<template>
  <PageLayout>
    <template v-slot:header>
      <PageHeader :title="$t('home.my_projects')" :withBackButton="true" />
    </template>
    <div class="pt-3"></div>
    <ProjectCard
      v-for="(project, i) in this.$store.state.projects"
      :key="i"
      :imagePath="project.imagePath"
      :title="I18N(project.nameTh, project.nameEn)"
    />
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { PageLayout, PageHeader } from '@/components/Layout'
import ProjectCard from '@/components/ProjectCard.vue'

class Props {}

@Component({
  components: { PageLayout, PageHeader, ProjectCard },
  props: []
})
export default class MyProjects extends Vue.with(Props) {
  mounted() {
    this.fetchProjects()
  }
  fetchProjects() {
    this.$http.get('/capi/project').then(res => {
      this.$store.state.projects = res.data
    })
  }
}
</script>

<style scoped lang="scss"></style>
