
import { Options as Component, Vue } from 'vue-class-component'
import _ from 'lodash'
import ContentShow from '@/components/content/ContentShow.vue'
class Props {}

@Component({
  components: {
    ContentShow
  },
  props: []
})
export default class AnnouncementShowPage extends Vue.with(Props) {
  announcement = {} as {
    id: string
    title: string
    content: string
    image: string[]
    projectId: number
    userId: number
    projectNameTh: string
    projectNameEn: string
    createdAt: string
    updatedAt: string
  }
  async mounted() {
    if (_.get(this.$route.params, 'data')) {
      Object.assign(this.announcement, JSON.parse(this.$route.params.data.toString()))
    } else if (_.get(this.$route.params, 'id')) {
      try {
        this.announcement = (
          await this.$http.get(`/announcement/${this.$route.params.id}`)
        ).data
      } catch (e) {
        await this.$router.push('/home')
      }
    } else {
      await this.$router.push('/home')
    }
  }
}
