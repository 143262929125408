import { Plugins } from '@capacitor/core'
import { FCM } from '@capacitor-community/fcm'
const fcm = new FCM()
const Push = Plugins.PushNotifications
const registerPushNotifications = async () => {
  const permission = await Push.requestPermission()
  if (permission.granted) {
    await Push.register()
    const response = await fcm.getToken()
    console.log('firebase FCM token', response)
    return response.token
  }
  Push.addListener('registration', (token: any) => {
    alert('Push registration success, token: ' + token.value)
  })
}

export default registerPushNotifications
