
import { Options as Component, Vue } from 'vue-class-component'
class Props {
  content!: string
}
@Component({
  components: {},
  props: ['content']
})
export default class EmptyListPlaceHolder extends Vue.with(Props) {}
