<template>
  <PageLayout :style="{ paddingBottom: '70px' }">
    <BillShowHeader route="/bill_groups" :invoice="invoiceGroup" />
    <div class="container">
      <Card
        v-for="(invoice, i) in invoiceGroup?.invoices || []"
        :key="i"
        class="bill-card"
        cardStyle="outline"
        :button="true"
        @click="$router.push(`/bills/${invoice?._id}`)"
      >
        <IonRow class="bill-header ion-justify-content-between">
          <IonCol size="auto">{{ invoice.number }}</IonCol>
          <IonCol size="auto"><BillState :invoice="invoice"/></IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <CustomerDetails :invoice="invoice" />
            <div
              v-if="invoice.totalCreditNoteCents !== 0"
              class="bill-details credit-note ellipsis"
            >
              <span>{{ `${$t('bill.label.credit_note')} : ` }} </span>
              <span>
                {{ invoice.totalCreditNote.fullDisplay }}
              </span>
            </div>
          </IonCol>
        </IonRow>

        <IonRow class="bill-total ion-justify-content-between">
          <IonCol size="auto">{{ $t('bill.label.total_payment') }}</IonCol>
          <IonCol size="auto">{{ invoice.grandTotalAfterCreditNote.fullDisplay }}</IonCol>
        </IonRow>

        <IonRow class="bill-footer">
          <IonCol>
            <IonButton class="primary-button">
              {{ $t('button.view_details') }}
            </IonButton>
          </IonCol>
        </IonRow>
      </Card>

      <GrandTotalCard
        :grandTotal="invoiceGroup?.grandTotalAfterCreditNote?.fullDisplay"
      />

      <DocumentDownload
        @downloadInvoice="
          $router.push({
            path: `/bill_groups/${invoiceGroup._id}/pdf`,
            query: { type: 'invoice' }
          })
        "
        @downloadReceipt="
          $router.push({
            path: `/bill_groups/${invoiceGroup._id}/pdf`,
            query: { type: 'receipt' }
          })
        "
        @downloadCreditNote="
          $router.push({
            path: `/bill_groups/${invoiceGroup._id}/pdf`,
            query: { type: 'credit' }
          })
        "
        :receiptDisabled="invoiceGroup?.state !== 'paid'"
        :showCreditNote="creditNoteCount > 0"
      />

      <PaymentsPanel
        v-if="invoiceGroup?.state === 'pending'"
        :invoice="invoiceGroup"
        v-on:billPaid="fetchInvoiceGroup()"
      />
    </div>
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonCol, IonRow, IonButton } from '@ionic/vue'
import { BillShowHeader } from '@/modules/bills'
import { PageLayout } from '@/components/Layout'
import { Card } from '@/components/Card'
import BillState from '@/modules/bills/BillState.vue'
import CustomerDetails from '@/modules/bills/CustomerDetails.vue'
import GrandTotalCard from '@/modules/bills/GrandTotalCard.vue'
import DocumentDownload from '@/modules/bills/DocumentDownload.vue'
import { PaymentsPanel } from '@/modules/bills'

@Component({
  components: {
    PageLayout,
    BillShowHeader,
    Card,
    BillState,
    CustomerDetails,
    GrandTotalCard,
    DocumentDownload,
    PaymentsPanel,
    IonCol,
    IonRow,
    IonButton
  },
  props: []
})
export default class BillGroupShow extends Vue {
  invoiceGroup = {} as any
  payment = {} as any

  get creditNoteCount() {
    if (!this.invoiceGroup) return 0
    return this.invoiceGroup.invoices?.reduce(
      (sum: number, invoice: any) => sum + invoice.creditNotes.length,
      0
    )
  }

  mounted() {
    this.fetchInvoiceGroup()
  }

  fetchInvoiceGroup() {
    this.$http.get(`/invoice/group/` + this.$route.params.id).then(res => {
      this.invoiceGroup = res?.data
      this.payment = this.invoiceGroup?.payment_groups?.find(
        (payment: any) => payment?.state === 'received'
      )
    })
  }
}
</script>

<style scoped lang="scss">
.container {
  padding: 10px 10px 40px 10px;
}

.bill-card {
  padding: 10px 10px 10px 10px !important;
  margin-bottom: 15px !important;

  .bill-header {
    ion-col:first-child {
      font-size: 20px;
      color: $color-primary-dark;
    }
    ion-col:last-child {
      padding-top: 8px;
    }
  }

  .bill-total {
    font: normal normal normal 16px/25px Prompt;
    letter-spacing: 0px;
    color: #333333;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 3px;
    margin-top: 5px;
    margin-bottom: 3px;

    ion-col:last-child {
      font-weight: bold;
    }
  }

  .bill-footer {
    text-align: center;
  }
}

.credit-note {
  span:first-child {
    color: $color-primary;
  }
  span:last-child {
    color: $color-primary;
  }
}
</style>
