<template>
  <Card cssStyle="padding: 10px 10px 10px 10px;" :button="false" cardStyle="outline">
    <IonRow class="bill-grand-total ion-justify-content-between">
      <IonCol size="auto">{{ $t('bill.label.grand_total') }}</IonCol>
      <IonCol size="auto">
        {{ grandTotal }}
      </IonCol>
    </IonRow>
  </Card>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonCol, IonRow } from '@ionic/vue'

import { Card } from '@/components/Card'

class Props {
  grandTotal!: string
}

@Component({
  components: {
    Card,
    IonCol,
    IonRow
  },
  props: ['grandTotal']
})
export default class GrandTotalCard extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
.bill-grand-total {
  font-size: 16px;

  ion-col {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  ion-col:first-child {
    color: #333333;
    font-weight: 500;
  }

  ion-col:last-child {
    color: $color-primary-dark;
    font-weight: bold;
  }
}
</style>
