<template>
  <Card class="project-card-wrapper">
    <div
      :style="
        `background-image:
                    linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(156,156,156,0) 80%),
                    url(${
                      announcement.imagePaths && announcement.imagePaths.length
                        ? GET_SECURED_IMAGE(announcement.imagePaths[0])
                        : '/assets/img/default-content-image.png'
                    })
                  `
      "
      class="announcement-bg-wrapper"
    >
      <div class="text-wrapper">
        <div class="card-title">{{ REMOVE_HTML_TAG(announcement.title) }}</div>
        <div class="card-subtitle">{{ REMOVE_HTML_TAG(announcement.content) }}</div>
      </div>
    </div>
  </Card>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { Card } from '@/components/Card'
import { IonCard } from '@ionic/vue'

class Props {
  announcement?: {}
}
@Component({
  components: { IonCard, Card },
  props: ['announcement']
})
export default class AnnouncementCard extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
.project-card-wrapper {
  height: 163px;
  position: relative;
  width: 300px;
  box-shadow: 0px 3px 3px #00000029;
  opacity: 1;
  margin: 0px;
  .announcement-bg-wrapper {
    height: 160px;
    width: 100%;
    background-size: cover;
    background-position: center;

    .text-wrapper {
      position: absolute;
      width: 100%;
      padding: 16px;
      bottom: 0;
      color: white;
      text-align: left;

      .card-title {
        font-size: 16px;
        width: 100%;
        letter-spacing: 0.47px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .card-subtitle {
        font-size: 12px;
        width: 100%;
        letter-spacing: 0.35px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
