<template>
  <IonRow ref="{ref}" class="d-flex" style="flex-wrap: wrap">
    <IonCol
      size="4"
      class="payment-method"
      v-for="paymentMethod in paymentMethods"
      :key="paymentMethod.id"
    >
      <IonCard
        :button="paymentMethod.isActive && paymentMethod.isOpened"
        :class="{ closed: !paymentMethod.isOpened || !paymentMethod.isActive }"
        @click="
          paymentMethod.isActive && paymentMethod.isOpened && paymentMethod.action()
        "
        style="width: 100%"
      >
        <div class="closed-text">
          <CenterVerticalAlign>
            {{
              paymentMethod.isActive
                ? $t('payment_method.label.is_unavailable_until', {
                    method: paymentMethod.label,
                    openedAt: paymentMethod.openedAt
                  })
                : $t('payment_method.label.is_unavailable', {
                    method: paymentMethod.label
                  })
            }}
          </CenterVerticalAlign>
        </div>
        <div class="content">
          <IonIcon :src="`/assets/icon/${paymentMethod.iconName}.svg`" />
          <IonText>{{ paymentMethod.label }}</IonText>
          <div class="fee">
            {{
              paymentMethod.totalFee?.fractional > 0
                ? `${$t('bill.label.fee')} ${paymentMethod.totalFee.fullDisplay}`
                : $t('bill.label.no_fee')
            }}
          </div>
        </div>
      </IonCard>
    </IonCol>
    <IonCol size="12" class="pt-2">
      <p
        class="pa-0 ma-0 f-11"
        style="color:red"
        v-for="(text, i) in $t('payment_method.description').split('\n')"
        :key="i"
      >
        {{ text }}
      </p>
    </IonCol>
    <GbPrimeCardModal
      v-if="modalOpen.gbPrimeCard"
      :invoice="invoice"
      v-on:close="() => handleCloseModal('gbPrimeCard')"
      v-on:createTransaction="createTransaction"
      @reload="emitReload"
    />

    <GbPrimeQrCodeModal
      v-if="modalOpen.gbPrimeQrCode"
      :invoice="invoice"
      v-on:close="() => handleCloseModal('gbPrimeQrCode')"
      v-on:createTransaction="createTransaction"
      @reload="emitReload"
    />

    <GbPrimeMobileBankingModal
      v-if="modalOpen.gbPrimeMobileBanking"
      :invoice="invoice"
      v-on:close="() => handleCloseModal('gbPrimeMobileBanking')"
      v-on:createTransaction="createTransaction"
      @reload="emitReload"
    />
  </IonRow>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonCard, IonText, IonIcon, IonRow, IonCol } from '@ionic/vue'

import { GbPrimeCardModal, GbPrimeQrCodeModal, GbPrimeMobileBankingModal } from './modals'
import firebase from 'firebase/app'
import CenterVerticalAlign from '@/components/CenterVerticalAlign.vue'

class Props {
  invoice!: any
}

@Component({
  components: {
    IonCard,
    IonText,
    IonIcon,
    IonRow,
    IonCol,
    CenterVerticalAlign,
    GbPrimeCardModal,
    GbPrimeQrCodeModal,
    GbPrimeMobileBankingModal
  },
  props: ['invoice'],
  emits: ['billPaid']
})
export default class PaymentsPanel extends Vue.with(Props) {
  modalOpen = {
    gbPrimeCard: false,
    gbPrimeQrCode: false,
    gbPrimeMobileBanking: false
  } as any

  transactionListener = () => {}

  async mounted() {
    // init firebase
    await firebase.firestore().collection('payment_transactions')
  }

  get paymentMethods() {
    const projectPaymentMethods = this.invoice?.projectPaymentMethods || []
    return projectPaymentMethods.map((projectPaymentMethod: any) => {
      const totalFee = projectPaymentMethod?.charge?.totalFee
      const { paymentMethod } = projectPaymentMethod
      const paymentMethodName = `${paymentMethod?.brand}_${paymentMethod?.method}`
      const label = this.$t(`payment_method.${paymentMethod.method}`)

      const isUnderPrice = projectPaymentMethod.isUnderPrice
      const isActive = projectPaymentMethod.isActive
      const isOpened = projectPaymentMethod.isOpened
      const openedAt = paymentMethod.paymentTimeOpenedAt

      const iconName = isOpened ? paymentMethodName : `${paymentMethodName}_grey`

      const showUnderPriceWarning = () =>
        alert(`Minimum payment is ${paymentMethod.minimumRate} Baht`)

      const paymentPanel = {
        label,
        iconName,
        totalFee,
        isActive,
        isOpened,
        openedAt,
        openPaymentModal: () => {},
        action: () => {
          if (isUnderPrice) {
            showUnderPriceWarning()
          } else {
            paymentPanel.openPaymentModal()
            this.showPaymentDelayWarning()
          }
        }
      }

      switch (paymentMethodName) {
        case 'gb_prime_card':
          paymentPanel.openPaymentModal = () => (this.modalOpen.gbPrimeCard = true)
          return paymentPanel

        case 'gb_prime_qr_code':
          paymentPanel.openPaymentModal = () => (this.modalOpen.gbPrimeQrCode = true)
          return paymentPanel

        case 'gb_prime_mobile_banking':
          paymentPanel.openPaymentModal = () =>
            (this.modalOpen.gbPrimeMobileBanking = true)
          return paymentPanel

        default:
          return paymentPanel
      }
    })
  }

  emitReload() {
    this.$emit('reload')
  }

  showPaymentDelayWarning() {
    // set delay to show warning modal on top of any other modal
    setTimeout(() => {
      this.$store.state.modal.alert.content = this.$t('bill.modal.payment_delay_warning')
      this.$store.state.modal.alert.isOpen = true
    }, 100)
  }

  handleCloseModal(modalName: string) {
    if (modalName === 'all') {
      for (const modalName in this.modalOpen) {
        this.modalOpen[modalName] = false
      }
    } else {
      this.modalOpen[modalName] = false
    }
    // unsubscribe firebase payment transaction listener
    this.transactionListener && this.transactionListener()
  }

  async createTransaction(paymentReference: any, data = {}) {
    try {
      await firebase
        .firestore()
        .collection('payment_transactions')
        .doc(paymentReference)
        .set(data)

      this.transactionListener = firebase
        .firestore()
        .collection('payment_transactions')
        .doc(paymentReference)
        .onSnapshot(snapshot => {
          if (!snapshot.exists && !snapshot.data()) {
            this.handleCloseModal('all')
            this.$emit('billPaid')
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  scrollToTop() {
    const ionContent = document.querySelector('ion-content')
    ionContent && ionContent.scrollToTop(500)
  }
}
</script>

<style lang="scss">
.ion-md-time {
  color: red !important;
}
.ion-ios-time {
  color: red !important;
}
</style>

<style scoped lang="scss">
.payment-method {
  padding: 3px 2px 5px 3px;
  display: flex;
  justify-content: center;

  ion-card {
    margin: 0 0 0 0;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    border: 0.5px solid $color-primary;

    .closed-text {
      text-align: center;
      display: none;
    }

    .content {
      padding: 10px;
    }

    ion-icon {
      display: block;
      margin: 0 auto;
      font-size: 32px;
    }

    ion-icon {
      display: block;
      margin: 0 auto;
      font-size: 32px;
    }

    ion-text {
      width: 100%;
      display: block;
      text-align: center;
      font-size: 12px;
      margin-top: 4px;
    }

    .fee {
      font-size: 0.9em;
      text-align: center;
      color: $color-primary;
    }
  }

  ion-card.closed {
    border: 0.5px solid #bdbdbd;

    .closed-text {
      display: block;
      background: #3b3b3b;
      opacity: 0.85;
      color: white;
      padding: 10px;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    ion-icon {
      opacity: 0.2;
    }
  }
}
</style>
