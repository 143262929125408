<template>
  <PageLayout style="padding-bottom: 120px">
    <PageHeader :title="$t('bill.bill_groups')" :withBackButton="true">
      <template v-slot:action>
        <div style="float: right">
          <IonButton
            mode="md"
            fill="clear"
            size="small"
            class="page-header-action"
            @click="filterModalOpen = true"
          >
            <IonIcon src="/assets/icon/filter.svg" />
          </IonButton>
        </div>
      </template>
    </PageHeader>

    <BillTabBar currentTab="bill_groups" />

    <EmptyListPlaceHolder
      v-if="!visibleInvoiceGroups.length"
      style="padding-top: 100px"
      :content="noDataMessage"
    />

    <div v-else class="bill-group-list">
      <div v-for="(invoiceGroup, i) in visibleInvoiceGroups" :key="i">
        <BillGroupCard
          :highlighted="invoiceGroup._id === highlightId"
          :invoiceGroup="invoiceGroup"
          cardStyle="outline"
          @ungroup="deleteInvoiceGroup"
        />
      </div>
    </div>

    <IonInfiniteScroll
      @ionInfinite="handleLoadMore"
      threshold="1px"
      id="infinite-scroll"
      :disabled="ionInfiniteDisable"
    >
      <IonInfiniteScrollContent loadingSpinner="crescent"> </IonInfiniteScrollContent>
    </IonInfiniteScroll>

    <BillFilter
      :isOpen="filterModalOpen"
      :projects="$store.state.projects || []"
      stateType="unpaidGroup"
      ref="billFilter"
      v-on:close="filterModalOpen = false"
      v-on:submit="handleFilter"
    />
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue, mixins } from 'vue-class-component'
import PageLayout from '@/components/Layout/PageLayout.vue'
import EmptyListPlaceHolder from '@/components/EmptyListPlaceHolder.vue'
import { BillTabBar } from '@/modules/bills'
import PageHeader from '@/components/Layout/PageHeader.vue'
import { Pagination, AlertModal } from '@/mixins'
import BillFilter from '@/components/filters/BillFilter.vue'
import {
  IonIcon,
  IonButton,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import BillGroupCard from '@/modules/bills/BillGroupCard.vue'
import { flashResponseError } from '@/utils/flashMessage'
import axiosNoLoader from '@/http-no-loading'

class Props {
  $refs!: any
}

@Component({
  components: {
    IonIcon,
    IonButton,
    IonCol,
    PageHeader,
    EmptyListPlaceHolder,
    PageLayout,
    BillGroupCard,
    BillFilter,
    BillTabBar,
    ConfirmModal,
    IonInfiniteScroll,
    IonInfiniteScrollContent
  }
})
export default class BillList extends mixins(Vue.with(Props), Pagination, AlertModal) {
  invoiceGroups = [] as any[]
  filterModalOpen = false
  highlightId: any = null
  noDataMessage = ''
  ionInfiniteDisable = false

  params = {
    orderBy: 'asc',
    orderField: 'dueAt'
  }

  get visibleInvoiceGroups(): any[] {
    if (this.highlightId) {
      const highlight = this.invoiceGroups.find(
        invoiceGroup => invoiceGroup._id === this.highlightId
      )
      if (highlight) {
        return [
          highlight,
          ...this.invoiceGroups.filter(
            invoiceGroup => invoiceGroup._id !== this.highlightId
          )
        ]
      }
    }

    return this.invoiceGroups
  }

  async mounted() {
    this.fetchInvoiceGroups()
    this.fetchProjects()
    this.highlightId = this.$route.query.highlightId
    this.noDataMessage = this.$t('bill.no_pending_bills')
  }

  handleLoadMore(e: any) {
    this.pagination.currentPage += 1
    const filter = this.$refs.billFilter.formatFilter()
    const parameters = this.withPagination({
      ...filter,
      ...this.params,
      ...this.pagination
    })
    axiosNoLoader
      .get('/invoice/group', {
        params: {
          ...parameters
        }
      })
      .then(res => {
        setTimeout(() => {
          this.ionInfiniteDisable = !res.data.data.length
          // res.data.data.forEach((v: any) => {
          //   this.invoices.push(v)
          // })
          this.invoiceGroups.push(...res.data.data)
          e.target.complete()
        }, 500)
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleFilter() {
    this.noDataMessage = this.$t('label.data_not_found')
    this.filterModalOpen = false
    this.ionInfiniteDisable = false
    this.setCurrentPage(1)
    this.fetchInvoiceGroups()
  }

  fetchInvoiceGroups(_params = {}) {
    const filter = this.$refs.billFilter.formatFilter()
    const params = this.withPagination({
      orderBy: 'asc',
      orderField: 'dueAt',
      ...filter,
      ..._params
    })

    this.$http.get('/invoice/group', { params }).then(res => {
      this.invoiceGroups = res.data?.data
      this.setPagination(res.data?.meta)
    })
  }

  fetchProjects() {
    this.$http.get('/capi/project').then(res => {
      this.$store.state.projects = res.data
    })
  }

  deleteInvoiceGroup(id: string) {
    this.$http
      .delete('/invoice/group/' + id)
      .then(() => {
        this.fetchInvoiceGroups({ page: 1, perPage: 20 })
        this.openAlertModal(this.$t('bill.modal.ungroup.invoice_ungrouped'))
      })
      .catch(flashResponseError)
  }
}
</script>

<style scoped lang="scss">
.bill-group-list {
  margin-top: -2px;
  margin-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 50px;
}
</style>
