import { DeviceUUID } from 'device-uuid'

export const getLogInDevice = () => {
  const du = new DeviceUUID()
  const uuid = du.get()
  const source = du.parse().source

  return {
    uuid,
    source
  }
}
