
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonSlides,
  IonSlide,
  IonButton
} from '@ionic/vue'
import AnnouncementCard from '@/components/Announcement/AnnouncementCard.vue'
class Props {
  announcements!: {
    id: string
    title: string
    content: string
    image: string[]
    projectId: number
    userId: number
    createdAt: string
    updatedAt: string
    projectNameTh: string
    projectNameEn: string
  }[]
}
@Component({
  components: {
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
    AnnouncementCard,
    IonSlides,
    IonSlide,
    IonButton
  },
  props: {
    announcements: {
      type: Array
    }
  }
})
export default class AnnouncementSlide extends Vue.with(Props) {
  defaultAnnouncement = {
    _id: null,
    title: 'ยินดีตอนรับสู่',
    content: '',
    image: ['/assets/default-content-image.png'],
    projectId: 1,
    userId: 1,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    projectNameTh: '',
    projectNameEn: ''
  }
  announcementCardClicked(announcement: any) {
    this.$router.push({
      name: 'Announcement Show',
      params: { id: announcement._id, data: JSON.stringify(announcement) }
    })
  }
  get slideConfig() {
    return {
      spaceBetween: 50,
      slidesPerView:
        !!this.announcements.length && this.announcements.length > 1 ? 1.15 : 1,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 200,
        modifier: 2,
        slideShadows: false
      },
      on: {
        beforeInit() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const swiper = this as any

          swiper.classNames.push(`${swiper.params.containerModifierClass}coverflow`)
          swiper.classNames.push(`${swiper.params.containerModifierClass}3d`)

          swiper.params.watchSlidesProgress = true
          swiper.originalParams.watchSlidesProgress = true
        },
        setTranslate() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const swiper = this as any
          const {
            width: swiperWidth,
            height: swiperHeight,
            slides,
            $wrapperEl,
            slidesSizesGrid,
            $
          } = swiper
          const params = swiper.params.coverflowEffect
          const isHorizontal = swiper.isHorizontal()
          const transform$$1 = swiper.translate
          const center = isHorizontal
            ? -transform$$1 + swiperWidth / 2
            : -transform$$1 + swiperHeight / 2
          const rotate = isHorizontal ? params.rotate : -params.rotate
          const translate = params.depth
          // Each slide offset from center
          for (let i = 0, length = slides.length; i < length; i += 1) {
            const $slideEl = slides.eq(i)
            const slideSize = slidesSizesGrid[i]
            const slideOffset = $slideEl[0].swiperSlideOffset
            const offsetMultiplier =
              ((center - slideOffset - slideSize / 2) / slideSize) * params.modifier

            let rotateY = isHorizontal ? rotate * offsetMultiplier : 0
            let rotateX = isHorizontal ? 0 : rotate * offsetMultiplier
            // var rotateZ = 0
            let translateZ = -translate * Math.abs(offsetMultiplier)

            let translateY = isHorizontal ? 0 : params.stretch * offsetMultiplier
            let translateX = isHorizontal ? params.stretch * offsetMultiplier : 0

            // Fix for ultra small values
            if (Math.abs(translateX) < 0.001) translateX = 0
            if (Math.abs(translateY) < 0.001) translateY = 0
            if (Math.abs(translateZ) < 0.001) translateZ = 0
            if (Math.abs(rotateY) < 0.001) rotateY = 0
            if (Math.abs(rotateX) < 0.001) rotateX = 0

            const slideTransform = `translate3d(${translateX}px,${translateY}px,${translateZ}px)  rotateX(${rotateX}deg) rotateY(${rotateY}deg)`

            $slideEl.transform(slideTransform)
            $slideEl[0].style.zIndex = -Math.abs(Math.round(offsetMultiplier)) + 1
          }
          // Set correct perspective for IE10
          if (swiper.support.pointerEvents || swiper.support.prefixedPointerEvents) {
            const ws = $wrapperEl[0].style
            ws.perspectiveOrigin = `${center}px 30%`
          }
        },
        setTransition(duration: any) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const swiper = this as any
          swiper.slides
            .transition(duration)
            .find(
              '.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left'
            )
            .transition(duration)
        }
      }
    }
  }
}
