<template>
  <div class="mx-n2">
    <slot name="header" />
    <IonContent :class="backgroundClass" :fullscreen="true" :scrollY="isScrollY">
      <IonGrid class="fill-height py-0 ma-0" :class="getClass">
        <DesktopNavBar />
        <div style="margin-left: 128px;">
          <div class="pa-4">
            <IonRow class="ion-justify-content-between ion-align-items-center mb-8">
              <IonCol size="auto">
                <ProfileBanner :desktop="true" />
              </IonCol>

              <IonCol size="auto" class="">
                <IonButton
                  size="medium"
                  color="dark-blue"
                  @click="$router.push('/my_projects')"
                >
                  {{ $t('home.my_projects') }}
                </IonButton>
                <IonButton fill="clear" @click="$router.push('/settings')">
                  <IonIcon
                    size="large"
                    class="pa-0 cog-icon"
                    src="/assets/icon/settings.svg"
                  />
                </IonButton>
              </IonCol>
            </IonRow>
            <slot></slot>
          </div>
        </div>
      </IonGrid>
    </IonContent>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonPage,
  IonContent,
  IonGrid,
  IonRefresher,
  IonRefresherContent
} from '@ionic/vue'
import DesktopNavBar from '@/components/desktop/DesktopNavBar.vue'
import ProfileBanner from '@/components/ProfileBanner.vue'

class Props {
  background?: string
  isFullScreen?: {
    type: boolean
    default: false
  }
  isScrollY?: {
    type: boolean
    default: true
  }
}

@Component({
  components: {
    ProfileBanner,
    DesktopNavBar,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonPage,
    IonContent,
    IonGrid,
    IonRefresher,
    IonRefresherContent
  },
  props: ['background', 'isFullScreen', 'isScrollY']
})
export default class DesktopPageLayout extends Vue.with(Props) {
  get backgroundClass() {
    return this.background ? 'st-background-' + this.background : 'st-background-grey'
  }
  get getClass() {
    if (this.isFullScreen) return 'px-0'
    else return 'px-2'
  }
}
</script>

<style scoped lang="scss">
ion-content {
  &.st-background-white {
    --background: #fff;
  }
  &.st-background-grey {
    --background: #fff url('/assets/img/background/grey_bg.png') no-repeat fixed center /
      cover;
    &::part(background) {
      background: #fff url('/assets/img/background/grey_bg.png') no-repeat fixed center /
        cover;
    }
  }
  &.st-background-color {
    --background: #fff url('/assets/img/background/city_bg.svg') no-repeat fixed center /
      cover;
    &::part(background) {
      background: #fff url('/assets/img/background/city_bg.svg') no-repeat fixed center /
        cover;
    }
  }
}
</style>
