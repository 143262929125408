export const flashSuccess = (message = 'Operation completed.') => {
  console.log(message)
  alert(message)
}

export const flashResponseError = (error: any) => {
  try {
    const defaultMessage = 'Operation failed, please try again.'
    const responseMessage = error?.response?.data?.message || error?.message
    let message = responseMessage?.message || responseMessage

    const errorMessageIsObject =
      responseMessage !== null &&
      !Array.isArray(responseMessage) &&
      typeof responseMessage === 'object'

    if (errorMessageIsObject) {
      const firstKey = Object.keys(responseMessage)[0]
      const firstMessage = responseMessage[firstKey]

      if (Array.isArray(firstMessage) && firstMessage.length > 0) {
        message = `${firstKey} ${firstMessage[0]}`
      }
    }

    if (message === null || typeof message !== 'string') {
      message = defaultMessage
    }

    if (typeof error === 'string') {
      message = error
    }

    console.log(error)
    console.log(message)
    alert(message)
  } catch (err) {
    console.log(err)
    console.log(error)
  }
}
