<template>
  <template>
    <PrivacyPolicyModal v-if="policyModalOpen" />
    <TermsAndConditionsModal v-if="termsModalOpen" />
    <AlertModal v-if="alertModal.isOpen" @close="alertModal.isOpen = false">
      <template v-slot:content>
        {{ alertModal.content }}
      </template>
    </AlertModal>
  </template>
</template>
<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import PrivacyPolicyModal from '@/modules/authentication/PrivacyPolicyModal.vue'
import TermsAndConditionsModal from '@/modules/authentication/TermsAndConditionsModal.vue'
import AlertModal from '@/components/modals/AlertModal.vue'

@Component({
  components: { PrivacyPolicyModal, TermsAndConditionsModal, AlertModal }
})
export default class AppModal extends Vue {
  get policyModalOpen(): boolean {
    return this.$store.state.modal.policy.isOpen
  }
  get termsModalOpen(): boolean {
    return this.$store.state.modal.terms.isOpen
  }
  get alertModal() {
    return this.$store.state.modal.alert
  }
}
</script>
