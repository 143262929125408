
import { Options as Component, Vue, mixins } from 'vue-class-component'
import PageLayout from '@/components/Layout/PageLayout.vue'
import EmptyListPlaceHolder from '@/components/EmptyListPlaceHolder.vue'
import { BillCard, BillTabBar } from '@/modules/bills'
import BillGroupCard from '@/modules/bills/BillGroupCard.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import { Pagination, SortBy } from '@/mixins'
import BillFilter from '@/components/filters/BillFilter.vue'
import {
  IonIcon,
  IonButton,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/vue'
import axiosNoLoader from '@/http-no-loading'

class Props {
  $refs!: any
}

@Component({
  components: {
    IonIcon,
    IonButton,
    IonRow,
    IonCol,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    PageHeader,
    EmptyListPlaceHolder,
    PageLayout,
    BillCard,
    BillGroupCard,
    BillFilter,
    BillTabBar
  }
})
export default class BillHistory extends mixins(Vue.with(Props), Pagination, SortBy) {
  invoiceGroups: any[] = []
  invoices: any[] = []
  filterModalOpen = false
  ionInfiniteDisable = false

  params = {
    orderBy: 'asc',
    orderField: 'dueAt'
  }

  get sortByPopover() {
    return {
      options: [
        {
          label: this.$t('attribute.dueAt'),
          value: 'dueAt'
        },
        {
          label: this.$t('attribute.issueAt'),
          value: 'createdAt'
        }
      ],
      optionValue: this.params.orderField
    }
  }

  get items() {
    return [...this.invoiceGroups, ...this.invoices]
  }

  async mounted() {
    this.fetchInvoices()
    this.fetchInvoiceGroups()
    this.fetchProjects()
  }

  handleLoadMore(e: any) {
    this.pagination.currentPage += 1
    const filter = this.$refs.billFilter.formatFilter()
    const params = this.withPagination({
      ...this.params,
      ...filter,
      ...this.pagination,
      isGrouped: 0
    })

    Promise.all([
      axiosNoLoader.get('/invoice', { params }),
      axiosNoLoader.get('/invoice/group', { params })
    ])
      .then(responses => {
        setTimeout(() => {
          const invoiceRes = responses[0]
          const invoiceGroupRes = responses[1]
          this.ionInfiniteDisable = !(
            invoiceRes?.data?.data.length + invoiceGroupRes?.data?.data.length
          )
          this.invoices.push(...invoiceRes.data.data)
          this.invoices.push(...invoiceGroupRes.data.data)
        }, 500)
      })
      .catch(console.log)
  }

  handleSortBy(orderField: string) {
    this.params.orderField = orderField
    if (orderField === 'createdAt') this.params.orderBy = 'desc'
    if (orderField === 'dueAt') this.params.orderBy = 'asc'
    this.ionInfiniteDisable = false
    this.setCurrentPage(1)
    this.fetchInvoices()
  }

  handleFilter() {
    this.filterModalOpen = false
    this.ionInfiniteDisable = false
    this.setCurrentPage(1)
    this.fetchInvoices()
  }

  fetchInvoices(_params = {}) {
    const filter = this.$refs.billFilter.formatFilter()
    const params = this.withPagination({
      isGrouped: 0,
      ...this.params,
      ...filter,
      ..._params
    })

    this.$http.get('/invoice', { params }).then(res => {
      this.invoices = res.data?.data
      this.setPagination(res.data?.meta)
    })
  }

  fetchInvoiceGroups(_params = {}) {
    const filter = this.$refs.billFilter.formatFilter()
    const params = this.withPagination({
      ...this.params,
      ...filter,
      ..._params
    })

    this.$http.get('/invoice/group', { params }).then(res => {
      this.invoiceGroups = res.data?.data
      this.setPagination(res.data?.meta)
    })
  }

  fetchProjects() {
    this.$http.get('/capi/project').then(res => {
      this.$store.state.projects = res.data
    })
  }
}
