<template>
  <div style="display: table; height: 100%; overflow: hidden">
    <div style="display: table-cell; vertical-align: middle">
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'

class Props {}

@Component({})
export default class CenterVerticalAlign extends Vue.with(Props) {}
</script>
