<template>
  <IonRow class="ion-justify-content-start ion-align-items-center">
    <IonCol size="auto">
      <div :class="desktop ? 'line-left--desktop' : 'line-left--mobile'" />
    </IonCol>
    <IonCol size="auto" style="height: 48px">
      <img
        @click="goToProfile"
        class="profile-image mr-2"
        :src="
          $store.state.user.profileImage
            ? GET_SECURED_IMAGE($store.state.user.profileImage)
            : '/assets/default_profile_picture.svg'
        "
      />
    </IonCol>
    <IonCol size="auto" @click="goToProfile">
      <IonCardTitle class="greeting-text">
        {{ $t('home.hello') }},
        <span :class="desktop ? 'name-highlight--desktop' : 'name-highlight--mobile'">
          {{ $store.state.user.firstName }}
        </span>
      </IonCardTitle>
      <IonCardSubtitle class="subTitle">
        <b>
          {{ $t('home.see_your_profile') }}
        </b>
      </IonCardSubtitle>
    </IonCol>
    <!--<IonCol size="auto">-->
    <!--  <IonButton size="small" @click="$router.push('/my_projects')">-->
    <!--    {{ $t('home.my_projects') }}-->
    <!--  </IonButton>-->
    <!--</IonCol>-->
  </IonRow>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonCardTitle,
  IonCardSubtitle
} from '@ionic/vue'

class Props {
  desktop?: boolean | false
}

@Component({
  components: { IonCard, IonGrid, IonRow, IonCol, IonCardTitle, IonCardSubtitle },
  props: ['desktop']
})
export default class ProfileBanner extends Vue.with(Props) {
  goToProfile() {
    this.$router.push('/user')
  }
}
</script>

<style scoped lang="scss">
.profile-card {
  box-shadow: none;
}
.profile-image {
  height: 48px;
  border: 0.5px solid #c6c6c6;
  border-radius: 10px;
  opacity: 1;
  margin-left: 4px;
}
@mixin line-left($color) {
  height: 48px;
  border-left: 4px solid $color;
  border-radius: 5px;
}
.line-left {
  &--mobile {
    @include line-left(#01b3bf);
  }
  &--desktop {
    @include line-left(#03256c);
  }
}
.greeting-text {
  text-align: left;
  font: normal normal normal 14px Prompt;
  letter-spacing: 0.47px;
  color: #333333;
  opacity: 1;
}

@mixin name-highlight($color) {
  text-align: left;
  font: normal normal normal 18px Prompt;
  letter-spacing: 0px;
  color: $color;
  opacity: 1;
}
.name-highlight {
  //text-align: left;
  //font: normal normal normal 18px Prompt;
  //letter-spacing: 0px;
  //color: #008caa;
  //opacity: 1;
  &--mobile {
    @include name-highlight(#01b3bf);
  }
  &--desktop {
    @include name-highlight(#03256c);
  }
}
.subTitle {
  text-align: left;
  font: normal normal normal 14px Prompt;
  letter-spacing: 0.35px;
  color: #9a9a9a;
  opacity: 1;
  text-transform: capitalize;
}
</style>
