<template>
  <div class="container">
    <IonRow v-if="creditNote" class="tab-bar ion-justify-content-between">
      <IonCol size="6" class="tab" :class="{ active: currentTab === 'invoice_details' }">
        <IonButton @click="currentTab = 'invoice_details'">
          {{ $t('bill.tab.invoice_details') }}
        </IonButton>
      </IonCol>
      <IonCol size="6" class="tab" :class="{ active: currentTab === 'credit_note' }">
        <IonButton @click="currentTab = 'credit_note'">
          {{ $t('bill.tab.credit_note') }}
        </IonButton>
      </IonCol>
    </IonRow>

    <Card class="bill-card" :button="false" cardStyle="outline">
      <template v-if="currentTab === 'invoice_details'">
        <IonRow class="bill-header ion-justify-content-between">
          <IonCol size="auto">{{ invoice.number }}</IonCol>
          <IonCol size="auto"><BillState :invoice="invoice"/></IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <CustomerDetails :invoice="invoice" wrapper="well" />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="12" class="bill-item-title">
            {{ $t('bill.label.items') }}
          </IonCol>
        </IonRow>

        <IonRow v-for="item in invoiceItems" :key="item?.id" class="bill-item">
          <IonCol size="2">
            <img :src="item.iconType" style="width: 100%" />
          </IonCol>

          <IonCol size="10" class="ion-no-padding">
            <IonRow class="bill-item-desc">
              <IonCol size="12">
                <div>{{ item?.description }}</div>
                <div>{{ item?.description2 }}</div>
              </IonCol>
            </IonRow>

            <IonRow v-if="itemGotVat(item)" class="bill-item-vat">
              <IonCol size="7">{{ $t('bill.label.vat') }} ({{ item.vatType }})</IonCol>
              <IonCol size="5">{{ item?.vat?.fullDisplay }}</IonCol>
            </IonRow>

            <IonRow v-if="itemGotWht(item)" class="bill-item-vat">
              <IonCol size="7">{{ $t('bill.label.wht') }} ({{ item.whtType }})</IonCol>
              <IonCol size="5">{{ item?.wht?.fullDisplay }}</IonCol>
            </IonRow>

            <IonRow class="bill-item-total">
              <IonCol size="7">{{ $t('bill.label.total') }}</IonCol>
              <IonCol size="5">{{ item?.total?.fullDisplay }}</IonCol>
            </IonRow>
          </IonCol>

          <IonCol size="12">
            <div class="bill-item-underline" />
          </IonCol>
        </IonRow>

        <IonRow class="bill-summary">
          <IonCol class="ion-no-padding">
            <IonRow>
              <IonCol size="7">{{ $t('bill.label.sub_total') }}</IonCol>
              <IonCol size="5">{{ invoice?.total?.fullDisplay }}</IonCol>
            </IonRow>

            <IonRow v-if="invoice?.totalCreditNoteCents">
              <IonCol size="7">{{ $t('bill.label.total_credit_note') }}</IonCol>
              <IonCol size="5">
                {{ invoice?.totalCreditNote?.fullDisplay }}
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="7">{{ $t('bill.label.discount') }}</IonCol>
              <IonCol size="5">
                {{
                  invoice?.totalDiscount?.fractional
                    ? invoice?.totalDiscount?.fullDisplay
                    : '-'
                }}
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="7">{{ $t('bill.label.total_after_discount') }}</IonCol>
              <IonCol size="5">{{ invoice?.totalAfterDiscount?.fullDisplay }}</IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="7">{{ $t('bill.label.vat') }}</IonCol>
              <IonCol size="5">{{ invoice?.vat?.fullDisplay }}</IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="7">{{ $t('bill.label.wht') }}</IonCol>
              <IonCol size="5">{{ invoice?.wht?.fullDisplay }}</IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </template>

      <template v-else-if="currentTab === 'credit_note'">
        <IonRow class="bill-header ion-justify-content-between">
          <IonCol size="auto">{{ creditNote.number }}</IonCol>
          <IonCol />
        </IonRow>

        <IonRow>
          <IonCol>
            <div class="credit-note-details well-background">
              <div>
                <span>{{ $t('bill.label.due_date') }} : </span>
                <span>{{ THAI_DATE(creditNote.dueAt) }}</span>
              </div>
              <div>
                <span>{{ $t('bill.label.invoice_no') }} : </span>
                <span>{{ invoice.number }}</span>
              </div>
            </div>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="12" class="bill-item-title">
            {{ $t('bill.label.items') }}
          </IonCol>

          <IonCol v-for="(item, i) in creditNote.items" :key="i" size="12">
            {{ item.total.fullDisplay }} {{ item.description }}
            {{ item.description2 }}
          </IonCol>
        </IonRow>

        <IonRow class="bill-summary">
          <IonCol class="ion-no-padding">
            <IonRow>
              <IonCol size="7">{{ $t('bill.label.total_credit_note') }}</IonCol>
              <IonCol size="5">{{ creditNote?.grandTotal?.fullDisplay }}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="7">{{ $t('bill.label.invoice_amount') }}</IonCol>
              <IonCol size="5">{{ invoice?.grandTotal?.fullDisplay }}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="7">{{ $t('bill.label.total_to_be_paid') }}</IonCol>
              <IonCol size="5">
                {{ invoice?.grandTotalAfterCreditNote?.fullDisplay }}
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </template>
    </Card>

    <GrandTotalCard :grandTotal="invoice?.grandTotalAfterCreditNote?.fullDisplay" />

    <DocumentDownload
      v-if="!invoice.invoiceGroupId"
      @downloadInvoice="
        $router.push({ path: `/bills/${invoice._id}/pdf`, query: { type: 'invoice' } })
      "
      @downloadReceipt="
        $router.push({ path: `/bills/${invoice._id}/pdf`, query: { type: 'receipt' } })
      "
      @downloadCreditNote="
        $router.push({ path: `/bills/${invoice._id}/pdf`, query: { type: 'credit' } })
      "
      :receiptDisabled="invoice?.state !== 'paid' || invoice?.invoiceGroupId"
      :showCreditNote="invoice?.creditNotes?.length > 0"
    />

    <PaymentsPanel
      v-if="invoice?.state === 'pending' && !invoice?.invoiceGroupId"
      :invoice="invoice"
      v-on:billPaid="$emit('billPaid')"
      @reload="$emit('reload')"
    />
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonCol, IonRow, IonButton } from '@ionic/vue'

import { Card } from '@/components/Card'
import { PaymentsPanel } from '@/modules/bills'
import CustomerDetails from '@/modules/bills/CustomerDetails.vue'
import BillState from '@/modules/bills/BillState.vue'
import GrandTotalCard from '@/modules/bills/GrandTotalCard.vue'
import DocumentDownload from '@/modules/bills/DocumentDownload.vue'
import _ from 'lodash'

class Props {
  invoice!: any
  payment!: any
}

@Component({
  components: {
    PaymentsPanel,
    Card,
    BillState,
    CustomerDetails,
    GrandTotalCard,
    DocumentDownload,
    IonCol,
    IonRow,
    IonButton
  },
  props: ['invoice', 'payment'],
  emits: ['billPaid']
})
export default class BillShowContent extends Vue.with(Props) {
  currentTab: 'invoice_details' | 'credit_note' = 'invoice_details'

  get creditNote() {
    return _.get(this.invoice, 'creditNotes[0]')
  }

  get invoiceItems(): any[] {
    if (this.invoice?.type === 'invoice_groups') {
      return this.invoice.invoices?.flatMap((invoice: any) => invoice.invoiceItems)
    }
    return this.invoice?.invoiceItems || []
  }

  itemGotVat(item: any) {
    return item.vatType !== '0.00' && item.vatType !== 'none'
  }

  itemGotWht(item: any) {
    return (
      item.whtType != '0.00' && item.whtType != 'none' && item.whtType != 'not_specified'
    )
  }
}
</script>

<style scoped lang="scss">
.container {
  padding: 10px 10px 40px 10px;
}

.bill-card {
  padding: 10px 10px 10px 10px !important;
  margin-bottom: 9px !important;
  margin-top: 0px !important;
}

.bill-header {
  ion-col:first-child {
    font-size: 20px;
    color: $color-primary-dark;
  }
  ion-col:last-child {
    padding-top: 8px;
  }
}

.bill-item-title {
  font-size: 16px;
  padding-left: 5px;
}

.bill-item {
  padding-right: 5px;
  margin-bottom: 8px;

  .bill-item-desc {
    ion-col {
      font-size: 14px;
      font-weight: 200;
      color: #333333;
      padding-top: 3px;
      padding-bottom: 0px;
    }
  }

  .bill-item-vat {
    ion-col {
      font-size: 14px;
      font-weight: 200;
      color: #333333;
      padding-top: 3px;
      padding-bottom: 0px;
    }

    ion-col:last-child {
      text-align: right;
    }
  }

  .bill-item-total {
    margin-top: 4px;
    margin-bottom: 4px;

    ion-col {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      padding-top: 3px;
      padding-bottom: 3px;
    }

    ion-col:last-child {
      text-align: right;
    }
  }

  .bill-item-underline {
    border-bottom: 1px solid #f1f1f1;
  }
}

.bill-summary {
  padding-left: 5px;
  padding-right: 5px;

  ion-row {
    ion-col:first-child {
      font-size: 16px;
      color: #333333;
    }

    ion-col:last-child {
      text-align: right;
      font-size: 16px;
      color: #333333;
    }
  }
}

.credit-note-details {
  padding: 10px 10px 10px 10px;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;

  div {
    font-size: 14px;

    span:first-child {
      color: #9a9a9a;
    }
    span:last-child {
      color: #333333;
    }
  }
}

.tab-bar {
  padding: 0px 8px 0px 0px;
  margin-bottom: -5px;

  .tab {
    height: 50px;
    border: 1px solid rgba(255, 255, 255, 0);
    font-size: 16px;

    ion-button {
      --border-radius: 10px 10px 0px 0px;
      --background: var(--color-primary-dark);
      width: 98%;
      height: 35px;
      margin-left: 5px;
      margin-right: 5px;
      position: absolute;
      bottom: 0;
    }
  }

  .tab.active {
    ion-button {
      --background: var(--color-primary);
      height: 40px !important;
    }
  }
}
</style>

<style lang="scss">
.bill__header__toolbar {
  --border-color: var(--ion-color-primary);
}
</style>
