
import { Options as Component, Vue } from 'vue-class-component'
import {} from '@ionic/vue'

class Props {
  duration!: number
}

@Component({
  components: {},
  props: {
    duration: {
      type: Number,
      default: 800
    }
  },
  emits: ['trigger']
})
export default class ClickAndHold extends Vue.with(Props) {
  interval: any = false
  count = 0

  press() {
    if (!this.interval) {
      this.interval = setInterval(() => {
        this.count += 100
        if (this.count > this.duration) {
          this.$emit('trigger')
          this.release()
        }
      }, 100)
    }
  }
  release() {
    clearInterval(this.interval)
    this.interval = false
    this.count = 0
  }
}
