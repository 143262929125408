
import { Options as Component, Vue } from 'vue-class-component'
import { IonCol, IonRow, IonButton } from '@ionic/vue'

class Props {
  receiptDisabled?: boolean
  showCreditNote?: boolean
}

@Component({
  components: {
    IonCol,
    IonRow,
    IonButton
  },
  props: ['receiptDisabled', 'showCreditNote'],
  emits: ['downloadInvoice', 'downloadReceipt', 'downloadCreditNote']
})
export default class DocumentDownload extends Vue.with(Props) {
  emitDownloadReceipt() {
    this.$emit('downloadReceipt')
  }
}
