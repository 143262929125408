
import { Options as Component, Vue } from 'vue-class-component'
import PageLayout from '@/components/Layout/PageLayout.vue'
import {
  IonPage,
  IonContent,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonButton,
  IonCard,
  IonImg
} from '@ionic/vue'
import LanguageBadgeSwitch from '@/components/language/LanguageBadgeSwitch.vue'

class Props {}

@Component({
  components: {
    LanguageBadgeSwitch,
    PageLayout,
    IonPage,
    IonContent,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonItem,
    IonButton,
    IonCard
  },
  props: []
})
export default class LandingPage extends Vue.with(Props) {
  startedBtnClicked() {
    try {
      console.log('STARTD: get start')
      this.$router.push('/login')
    } catch (e) {
      console.log('STARTD: get start error', e)
    }
  }
}
