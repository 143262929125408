<template>
  <IonItem>
    <slot name="prepend" />
    <IonLabel v-if="label || name" class="field-label">{{ label || name }}</IonLabel>
    <IonInput
      :value="modelValue"
      @input="changeValue"
      :placeholder="placeholder"
      :name="name"
      :autocomplete="autocomplete"
      :type="type"
      :inputmode="inputmode"
      :pattern="pattern"
      :maxlength="maxlength"
      :required="required"
      :autofocus="autofocus"
    />
    <slot name="append" />
  </IonItem>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonItem, IonLabel, IonInput } from '@ionic/vue'

class Props {
  name?: string
  label?: string
  modelValue!: string
  placeholder?: string
  autocomplete?: string
  type?: string | 'text'
  inputmode?: string
  pattern?: string
  maxlength?: [string, number]
  required?: boolean | false
  autofocus?: boolean | false
}

@Component({
  components: { IonItem, IonLabel, IonInput },
  props: [
    'name',
    'label',
    'modelValue',
    'placeholder',
    'autocomplete',
    'type',
    'inputmode',
    'pattern',
    'maxlength',
    'required',
    'autofocus'
  ],
  emits: ['update:modelValue']
})
export default class BaseField extends Vue.with(Props) {
  changeValue(event: any) {
    this.$emit('update:modelValue', event.target.value)
  }
}
</script>

<style scoped lang="scss">
ion-input {
  height: 30px;
}
ion-item {
  --min-height: 30px;
}
.item-interactive {
  --border-radius: 5px;
  --border-width: 1px;
  --inner-border-width: 0;
  &.item-has-focus {
    --border-color: var(--highlight-color-focused);
    --highlight-background: none;
  }
}
.field-label {
  display: block;
  font-size: 14px;
  color: var(--ion-color-medium) !important;
}

.error-message-placeholder {
  opacity: 0;
}

.error-message {
  font-size: 14px;
}
</style>
