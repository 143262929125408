
import { IonCard } from '@ionic/vue'
import { Options as Component, Vue } from 'vue-class-component'

class Props {
  cssClass?: string
  cssStyle?: string
  button?: boolean
  cardStyle?: string | 'outline'
  disabled?: boolean
}

@Component({
  components: { IonCard },
  props: {
    cssClass: {
      type: String
    },
    cssStyle: {
      type: String,
      default: ''
    },
    button: {
      type: Boolean,
      default: true
    },
    cardStyle: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class Card extends Vue.with(Props) {}
