
import { Options as Component, Vue } from 'vue-class-component'
import Attribute from './Attribute.vue'

class Props {
  label?: string
}

@Component({
  components: { Attribute },
  props: ['label']
})
export default class TextAreaAttribute extends Vue.with(Props) {}
