
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonText
} from '@ionic/vue'
import DesktopPageLayout from '@/components/Layout/DesktopPageLayout.vue'
import { ServiceDataInterface } from '@/interfaces/service'

class Props {
  serviceList!: ServiceDataInterface[]
}

@Component({
  components: {
    DesktopPageLayout,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonText
  },
  props: ['serviceList']
})
export default class ServiceHomeDesktop extends Vue.with(Props) {
  serviceClicked(name: string, iframeLink: string) {
    this.$router.push({ path: '/services/' + name, query: { iframeLink } })
  }
}
