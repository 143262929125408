import { useLoading } from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'

const loading = {
  // called by Vue.use(FirstPlugin)
  install(app: any, options: any) {
    const loader = useLoading()
    app.config.globalProperties.$loading = {
      show: () => {
        loader.show({
          color: '#01d3bf'
          // Optional parameters
        })
      },
      hide: () => {
        loader.hide()
      }
    }
  }
}

export default loading
