<template>
  <IonRow>
    <IonCol size="12">
      <pdf
        v-if="pageCount === 1"
        :src="getData"
        @num-pages="pageCount = $event"
        @page-loaded="currentPage = $event"
        :page="currentPage"
      ></pdf>
      <pdf
        v-else
        v-for="i in pageCount"
        :key="i"
        :src="getData"
        :page="i"
        style="margin-bottom: 25px;"
      ></pdf>
      <div class="errorMsg">
        {{ errorMsg }}
      </div>
    </IonCol>

    <IonCol size="12">
      <!-- <IonButton
        v-if="currentPage > 1"
        mode="md"
        @click="() => currentPage--"
        class="float-button-prev"
      >
        {{ $t('button.prev') }}
      </IonButton> -->

      <!-- <IonButton
        v-if="currentPage < pageCount"
        mode="md"
        @click="() => currentPage++"
        class="float-button-next"
      >
        {{ $t('button.next') }}
      </IonButton> -->

      <IonButton
        expand="block"
        mode="md"
        @click="handleNativeFileSave"
        class="float-button"
      >
        <IonIcon color="white" icon="downloadOutline" />
        {{ $t('button.download') }}
      </IonButton>
    </IonCol>
  </IonRow>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonButton, IonCol, IonIcon, IonRow, isPlatform } from '@ionic/vue'
import pdf from 'vue3-pdf'
import { Base64ToGallery } from '@ionic-native/base64-to-gallery'
import { FilesystemDirectory, Plugins } from '@capacitor/core'
class Props {
  fileUrl!: string
  fileName!: string
  defaultPageCount!: number
}

@Component({
  components: { pdf, IonRow, IonCol, IonButton, IonIcon },
  props: ['fileName', 'fileUrl', 'defaultPageCount']
})
export default class PdfViewer extends Vue.with(Props) {
  currentPage = 1 as number
  pageCount = 1 as number
  pdfData = ''
  errorMsg = ''

  get getData() {
    return this.pdfData
  }

  async mounted() {
    this.pageCount = this.defaultPageCount || 1
    this.fetchPDF()
  }

  async fetchPDF() {
    await this.$http
      .get(this.fileUrl)
      .then(res => (this.pdfData = res.data))
      .catch(err => {
        console.log(err)
        if (
          err?.response?.data?.code === 'invoice_pdf_not_available_outside_payment_time'
        ) {
          this.errorMsg = err?.response?.data?.message
        }
      })
  }
  async handleNativeFileSave() {
    const { Filesystem } = Plugins
    const fileNameWithExtension = this.fileName + '.pdf'

    if (isPlatform('cordova')) {
      try {
        const options = {
          density: 100,
          saveFilename: 'untitled',
          format: 'png',
          width: 600,
          height: 600
        }
        Filesystem.writeFile({
          path: fileNameWithExtension,
          data: this.pdfData,
          directory: FilesystemDirectory.Documents
        })
          .then(async data => {
            const canvases = document.querySelectorAll('canvas')
            try {
              canvases.forEach((canvas, index) => {
                Base64ToGallery.base64ToGallery(
                  canvas.toDataURL('image/png', 1.0).split(',')[1],
                  {
                    mediaScanner: true,
                    prefix: this.fileName + '_' + index
                  }
                ).catch((e: any) => {
                  console.log('Failed to save each to gallery', e)
                  throw e
                })
              })
              alert(`Document is saved in gallery and file storage.`)
            } catch (e) {
              console.log('Saving images of pdf.', JSON.stringify(e))
              alert('Failed to save document to gallery.')
            }
          })
          .catch(e => {
            alert(e)
            this.handleDownloadPdf()
          })
      } catch (e) {
        this.handleDownloadPdf()
      }
    } else {
      this.handleDownloadPdf()
    }
  }
  handleDownloadPdf() {
    try {
      const element = document.createElement('a')
      element.href = this.pdfData
      element.download = this.fileName
      element.click()
      alert(`${this.fileName} is saved.`)
    } catch (e) {
      alert(`Unable to save file.`)
    }
  }
}
</script>

<style scoped lang="scss">
.errorMsg {
  font-size: 20px;
  color: #333333;
  padding-left: 25px;
  padding-right: 25px;
}
.float-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  left: 16px;
  z-index: 9999;
  font-size: 16px;
}

.float-button-prev {
  position: fixed;
  bottom: 70px;
  left: 16px;
  z-index: 9999;
}

.float-button-next {
  position: fixed;
  bottom: 70px;
  right: 16px;
  z-index: 9999;
}
</style>
