<template>
  <IonRow class="ion-justify-content-center" v-for="lang in languages" :key="lang.locale">
    <IonCol :size="size">
      <IonCard
        class="pa-2 text-black cursor-pointer"
        @click="$i18n.locale = lang.locale"
        :style="languageBadgeStyle(lang.locale)"
      >
        <IonRow class="ion-align-items-center">
          <IonCol size="auto" class="pr-2">
            <IonImg style="width: 20px" :src="lang.flagSrc" />
          </IonCol>
          <IonCol size="auto">
            {{ lang.text }}
          </IonCol>
        </IonRow>
      </IonCard>
    </IonCol>
  </IonRow>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonCard, IonImg } from '@ionic/vue'

class Props {
  size!: number | 12
}

@Component({
  components: { IonRow, IonCol, IonCard, IonImg },
  props: ['size']
})
export default class LanguageBadgeSwitch extends Vue.with(Props) {
  languages = [
    {
      locale: 'th',
      flagSrc: '/assets/icon/flag-TH.png',
      text: 'ไทย'
    },
    {
      locale: 'en',
      flagSrc: '/assets/icon/flag-EN.png',
      text: 'English'
    }
  ]
  languageBadgeStyle(lang: string) {
    return this.$i18n.locale === lang ? 'border: 2px solid #008CAA;' : null
  }
}
</script>

<style scoped lang="scss"></style>
