
import { Options as Component, Vue } from 'vue-class-component'
import { Card } from '@/components/Card'
import { IonCard } from '@ionic/vue'

class Props {
  announcement?: {}
}
@Component({
  components: { IonCard, Card },
  props: ['announcement']
})
export default class AnnouncementCard extends Vue.with(Props) {}
