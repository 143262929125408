
import { Options as Component, Vue } from 'vue-class-component'
import PageLayout from '@/components/Layout/PageLayout.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import { isPlatform, IonIcon } from '@ionic/vue'

class Props {}

@Component({
  components: { PageLayout, PageHeader, IonIcon },
  props: []
})
export default class ServicesShow extends Vue.with(Props) {
  isIos() {
    return isPlatform('ios')
  }
}
