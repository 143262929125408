<template>
  <DesktopPageLayout>
    <div class="f-18">{{ $t('service.title') }}</div>
    <IonRow class="ion-justify-content-evenly">
      <IonCol
        size="4"
        v-for="(service, i) in serviceList"
        :key="i + service.name"
        class="pr-2"
      >
        <IonCard
          @click="serviceClicked(service.name, service.iframeLink)"
          class="fill-height outlined cursor-pointer service-card mx-0"
        >
          <IonCardHeader>
            <!--<img :src="service.src" alt="service" />-->
            <div class="card-img" :style="`background-image: url(${service.src})`"></div>
            <!--        <IonCardSubtitle>{{ service.name }}</IonCardSubtitle>-->
            <IonCardTitle>{{ service.title }}</IonCardTitle>
          </IonCardHeader>

          <div class="read-more--3ln px-4 mb-4">
            {{ service.content }}
          </div>
          <IonText class="pa-0  read-more-absolute" color="primary">
            <b>
              {{ $t('button.view_details') }}
            </b>
          </IonText>
        </IonCard>
      </IonCol>
    </IonRow>
  </DesktopPageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonText
} from '@ionic/vue'
import DesktopPageLayout from '@/components/Layout/DesktopPageLayout.vue'
import { ServiceDataInterface } from '@/interfaces/service'

class Props {
  serviceList!: ServiceDataInterface[]
}

@Component({
  components: {
    DesktopPageLayout,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonText
  },
  props: ['serviceList']
})
export default class ServiceHomeDesktop extends Vue.with(Props) {
  serviceClicked(name: string, iframeLink: string) {
    this.$router.push({ path: '/services/' + name, query: { iframeLink } })
  }
}
</script>

<style scoped lang="scss">
.service-card {
  border-radius: 15px;
}
.service-card:hover {
  //border-radius: 30px;
  -webkit-box-shadow: 4px 5px 15px -3px #000000;
  box-shadow: 4px 5px 15px -3px #000000;
}
.card-img {
  border-radius: 5px;
  height: 150px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.read-more-absolute {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
