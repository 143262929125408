
import { Options as Component, Vue } from 'vue-class-component'
import { PageLayout } from '@/components/Layout'
import dayjs from '@/utils/dayjs'

import {
  IonButton,
  IonCard,
  IonCol,
  IonDatetime,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption
} from '@ionic/vue'

class Props {
  projects!: any[]
  isOpen!: boolean
  defaultOptions?: any
  defaultFilter?: any
  stateType!: string | 'unpaid' | 'unpaidGroup' | 'history'
  $refs!: any
}

@Component({
  components: {
    IonModal,
    IonCard,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonButton,
    PageLayout,
    IonDatetime,
    IonRow,
    IonCol
  },
  props: {
    projects: {
      type: Array
    },
    isOpen: {
      type: Boolean
    },
    defaultOptions: {
      type: Object,
      default: {}
    },
    defaultFilter: {
      type: Object,
      default: {}
    },
    stateType: {
      type: String
    }
  },
  emits: ['close', 'submit']
})
export default class BillFilter extends Vue.with(Props) {
  options = {
    projects: [{ id: 'all', name: 'All' }],
    state: ['all'],
    dueDate: ['last_30_days', 'last_90_days', 'last_180_days', 'all', 'custom'],
    maxCustomDueDate: dayjs().format('YYYY-MM')
  }

  filter = {
    projectId: null,
    state: 'all',
    dueAt: 'all',
    customDueDate: ''
  }

  billStates = {
    unpaid: ['overdue', 'pending_not_due', 'all'],
    unpaidGroup: ['overdue', 'pending_not_due', 'all'],
    history: ['paid', 'voided', 'processing', 'all']
  } as { [key: string]: any }

  mounted() {
    this.options.state = this.billStates[this.stateType]
    this.overwriteDefaultFilter()
  }

  handleSubmit() {
    this.$emit('submit')
  }

  overwriteDefaultFilter() {
    this.options = {
      ...this.options,
      ...this.defaultOptions
    }

    this.filter = {
      ...this.filter,
      ...this.defaultFilter
    }
  }

  formatFilter() {
    const filter = { ...this.filter } as { [key: string]: any }

    if (filter.projectId === 'all') {
      delete filter.projectId
    }

    if (filter.state === 'all') {
      filter.state = this.options.state.filter(state => state !== 'all')
    }

    if (filter.dueAt === 'custom') {
      filter.dueAt = this.filter.customDueDate
      delete filter.customDueDate
    } else if (filter.dueAt === 'all') {
      delete filter.dueAt
    }

    for (const key in filter) {
      if (!filter[key]) {
        delete filter[key]
      }
    }

    return filter
  }

  selectDueDate(value: string) {
    if (value === 'custom') {
      this.openCustomDueDatePicker()
    } else {
      this.filter.dueAt = value
      this.filter.customDueDate = ''
    }
  }

  openCustomDueDatePicker() {
    this.$refs.dueDatePicker.$el.click()
  }
}
