<template>
  <form @submit="handleSubmit">
    <IonRow class="full-width pb-2">
      <IonCol size="12">
        <BaseField
          v-model="form.number"
          :label="$t('bill.card_form.number')"
          placeholder="4000 XXXX XXXX XXXX"
          autocomplete="off"
          type="number"
          inputmode="tel"
          pattern="[0-9]*"
          maxlength="16"
          required
          autofocus
        />
      </IonCol>
    </IonRow>

    <IonRow class="pb-2 full-width">
      <IonCol size="6" class="pr-1">
        <BaseField
          v-model="form.expirationMonth"
          :label="$t('bill.card_form.exp_month')"
          placeholder="MM"
          autocomplete="off"
          type="number"
          inputmode="tel"
          pattern="[0-9]*"
          maxlength="2"
          required
        />
      </IonCol>

      <IonCol size="6" class="pl-1">
        <BaseField
          v-model="form.expirationYear"
          :label="$t('bill.card_form.exp_year')"
          name="expirationYear"
          placeholder="YY"
          autocomplete="off"
          type="number"
          inputmode="tel"
          pattern="[0-9]*"
          maxlength="2"
          required
        />
      </IonCol>
    </IonRow>

    <IonRow class="full-width pb-2">
      <IonCol>
        <BaseField
          v-model="form.securityCode"
          :label="$t('bill.card_form.cvv')"
          placeholder="●●●"
          autocomplete="off"
          type="tel"
          inputmode="tel"
          pattern="[0-9]*"
          maxlength="3"
          required
        />
      </IonCol>
    </IonRow>

    <IonButton type="submit" expand="block">
      {{ $t('bill.label.pay') }} {{ displayAmount }}
    </IonButton>
  </form>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonButton,
  IonCol,
  IonRow,
  IonItem,
  IonLabel,
  IonInput,
  IonGrid
} from '@ionic/vue'

import BaseField from '@/components/fields/BaseField.vue'

class Props {
  displayAmount!: number
}

@Component({
  components: {
    IonButton,
    IonCol,
    IonRow,
    IonItem,
    IonLabel,
    IonInput,
    BaseField,
    IonGrid
  },
  props: ['displayAmount'],
  emits: ['submit']
})
export default class GbPrimeCardForm extends Vue.with(Props) {
  form = {
    number: '',
    expirationMonth: '',
    expirationYear: '',
    securityCode: ''
  }

  handleSubmit(event: any) {
    event.preventDefault()
    this.$emit('submit', this.form)
  }
}
</script>

<style scoped lang="scss"></style>
