import axios from 'axios'
import store from '@/store'
import { isPlatform } from '@ionic/vue'

import { FirebaseX } from '@ionic-native/firebase-x'

const axiosNoLoader = axios.create()
axiosNoLoader.defaults.timeout = 600000
axiosNoLoader.defaults.baseURL = process.env.VUE_APP_BASE_API

// on req
axiosNoLoader.interceptors.request.use(
  async config => {
    config.headers = {
      Authorization: 'Bearer ' + store.state.firebase.token,
      companyId: store.state.companyId
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// on res
axiosNoLoader.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    if (error?.response?.data?.code === 'system_under_maintenance') {
      const { nameTh, nameEn } = error.response.data
      store.state.dialog.titleEn = nameEn
      store.state.dialog.titleTh = nameTh
      store.state.dialog.isActive = true
    } else {
      await FirebaseX.setCrashlyticsCollectionEnabled(true)
      try {
        if (!isPlatform('mobileweb') && isPlatform('android')) {
          console.log('init android')
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          await FirebaseX.logError(`Network Error : ${error?.response?.status}`, error)
          await FirebaseX.sendCrash()
        } else if (!isPlatform('mobileweb') && isPlatform('ios')) {
          console.log('init ios')
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          await FirebaseX.logError(`Network Error : ${error?.response?.status}`, error)
          await FirebaseX.sendCrash()
        }
      } catch (e) {
        console.log('Crashlytics error', e)
      }
    }

    // store.state.loading.pop()
    // if (!store.state.loading.length) {
    //   store.state.loadingActive = false
    // }
    console.log('axios', error)
    return Promise.reject(error)
  }
)

export default axiosNoLoader
