
import { Options as Component, Vue } from 'vue-class-component'

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonBackButton,
  IonRow,
  IonCol,
  IonIcon
} from '@ionic/vue'

class Props {
  title!: string
  backTo?: string
  withBackButton?: boolean | false
}

@Component({
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonBackButton,
    IonRow,
    IonCol,
    IonIcon
  },
  props: {
    title: {
      type: String,
      default: 'aa'
    },
    withBackButton: {
      type: Boolean,
      default: true
    },
    backTo: {
      type: String
    }
  }
})
export default class PageHeader extends Vue.with(Props) {
  onBack() {
    if (this.backTo) {
      return this.$router.push(this.backTo)
    }
    this.$router.go(-1)
  }
}
