
import { Options as Component, Vue } from 'vue-class-component'
import { IonCardContent } from '@ionic/vue'

import { Attribute } from '@/components/Attribute'

class Props {
  payment!: any
}

@Component({
  components: { IonCardContent, Attribute },
  props: ['payment']
})
export default class PaymentDetails extends Vue.with(Props) {
  get parsedPaymentMethod() {
    const type = this.payment?.type

    switch (true) {
      case type?.includes?.('card'):
        return "t('methods.card')"
      case type?.includes?.('internet_banking'):
        return "t('methods.internet_banking')"
      case type?.includes?.('qr_code'):
        return "t('methods.qr_code')"
      default:
        return '-'
    }
  }
}
