
import { Options as Component, Vue } from 'vue-class-component'

class Props {
  invoice!: any
  disabled?: boolean
}

@Component({
  components: {},
  props: ['invoice', 'disabled']
})
export default class BillState extends Vue.with(Props) {
  get state() {
    return this.computeBillState(this.invoice)
  }

  computeBillState(invoice: any) {
    if (invoice?.isOverdued) {
      return 'overdue'
    }
    return invoice?.state
  }
}
