import store from '@/store'
const ossClient = {
  install(app: any, options: any) {
    app.config.globalProperties.$oss = {
      getSignedUrl: async (src: string) => {
        return await store.state.ossClient.signatureUrl(src)
      }
    }
  }
}
export default ossClient
