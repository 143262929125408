<template>
  <PageLayout>
    <PageHeader :title="$t('service.title')" :withBackButton="true">
      <template v-slot:action>
        <IonButton mode="md" fill="clear" :style="{ float: 'right' }">
          <IonIcon color="primary" icon="duplicate" />
        </IonButton>
      </template>
    </PageHeader>

    <IonCard
      @click="serviceClicked(service.name, service.iframeLink)"
      v-for="(service, i) in serviceList"
      :key="i + service.name"
    >
      <IonCardHeader>
        <img :src="service.src" alt="seekter" />
        <!--        <IonCardSubtitle>{{ service.name }}</IonCardSubtitle>-->
        <IonCardTitle>{{ service.title }}</IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        <p>
          {{ service.content }}
        </p>
      </IonCardContent>
    </IonCard>
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonIcon
} from '@ionic/vue'
import PageLayout from '@/components/Layout/PageLayout.vue'
import { PageHeader } from '@/components/Layout'
import { ServiceDataInterface } from '@/interfaces/service'

class Props {
  serviceList!: ServiceDataInterface[]
}

@Component({
  components: {
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonIcon,
    PageLayout,
    PageHeader
  },
  props: ['serviceList']
})
export default class ServiceHomeMobile extends Vue.with(Props) {
  serviceClicked(name: string, iframeLink: string) {
    this.$router.push({ path: '/services/' + name, query: { iframeLink } })
  }
}
</script>

<style scoped lang="scss"></style>
