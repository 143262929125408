
import { Options as Component, Vue } from 'vue-class-component'
import TermsModal from '@/components/modals/TermsModal.vue'
import { getLogInDevice } from '@/utils/deviceUuid'

@Component({
  components: {
    TermsModal
  },
  props: []
})
export default class TermsAndConditionsModal extends Vue {
  termAndCondition = null as any
  contentEn = ''
  contentTh = ''

  get modal() {
    return this.$store.state.modal.terms
  }

  mounted() {
    this.fetchLastestTerms()
  }

  async fetchLastestTerms() {
    await this.$http.get('/policy/latest').then(res => {
      const { termAndCondition } = res.data
      this.termAndCondition = termAndCondition
      this.modal.contentEn = termAndCondition.contentEn
      this.modal.contentTh = termAndCondition.contentTh
    })
  }

  acceptTerms() {
    const { uuid, source } = getLogInDevice()

    const params = {
      termAndConditionId: this.termAndCondition?._id,
      deviceId: uuid,
      deviceSource: source
    }

    this.$http.post('/policy/accept', params).catch(console.log)
  }
}
