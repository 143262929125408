
import { Options as Component, Vue } from 'vue-class-component'
import PageLayout from '@/components/Layout/PageLayout.vue'

class Props {}

@Component({
  components: { PageLayout },
  props: []
})
export default class Visitors extends Vue.with(Props) {}
