
import { Options as Component, Vue } from 'vue-class-component'
import { loadingController } from '@ionic/vue'

class Props {
  invoice!: any
}

@Component({
  components: {},
  props: ['invoice']
})
export default class PaymentModal extends Vue.with(Props) {
  brand = 'payment_brand'
  method = 'payment_method'

  get displayAmount() {
    return this.charge?.amount?.fullDisplay
  }

  get charge() {
    const projectPaymentMethods = this.invoice?.projectPaymentMethods || []

    const projectPaymentMethod = projectPaymentMethods.find(
      (projectPaymentMethod: any) =>
        projectPaymentMethod.paymentMethod?.brand === this.brand &&
        projectPaymentMethod.paymentMethod?.method === this.method
    )

    return projectPaymentMethod?.charge
  }

  get paymentMethod() {
    const paymentMethods =
      this.invoice?.projectPaymentMethods?.map((p: any) => p.paymentMethod) || []

    return paymentMethods.find(
      (paymentMethod: any) =>
        paymentMethod?.brand === this.brand && paymentMethod?.method === this.method
    )
  }

  async createLoading() {
    const loading = await loadingController.create({
      message: this.$t('bill.label.please_wait')
    })
    await loading.present()
    return loading
  }
}
