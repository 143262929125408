<template>
  <IonRow class="tab-bar ion-justify-content-between">
    <IonCol size="4" class="tab" :class="{ active: currentTab === 'bills' }">
      <IonButton @click="$router.push('/bills')">{{ $t('bill.tab.invoice') }}</IonButton>
    </IonCol>
    <IonCol size="4" class="tab" :class="{ active: currentTab === 'bill_groups' }">
      <IonButton @click="$router.push('/bill_groups')">{{
        $t('bill.tab.group')
      }}</IonButton>
    </IonCol>
    <IonCol size="4" class="tab" :class="{ active: currentTab === 'bills_history' }">
      <IonButton @click="$router.push('/bills_history')">{{
        $t('bill.tab.history')
      }}</IonButton>
    </IonCol>
    <IonCol size="12" class="tab-bar-underline" />
  </IonRow>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonButton, IonRow, IonCol } from '@ionic/vue'

class Props {
  currentTab!: string
}

@Component({
  components: {
    IonButton,
    IonRow,
    IonCol
  },
  props: ['currentTab']
})
export default class BillTabBar extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
.tab-bar {
  padding: 5px 10px 4px 0px;

  .tab {
    height: 50px;
    border: 1px solid rgba(255, 255, 255, 0);
    font-size: 16px;

    ion-button {
      --border-radius: 10px 10px 0px 0px;
      --background: var(--color-primary-dark);
      width: 98%;
      height: 35px;
      margin-left: 5px;
      margin-right: 5px;
      position: absolute;
      bottom: 0;
    }
  }

  .tab.active {
    ion-button {
      --background: var(--color-primary);
      height: 40px !important;
    }
  }

  .tab-bar-underline {
    height: 2px;
    width: 100%;
    box-shadow: 3px 2px 2px #00000029;
    margin-top: -4px;
    margin-left: 3px;
  }
}
</style>
