
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonImg, IonButton, IonIcon } from '@ionic/vue'
import HomeMenu from '@/components/HomeMenu.vue'
import DesktopNavBar from '@/components/desktop/DesktopNavBar.vue'
import ProfileBanner from '@/components/ProfileBanner.vue'
import QuickActionBlock from '@/components/QuickActionBlock.vue'
import DesktopAnnouncement from '@/components/desktop/Announcement/DesktopAnnouncement.vue'
import DesktopPageLayout from '@/components/Layout/DesktopPageLayout.vue'

class Props {
  announcements!: []
  checkDisableHomeMenu!: Function
  invoiceGroups!: []
  invoices!: []
  menuData!: {
    menuList: []
  }
}

@Component({
  components: {
    DesktopPageLayout,
    DesktopAnnouncement,
    QuickActionBlock,
    ProfileBanner,
    DesktopNavBar,
    HomeMenu,
    IonRow,
    IonCol,
    IonImg,
    IonButton,
    IonIcon
  },
  props: [
    'announcements',
    'checkDisableHomeMenu',
    'invoiceGroups',
    'invoices',
    'menuData'
  ]
})
export default class HomePageDesktop extends Vue.with(Props) {
  mounted() {}
}
