
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonButton, IonModal, IonContent, IonCard } from '@ionic/vue'

class Props {
  id!: string
  title!: string
  contentEn!: string
  contentTh!: string
  forceRead?: boolean
}

@Component({
  components: {
    IonRow,
    IonCol,
    IonButton,
    IonModal,
    IonContent,
    IonCard
  },
  props: ['id', 'title', 'contentEn', 'contentTh', 'forceRead'],
  emits: ['close', 'accept']
})
export default class TermsModal extends Vue.with(Props) {
  contentRead = false
  content = ''

  mounted() {
    if (this.$i18n.locale === 'en') {
      this.content = this.contentEn
    } else {
      this.content = this.contentTh
    }
  }

  handleScroll(event: any) {
    const currentPosition = event.target.scrollTop + event.target.clientHeight
    const contentHeight = event.target.scrollHeight

    const isAtBottom = currentPosition >= contentHeight
    if (isAtBottom && !this.contentRead && this.forceRead) {
      this.contentRead = true
    }
  }

  handleClose() {
    if (this.forceRead) {
      this.$emit('accept')
    }
    this.$emit('close')
  }
}
