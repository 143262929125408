
import { Options as Component, Vue, mixins } from 'vue-class-component'
import { Base64ToGallery } from '@ionic-native/base64-to-gallery'
import PageLayout from '@/components/Layout/PageLayout.vue'
import { Diagnostic } from '@ionic-native/diagnostic'
import {
  GB_PRIME_CUSTOMER_KEY,
  GB_PRIME_PUBLIC_KEY,
  GB_PRIME_URL,
  BASE_API
} from '@/constants'

import {
  IonButton,
  IonIcon,
  IonModal,
  IonRow,
  IonCol,
  isPlatform,
  IonContent
} from '@ionic/vue'
import PaymentModal from './PaymentModal.vue'
import { flashResponseError } from '@/utils/flashMessage'

class Props {
  invoice!: any
}

@Component({
  components: { IonButton, IonIcon, IonModal, IonRow, IonCol, IonContent, PageLayout },
  props: ['invoice'],
  emits: ['close', 'createTransaction']
})
export default class GbPrimeQrCodeModal extends mixins(Vue.with(Props), PaymentModal) {
  brand = 'gb_prime'
  method = 'qr_code'
  imageUrl: any = null
  paymentReference?: string
  isOnNativeEnvironment = false
  mounted() {
    this.handleGenerateGbPrimeQrCode()
    if (isPlatform('cordova')) {
      this.isOnNativeEnvironment = true
    }
  }

  handleCloseModal() {
    this.imageUrl && window.URL.revokeObjectURL(this.imageUrl)
    this.$emit('close')
  }

  async handleSaveToGallery() {
    fetch(this.imageUrl)
      .then(res => res.blob())
      .then(blob => {
        const reader: any = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = async () => {
          const base64data = reader.result.toString()

          if (isPlatform('android')) {
            const isHasStoragePermission = await Diagnostic.requestRuntimePermission(
              'WRITE_EXTERNAL_STORAGE'
            )
            if (isHasStoragePermission != 'GRANTED') {
              await Diagnostic.requestExternalStorageAuthorization()
            }
          }
          Base64ToGallery.base64ToGallery(base64data)
            .then(() => alert(`Image saved`))
            .catch(alert)
        }
      })
  }

  async handleGenerateGbPrimeQrCode() {
    const loading = await this.createLoading()
    try {
      this.paymentReference = new Date().getTime().toString()
      const payment = await this.createPayment(
        this.invoice,
        this.charge,
        this.paymentReference,
        this.paymentMethod
      )
      const qrCodeImageBlob = await this.generateQrCode(
        this.charge,
        this.paymentReference,
        payment
      )

      this.imageUrl = qrCodeImageBlob
      this.$emit('createTransaction', this.paymentReference)
      this.$emit('reload')
      loading.dismiss()
    } catch (error) {
      loading.dismiss()
      this.$emit('reload')
      flashResponseError(error)
    }
  }

  async createPayment(
    invoice: any,
    charge: any,
    paymentReference: string,
    paymentMethod: any
  ) {
    const data: any = {
      amount: charge?.amount?.decimal,
      serviceFee: charge?.serviceFee?.decimal,
      paymentGatewayFee: charge?.paymentGatewayFee?.decimal,
      referenceNo: paymentReference,
      brand: paymentMethod?.brand,
      method: paymentMethod?.method,
      invoiceId: invoice._id
    }

    if (invoice.entity === 'invoiceGroup') {
      delete data.invoiceId
      data.invoiceGroupId = invoice._id
    }

    const response = await this.$http.post(`/invoice/transaction`, data)

    const payment = response.data
    return payment
  }

  async generateQrCode(charge: any, paymentReference: string, payment: any) {
    const data = {
      token: GB_PRIME_CUSTOMER_KEY,
      amount: charge?.amount?.decimal,
      referenceNo: paymentReference,
      backgroundUrl: `${BASE_API}/invoice/transaction/${payment?._id}/receive?brand=${this.brand}&method=${this.method}`
    }

    const formBody = Object.entries(data)
      .map(([k, v]) => encodeURIComponent(k) + '=' + encodeURIComponent(v))
      .join('&')

    const qrCodeResponse = await fetch(`${GB_PRIME_URL}/gbp/gateway/qrcode`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${btoa(GB_PRIME_PUBLIC_KEY + ':')}`,
        Accept: 'image/png'
      },
      body: formBody
    })

    const imageBlob = await qrCodeResponse.blob()
    const qrCodeImageBlob = window.URL.createObjectURL(imageBlob)

    return qrCodeImageBlob
  }
}
