<template>
  <div>
    <IonRow v-if="src.length > 0" class="">
      <IonCol :size="src.length === 1 ? 12 : 8" :class="src.length > 1 ? 'pr-2' : null">
        <div
          :style="'background-image: url(' + GET_SECURED_IMAGE(src[0]) + ');'"
          class="card-img"
        />
      </IonCol>
      <IonCol
        v-if="src.length > 1"
        size="4"
        :style="'background-image: url(' + GET_SECURED_IMAGE(src[1]) + ');'"
        class="card-img"
      >
        <div v-if="src.length > 1">
          <div :class="src.length > 2 ? 'dark-overlay' : null">
            <IonRow
              class="ion-justify-content-center ion-align-items-center fill-height"
              v-if="src.length > 2"
            >
              <IonCol size="auto">
                <h1 class="dark-text">+{{ src.length - 2 }}</h1>
              </IonCol>
            </IonRow>
          </div>
        </div>
      </IonCol>
    </IonRow>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonImg } from '@ionic/vue'

class Props {
  src!: string
}

@Component({
  components: { IonRow, IonCol, IonImg },
  props: {
    src: {
      default: ['https://th-test-11.slatic.net/p/5568633b84a71986d67de8c5f290ddae.jpg'],
      type: Array
    }
  }
})
export default class CardImages extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
.card-img {
  border-radius: 10px;
  width: 100%;
  height: 100px;
  background-position: center center;
  background-repeat: no-repeat;
}

.dark-overlay {
  @extend .card-img;
  z-index: 1;
  background-color: #1e2023;
  opacity: 0.5;
}
.dark-text {
  opacity: 1;
  color: white;
}
</style>
