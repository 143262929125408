
import { Options as Component, Vue, mixins } from 'vue-class-component'
import { IonModal, IonIcon, IonCol, IonContent } from '@ionic/vue'

import { GB_PRIME_URL, GB_PRIME_PUBLIC_KEY } from '@/constants'
import GbPrimeCardForm from '@/forms/GbPrimeCardForm.vue'
import PageLayout from '@/components/Layout/PageLayout.vue'
import PaymentModal from './PaymentModal.vue'
import { flashResponseError } from '@/utils/flashMessage'

class Props {
  invoice!: any
}

@Component({
  components: { IonModal, IonIcon, GbPrimeCardForm, PageLayout, IonCol, IonContent },
  props: ['invoice'],
  emits: ['close', 'createTransaction']
})
export default class GbPrimeCardModal extends mixins(Vue.with(Props), PaymentModal) {
  brand = 'gb_prime'
  method = 'card'
  htmlString = ''

  handleCloseModal() {
    this.htmlString = ''
    this.$emit('close')
  }

  async handlePayment(cardInput: any) {
    const loading = await this.createLoading()
    try {
      const cardToken = await this.getCardToken(cardInput)
      const payment = await this.createPayment(
        this.invoice,
        cardToken,
        this.charge,
        this.paymentMethod
      )
      const cardPaymentHtml = await this.do3dSecure(payment?.referenceNo)

      this.htmlString = cardPaymentHtml
      this.$emit('createTransaction', payment.referenceNo)
      this.$emit('reload')
      loading.dismiss()
    } catch (error) {
      this.$emit('reload')
      loading.dismiss()
      flashResponseError(error)
    }

    this.$loading?.hide()
  }

  async getCardToken(cardInput: any) {
    const data = { rememberCard: false, card: cardInput }
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Basic ${btoa(GB_PRIME_PUBLIC_KEY + ':')}`
    }

    const response: any = await fetch(`${GB_PRIME_URL}/v1/tokens`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })

    const { resultCode, card } = await response.json()

    switch (resultCode) {
      case '00':
        return card?.token
      case '02':
        throw new Error('Invalid data')
      case '54':
        throw new Error('Expired card')
      default:
        throw new Error(`Error requesting token (code: ${resultCode})`)
    }
  }

  async createPayment(invoice: any, cardToken: string, charge: any, paymentMethod: any) {
    const data: any = {
      cardToken,
      amount: charge?.amount?.decimal,
      serviceFee: charge?.serviceFee?.decimal,
      paymentGatewayFee: charge?.paymentGatewayFee?.decimal,
      brand: paymentMethod?.brand,
      method: paymentMethod?.method,
      invoiceId: invoice._id
    }

    if (invoice.entity === 'invoiceGroup') {
      delete data.invoiceId
      data.invoiceGroupId = invoice._id
    }

    const response: any = await this.$http.post('/invoice/transaction', data)

    const payment = response?.data
    if (!payment?.referenceNo) {
      throw new Error(payment?.failureCode || this.$t('bill.error.create_payment_failed'))
    }

    return payment
  }

  async do3dSecure(paymentReference: any) {
    const data = {
      publicKey: GB_PRIME_PUBLIC_KEY,
      gbpReferenceNo: paymentReference
    }

    const encodedData = Object.entries(data)
      .map(([k, v]) => encodeURIComponent(k) + '=' + encodeURIComponent(v))
      .join('&')

    const header = { 'Content-Type': 'application/x-www-form-urlencoded' }

    const response = await fetch(`${GB_PRIME_URL}/v1/tokens/3d_secured`, {
      method: 'POST',
      headers: header,
      body: encodedData
    })

    const cardPaymentHtml = await response.text()

    return cardPaymentHtml
  }
}
