
import { Options as Component, Vue } from 'vue-class-component'
import { IonButton } from '@ionic/vue'
import ErrorModal from '@/components/modals/ErrorModal.vue'

@Component({
  components: { IonButton, ErrorModal },
  props: ['errorCode'],
  emits: ['close']
})
export default class LogInErrorModal extends Vue {}
