<template>
  <FloatModal mode="blank" v-if="updateNeeded">
    <template v-slot:content>
      <div class="pa-3">
        <IonRow class="ion-justify-content-center">
          <IonCol size="auto">
            <img src="/assets/sky_logo.svg" width="160" alt="Company Logo" />
          </IonCol>
        </IonRow>
        <div
          class="ion-text-center"
          v-for="text in $t('version_update.content').split('\n')"
          :key="text"
        >
          {{ text }}
        </div>
        <IonRow class="ion-justify-content-between">
          <IonCol size="12" @click="updateClicked">
            <IonButton expand="block">
              {{ $t('version_update.button.update_now') }}
            </IonButton>
          </IonCol>
        </IonRow>
      </div>
    </template>
  </FloatModal>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonButton } from '@ionic/vue'
import FloatModal from '@/components/modals/FloatModal.vue'
import { Market } from '@ionic-native/market'
import { AppVersion } from '@ionic-native/app-version'
import { isPlatform } from '@ionic/vue'
import { parseInt } from 'lodash'
class Props {}

@Component({
  components: { FloatModal, IonRow, IonCol, IonButton },
  props: {}
})
export default class ForceUpdateModal extends Vue.with(Props) {
  updateNeeded = false
  async mounted() {
    if (isPlatform('cordova'))
      await this.$http.get('/app/version').then(async res => {
        const currentVersionCode = (await AppVersion.getVersionCode()).toString()
        console.log('currentVersionCode', currentVersionCode)
        if (
          (isPlatform('ios') &&
            parseInt(res.data.iosCode) >= parseInt(currentVersionCode)) ||
          (isPlatform('android') &&
            parseInt(res.data.androidCode) >= parseInt(currentVersionCode))
        ) {
          this.updateNeeded = true
        }
      })
  }
  async updateClicked() {
    const packageName = isPlatform('ios') ? '1576467469' : 'sky.commercial.app'
    await Market.open(packageName)
  }
}
</script>

<style scoped lang="scss"></style>
