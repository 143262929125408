
import { Options as Component, Vue } from 'vue-class-component'
import { IonList, IonItem } from '@ionic/vue'

class Props {
  handleSort!: Function
  optionValue!: string
  options!: string[]
}

@Component({
  components: { IonList, IonItem },
  props: ['handleSort', 'optionValue', 'options']
})
export default class SortByPopover extends Vue.with(Props) {}
