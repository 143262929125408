<template>
  <IonModal
    :isOpen="isOpen"
    backdropDismiss="false"
    style=""
    cssClass="news-filter-modal"
  >
    <IonContent id="news-modal">
      <IonRow id="news-modal-content" class="pa-4 news-content">
        <IonCol size="12" class="header under-line f-18 mb-3">
          <b>
            {{ title }}
          </b>
        </IonCol>
        <slot name="content" />
        <IonCol size="12" v-for="(filterList, i) in filterListArr" :key="i">
          <p class="ion-no-margin">
            {{ transKey ? $t(`${transKey}.${filterList.name}.title`) : filterList.name }}
          </p>
          <IonRow>
            <IonButton
              color="primary"
              size="small"
              class="f-16"
              :fill="filterData[filterList.key] === filter.key ? 'solid' : 'outline'"
              :key="filter.key"
              v-for="filter in filterList.filters"
              @click="filterClicked(filterList, filter)"
            >
              {{
                transKey
                  ? $t(`${transKey}.${filterList.name}.${filter.name}`)
                  : filterList.name
              }}
            </IonButton>
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow class="ion-justify-content-center pb-4">
        <IonCol size="auto">
          <IonButton
            color="secondary"
            style="width: 125px; height: 30px; font-size: 16px"
            @click="cancelBtnClicked()"
          >
            {{ $t('button.cancel') }}
          </IonButton>
          <IonButton
            color="primary"
            style="width: 125px; height: 30px; font-size: 16px"
            @click="submitBtnClicked()"
          >
            {{ $t('button.submit') }}
          </IonButton>
        </IonCol>
      </IonRow>
    </IonContent>
  </IonModal>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonModal, IonContent, IonButton } from '@ionic/vue'

class Props {
  isOpen!: boolean
  title!: string
  filterListArr!: any[]
  filterContent!: any
  transKey?: string
  onCancel!: Function
  onSubmit!: Function
}

@Component({
  components: { IonRow, IonCol, IonModal, IonContent, IonButton },
  props: {
    isOpen: {
      type: Boolean
    },
    title: {
      type: String,
      default: ''
    },
    filterListArr: {
      type: Array,
      default: []
    },
    filterContent: {
      type: Array,
      default: {}
    },
    transKey: {
      type: String
    },
    onCancel: {
      type: Function,
      default: () => {}
    },
    onSubmit: {
      type: Function,
      default: () => {}
    }
  }
})
export default class Filter extends Vue.with(Props) {
  filterData = {} as any

  mounted() {
    this.filterData = this.filterContent
  }

  filterClicked(list: any, filter: any) {
    console.log(list, filter)
    this.filterData[list.key] = filter.key
  }
  submitBtnClicked() {
    this.onSubmit(this.filterData)
  }

  cancelBtnClicked() {
    this.onCancel()
  }
}
</script>

<style lang="scss">
.news-filter-modal {
  padding-top: calc(100vh - 300px);
}
</style>
