
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonCardTitle,
  IonCardSubtitle
} from '@ionic/vue'

class Props {
  desktop?: boolean | false
}

@Component({
  components: { IonCard, IonGrid, IonRow, IonCol, IonCardTitle, IonCardSubtitle },
  props: ['desktop']
})
export default class ProfileBanner extends Vue.with(Props) {
  goToProfile() {
    this.$router.push('/user')
  }
}
