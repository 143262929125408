<template>
  <IonModal
    :is-open="$store.state.dialog.isActive"
    css-class="modal-windowed-maintenance"
    @didDismiss="$store.state.dialog.isActive = false"
  >
    <IonRow class="ion-align-items-center fill-height">
      <IonCol size="12">
        <IonRow class="ion-justify-content-center">
          <IonCol size="4">
            <IonImg src="/assets/maintenance.png" />
          </IonCol>
        </IonRow>
        <IonRow class="ion-justify-content-center">
          <IonCol size="auto">
            <IonText class="modal-title"> {{ $store.state.dialog.titleTh }}</IonText
            ><br />
            <IonText class="modal-title">
              {{ $store.state.dialog.titleEn }}
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow class="ion-justify-content-center pt-2">
          <IonCol size="auto">
            <IonButton
              class="btn btn-cancel mx-2"
              color="medium"
              @click="$store.state.dialog.isActive = false"
            >
              {{ $t('button.close') }}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  </IonModal>
</template>
<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonText,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonCardHeader,
  IonContent,
  IonImg,
  IonModal
} from '@ionic/vue'
class Props {}
@Component({
  components: {
    IonModal,
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonText,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonCardHeader,
    IonContent,
    IonImg
  },
  props: []
})
export default class DialogMaintenance extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
.modal-title {
  font: normal normal 300 14px/20px Prompt;
  letter-spacing: 0px;
  color: #333333;
}

.btn {
  top: 276px;
  left: 189px;
  width: 95px;
  height: 29px;
  text-align: center;
  font: normal normal normal 12px/18px Prompt;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 5px;
}

.btn-cancel {
  font-size: 14px;
  background-color: #afafaf;
}
</style>
