
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonButton } from '@ionic/vue'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import Card from '@/components/Card/Card.vue'
import DesktopAnnouncementCard from '@/components/desktop/Announcement/DesktopAnnouncementCard.vue'
class Props {
  announcements!: any[]
}

@Component({
  components: {
    DesktopAnnouncementCard,
    Card,
    IonRow,
    IonCol,
    IonButton,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  props: ['announcements']
})
export default class DesktopAnnouncement extends Vue.with(Props) {
  slideIndex = 0
  defaultAnnouncement = {
    highlightOrder: 1,
    isActive: true,
    _id: '618b406de2f423b50ac7823e',
    title: 'ยินดีตอนรับสู่ SkyCommercial',
    content: '',
    imagePaths: [],
    projectId: 2,
    userId: 1,
    companyId: 1,
    createdAt: '2021-11-10T03:45:49.581Z',
    updatedAt: '2021-11-09T03:45:49.582Z',
    company: {}
  }
}
