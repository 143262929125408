
import { Options as Component, Vue } from 'vue-class-component'

class Props {
  label?: string
  strong?: boolean | false
}

@Component({
  components: {},
  props: ['label', 'strong']
})
export default class Attribute extends Vue.with(Props) {}
