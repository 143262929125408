
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonButton } from '@ionic/vue'
import AnnouncementSlide from '@/components/Announcement/AnnouncementSlide.vue'
import HomeMenu from '@/components/HomeMenu.vue'
import ProfileBanner from '@/components/ProfileBanner.vue'
import PushNotification from '@/components/PushNotification/PushNotification.vue'
import QuickActionBlock from '@/components/QuickActionBlock.vue'
import PageLayout from '@/components/Layout/PageLayout.vue'
class Props {
  announcements!: []
  checkDisableHomeMenu!: Function
  invoiceGroups!: []
  invoices!: []
  menuData!: {
    menuList: []
  }
}

@Component({
  components: {
    PageLayout,
    IonRow,
    IonCol,
    IonButton,
    AnnouncementSlide,
    HomeMenu,
    ProfileBanner,
    PushNotification,
    QuickActionBlock
  },
  props: [
    'announcements',
    'checkDisableHomeMenu',
    'invoiceGroups',
    'invoices',
    'menuData'
  ]
})
export default class HomePageMobile extends Vue.with(Props) {}
