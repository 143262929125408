<template>
  <PageLayout>
    <IonRow class="ion-justify-content-between ion-align-items-center mt-2 mb-3">
      <IonCol size="auto">
        <ProfileBanner />
      </IonCol>
      <IonCol size="auto">
        <IonButton size="small" @click="$router.push('/my_projects')">
          {{ $t('home.my_projects') }}
        </IonButton>
      </IonCol>
    </IonRow>
    <AnnouncementSlide :announcements="announcements" />
    <QuickActionBlock
      v-if="
        !checkDisableHomeMenu(
          $store.state.navigationPermissions,
          menuData.menuList[0].rules
        )
      "
      :invoices="[...invoiceGroups, ...invoices].slice(0, 2)"
      class="pb-3"
    />
    <IonRow>
      <!--TODO remove if and make rules-->
      <IonCol v-for="(homeMenu, i) in menuData.menuList" :key="i" size="4">
        <HomeMenu
          :disabled="
            i > 0
              ? false
              : checkDisableHomeMenu($store.state.navigationPermissions, homeMenu.rules)
          "
          :src="homeMenu.src"
          :redDot="homeMenu.redDot"
          :name="$t(`home.menu.${homeMenu.name}`)"
          :routerLink="homeMenu.routerLink"
        />
      </IonCol>
    </IonRow>
    <div class="pt-12 pb-12 mb-12"> . </div>
    <PushNotification />
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonButton } from '@ionic/vue'
import AnnouncementSlide from '@/components/Announcement/AnnouncementSlide.vue'
import HomeMenu from '@/components/HomeMenu.vue'
import ProfileBanner from '@/components/ProfileBanner.vue'
import PushNotification from '@/components/PushNotification/PushNotification.vue'
import QuickActionBlock from '@/components/QuickActionBlock.vue'
import PageLayout from '@/components/Layout/PageLayout.vue'
class Props {
  announcements!: []
  checkDisableHomeMenu!: Function
  invoiceGroups!: []
  invoices!: []
  menuData!: {
    menuList: []
  }
}

@Component({
  components: {
    PageLayout,
    IonRow,
    IonCol,
    IonButton,
    AnnouncementSlide,
    HomeMenu,
    ProfileBanner,
    PushNotification,
    QuickActionBlock
  },
  props: [
    'announcements',
    'checkDisableHomeMenu',
    'invoiceGroups',
    'invoices',
    'menuData'
  ]
})
export default class HomePageMobile extends Vue.with(Props) {}
</script>

<style scoped lang="scss"></style>
