<template>
  <IonTabs>
    <IonTabBar slot="bottom" v-if="!$platform.is.desktop">
      <div class="offset" />
      <IonTabButton tab="home" href="/home">
        <IonIcon class="" src="/assets/icon/home.svg" />
        <IonLabel class="menu-label">{{ $t('home.navbar.home') }}</IonLabel>
      </IonTabButton>
      <IonTabButton tab="settings" href="/settings">
        <IonIcon class="" src="/assets/icon/settings.svg" />
        <IonLabel class="menu-label">{{ $t('home.navbar.settings') }}</IonLabel>
      </IonTabButton>
      <div class="offset" />
    </IonTabBar>
    <FreshChat
      v-if="freshChatData.isEnabled && freshChatData.freshChatToken"
      :token="freshChatData.freshChatToken"
      :freshChatIntegrationId="freshChatData._id"
      :userRestoreId="freshChatData.userRestoreId"
    />
  </IonTabs>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonLabel } from '@ionic/vue'
import { IonPage, IonIcon, IonTabBar, IonTabButton, IonTabs } from '@ionic/vue'
import FreshChat from '@/components/FreshChat.vue'
class Props {
  $refs!: any
}

@Component({
  components: { IonPage, IonIcon, IonTabBar, IonTabButton, IonTabs, IonLabel, FreshChat }
})
export default class BottomNavigationMenu extends Vue.with(Props) {
  get freshChatData() {
    const { _id, isEnabled, freshChatToken, userTokens } =
      this.$store.state.currentProject?.freshChatIntegration || {}
    const userRestoreId = userTokens?.find(
      userToken => userToken?.userId == this.$store.state.user.id
    )?.restoreId
    return { _id, isEnabled, freshChatToken, userTokens, userRestoreId }
  }
}
</script>

<style scoped lang="scss">
//.offset {
//  width: 50%;
//}

ion-tab-button {
  --color-selected: #01b3bf;
  --color: #333333;
}
//.menu-icon {
//  top: 968px;
//  left: 43px;
//  width: 23px;
//  height: 23px;
//}
.menu-label {
  font-size: 14px;
  //top: 990px;
  //left: 138px;
  //width: 70px;
  //height: 21px;
  //text-align: center;
  //font: normal normal normal 14px Prompt;
  //letter-spacing: 0px;
  //opacity: 1;
}
</style>
