import { Options as Component, Vue } from 'vue-class-component'

@Component({})
export default class Pagination extends Vue {
  pagination = {
    currentPage: 1,
    perPage: 20,
    nextPage: null,
    total: null,
    totalPage: null
  }

  setCurrentPage(page: number) {
    this.pagination.currentPage = page
  }

  setPerPage(perPage: number) {
    this.pagination.perPage = perPage
  }

  setPagination(pagination: any) {
    this.pagination = pagination || this.pagination
  }

  withPagination(params = {}) {
    return {
      page: this.pagination.currentPage,
      perPage: this.pagination.perPage,
      ...params
    }
  }
}
