
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonText } from '@ionic/vue'
import PageLayout from '@/components/Layout/PageLayout.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import ImagesDialog from '@/components/image/ImagesDialog.vue'
class Props {
  title!: string | ''
  content!: {
    id: string
    title: string
    content: string
    imagePaths: string[]
    projectId: number
    userId: number
    projectNameTh: string
    projectNameEn: string
    createdAt: string
    updatedAt: string
  }
}

@Component({
  components: { ImagesDialog, PageHeader, PageLayout, IonRow, IonCol, IonText },
  props: ['title', 'content']
})
export default class ContentShow extends Vue.with(Props) {
  contentRender(content: string) {
    if (content) {
      return content.replace(/<[^>]*>/g, '')
    } else {
      return ''
    }
  }
}
