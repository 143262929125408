<template>
  <div :class="['customer-details', { 'well-background': wrapper === 'well' }]">
    <div>
      <span>{{ $t('bill.label.due_date') }} : </span>
      <span>{{ THAI_DATE(invoice.dueAt) }}</span>
    </div>

    <div>
      <span>{{ $t('bill.label.issue_date') }} : </span>
      <span>{{ THAI_DATE(invoice.issuedAt) }}</span>
    </div>

    <div>
      <span>{{ $t('bill.label.billed_to') }} : </span>
      <span>{{ invoice?.invoiceCustomer?.name }}</span>
    </div>

    <div>
      <span>{{ $t('bill.label.project') }} : </span>
      <span>{{
        I18N(invoice?.invoiceProject?.nameTh, invoice?.invoiceProject?.nameEn)
      }}</span>
    </div>

    <div>
      <span>{{ $t('bill.label.unit') }} : </span>
      <span>{{ unitNames }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonCardContent } from '@ionic/vue'

import { Attribute } from '@/components/Attribute'

class Props {
  invoice?: any | {}
  wrapper?: string | 'well'
}

@Component({
  components: { IonCardContent, Attribute },
  props: ['invoice', 'wrapper']
})
export default class CustomerDetails extends Vue.with(Props) {
  get unitNames() {
    const unit = this.invoice?.invoiceUnits
    if (Array.isArray(unit)) {
      return unit.map(unit => unit.unitName).join(' / ')
    }
    return ''
  }
}
</script>

<style scoped lang="scss">
.customer-details {
  border-radius: 10px;
  opacity: 1;

  div {
    font-size: 14px;

    span:first-child {
      color: #9a9a9a;
    }
    span:last-child {
      color: #333333;
    }
  }
}

.well-background {
  padding: 10px 10px 10px 10px;
  background: #f1f1f1 0% 0% no-repeat padding-box;
}
</style>
