<template>
  <FloatModal mode="alert" size="small" v-on:close="$emit('cancel')">
    <template v-slot:header>
      <div class="header">
        <slot name="header" />
      </div>
    </template>
    <template v-slot:footer>
      <IonRow class="button-wrapper">
        <IonCol size="6">
          <IonButton class="secondary-button" @click="$emit('cancel')">
            {{ $t('button.cancel') }}
          </IonButton>
        </IonCol>
        <IonCol size="6">
          <IonButton class="warning-button" @click="$emit('confirm')">
            {{ $t('button.sure') }}
          </IonButton>
        </IonCol>
      </IonRow>
    </template>
  </FloatModal>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonButton, IonRow, IonCol } from '@ionic/vue'
import FloatModal from './FloatModal.vue'

class Props {}

@Component({
  components: { IonButton, FloatModal, IonRow, IonCol },
  props: ['confirm', 'cancel']
})
export default class ConfirmModal extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
.header {
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: center;
  font-size: 16px;
}

.button-wrapper {
  padding-bottom: 10px;

  ion-col:first-child {
    text-align: right;
    padding-right: 10px;
  }
  ion-col:last-child {
    text-align: left;
    padding-left: 10px;
  }
}

ion-button {
  min-width: 95px;
  height: 30px;
}
</style>
