<template>
  <PageLayout is-full-screen="true">
    <PageHeader
      :title="$t('home.navbar.profile')"
      :withBackButton="true"
      backTo="/"
      class="mb-5"
    />
    <IonImg src="/assets/img/background/city_bg_profile_cropped.png" />

    <IonRow class="ion-justify-content-center">
      <IonCol size="1" />
      <IonCol size="10" style="text-align: center">
        <IonRow class="mx-auto profile-container ion-justify-content-center">
          <IonCol size="12">
            <img class="profile-image" alt="logo" :src="getImagePath" />
            <!--            Add support for ios and then remove && !isPlatformIos -->
            <IonIcon
              key="selectImageIcon"
              v-if="isEdit"
              @click="getImage()"
              class="f-20 mx-auto bordered iconContainer"
              src="/assets/icon/camera.svg"
            />
          </IonCol>
          <IonCol size="12">
            <IonCardTitle class="greeting-text f-17">
              {{ $t('home.hello') }},
              <span class="name-highlight f-24">
                {{ user.displayName }}
              </span>
            </IonCardTitle>
          </IonCol>
        </IonRow>
      </IonCol>

      <IonCol size="1" style="text-align: right">
        <IonIcon
          class="f-21 pr-3 pt-3 pb-3"
          src="/assets/icon/profile_edit_icon.svg"
          v-if="!isEdit"
          @click="isEdit = true"
        />
      </IonCol>
    </IonRow>
    <Form @submit="handleSubmit" :validation-schema="formDataValidate" class="px-4">
      <IonRow>
        <IonCol size="12">
          <Field name="firstName" v-slot="{ field }" v-model="user.firstName">
            <IonRow>
              <IonCol size="4">
                <IonLabel class="input-label"
                  >{{ $t('profile.first_name') }}:&nbsp;</IonLabel
                >
              </IonCol>
              <IonCol size="8">
                <span v-if="!isEdit" class="input-value"> {{ user.firstName }}</span>
                <IonInput class="input-value" v-else v-bind="field" />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4"> </IonCol>
              <IonCol size="8">
                <ErrorMessage class="input-error-message" name="firstName" />
                <span>&nbsp;</span>
              </IonCol>
            </IonRow>
          </Field>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol size="12">
          <Field name="lastName" v-slot="{ field }" v-model="user.lastName">
            <IonRow>
              <IonCol size="4">
                <IonLabel class="input-label"
                  >{{ $t('profile.last_name') }}:&nbsp;</IonLabel
                >
              </IonCol>
              <IonCol size="8">
                <span v-if="!isEdit" class="input-value"> {{ user.lastName }}</span>
                <IonInput class="input-value" v-else v-bind="field" />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4"> </IonCol>
              <IonCol size="8">
                <ErrorMessage class="input-error-message" name="lastName" />
                <span>&nbsp;</span>
              </IonCol>
            </IonRow>
          </Field>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12">
          <Field name="phoneNumber" v-slot="{}" v-model="user.phoneNumber">
            <IonRow>
              <IonCol size="4">
                <IonLabel class="input-label"
                  >{{ $t('profile.phone_number') }}:&nbsp;</IonLabel
                >
              </IonCol>
              <IonCol size="8">
                <span class="input-value"> {{ user.phoneNumber }}</span>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4"> </IonCol>
              <IonCol size="8">
                <ErrorMessage class="input-error-message" name="phoneNumber" />
                <span>&nbsp;</span>
              </IonCol>
            </IonRow>
          </Field>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12">
          <Field name="email" v-slot="{ field }" v-model="user.email">
            <IonRow>
              <IonCol size="4">
                <IonLabel class="input-label">{{ $t('profile.email') }}:&nbsp;</IonLabel>
              </IonCol>
              <IonCol size="8">
                <span v-if="!isEdit" class="input-value"> {{ user.email || '-' }}</span>
                <IonInput class="input-value" v-else v-bind="field" />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4"> </IonCol>
              <IonCol size="8">
                <ErrorMessage class="input-error-message" name="email" />
                <span>&nbsp;</span>
              </IonCol>
            </IonRow>
          </Field>
        </IonCol>
      </IonRow>
      <IonRow v-if="isEdit" class="ion-justify-content-center">
        <IonCol size="auto" style="text-align: center">
          <IonButton type="submit" class="btn-gradient mt-10" size="small"
            ><span class="px-5 f-14">Save</span></IonButton
          >
        </IonCol>
      </IonRow>
    </Form>
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { PageHeader, PageLayout } from '@/components/Layout/'
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonList,
  IonRow,
  IonThumbnail,
  isPlatform
} from '@ionic/vue'
import { b64toBlob } from '@/utils/base64'
import BaseField from '@/components/fields/BaseField.vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as yup from 'yup'
import { CameraResultType, CameraSource, Filesystem, Plugins } from '@capacitor/core'

class Props {}

@Component({
  components: {
    BaseField,
    PageLayout,
    PageHeader,
    IonImg,
    IonItem,
    IonThumbnail,
    IonList,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    IonIcon,
    Form,
    Field,
    ErrorMessage
  },
  props: []
})
export default class Profile extends Vue.with(Props) {
  formDataValidate = yup.object({
    firstName: yup
      .string()
      .typeError('This field must be a string.')
      .required('This field is required.'),
    lastName: yup
      .string()
      .typeError('This field must be a string.')
      .required('This field is required.'),
    email: yup
      .string()
      .nullable()
      .email('This email is incorrect')
  })
  isEdit = false
  user = {
    profileImagePreview: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    displayName: ''
  }

  get isPlatformIos() {
    return isPlatform('ios')
  }

  get getImagePath() {
    if (this.user.profileImagePreview === '') return '/assets/default_profile_picture.svg'
    else if (this.user.profileImagePreview != this.$store.state.user.profileImage) {
      return 'data:image/jpeg;base64,' + this.user.profileImagePreview
    } else {
      return this.GET_SECURED_IMAGE(this.user.profileImagePreview)
    }
  }

  async getImage() {
    try {
      const { Camera } = Plugins
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Base64,
        source: CameraSource.Photos
      })
      if (image && image.base64String) {
        this.user.profileImagePreview = image.base64String
      }
    } catch (e) {
      console.log('getImage error', e)
    }
  }

  async handleSubmit() {
    const formData = new FormData()
    if (this.user.profileImagePreview != this.$store.state.user.profileImage) {
      const imageBff = await b64toBlob(this.user.profileImagePreview, 'image/jpeg')
      formData.append('image', imageBff)
    }
    formData.append('firstName', this.user.firstName)
    formData.append('lastName', this.user.lastName)
    formData.append('email', this.user.email)
    this.$http
      .patch('/capi/user', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        this.user.displayName = this.user.firstName
      })
      .finally(() => {
        this.isEdit = false
      })
  }

  mounted() {
    this.user = {
      profileImagePreview: this.$store.state.user.profileImage,
      firstName: this.$store.state.user.firstName,
      lastName: this.$store.state.user.lastName,
      email: this.$store.state.user.email,
      phoneNumber: this.$store.state.user.phoneNumber,
      displayName: this.$store.state.user.firstName
    }
  }
}
</script>

<style scoped lang="scss">
.announcement-bg-wrapper {
  height: 120px;
  width: 100%;
  background-size: cover;
}
.profile-container {
  position: relative;
  top: -35%;
}
.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}
.iconContainer {
  float: none;
  margin-left: -20px !important;
}
ion-icon {
  background-color: white;
  border-radius: 50%;
  color: lightgrey;
}
.bordered {
  border: solid 1px lightgrey;
}
ion-input {
  --padding-start: 10px !important;
  --padding-top: 3px;
  --padding-end: 3px;
  --padding-bottom: 3px;
  border: solid 1px lightgrey;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0);
  @extend .f-14;
}
.btn-custom {
  width: 120px;
  height: 50px;
}
.greeting-text {
  text-align: center;
  letter-spacing: 0.47px;
  color: #333333;
  opacity: 1;
}
.greeting-text:after {
  background-color: #008caa;
  bottom: -5px;
  content: '';
  display: block;
  height: 5px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  width: 50px;
  border-radius: 5px;
}
.name-highlight {
  text-align: center;
  letter-spacing: 0px;
  color: #008caa;
  opacity: 1;
}
.input-label {
  @extend .f-14;
  font-weight: 300;
  background-color: rgba(0, 0, 0, 0);
}
.input-value {
  @extend .f-14;
  font-weight: 100;
  background-color: rgba(0, 0, 0, 0);
}
.input-error-message {
  @extend .f-14;
}
</style>
