
import { Options as Component, Vue, mixins } from 'vue-class-component'
import PageLayout from '@/components/Layout/PageLayout.vue'
import EmptyListPlaceHolder from '@/components/EmptyListPlaceHolder.vue'
import { BillCard, BillTabBar } from '@/modules/bills'
import PageHeader from '@/components/Layout/PageHeader.vue'
import { Pagination, SortBy } from '@/mixins'
import BillFilter from '@/components/filters/BillFilter.vue'
import {
  IonIcon,
  IonButton,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/vue'
import CheckBox from '@/components/fields/CheckBox.vue'
import ClickAndHold from '@/components/ClickAndHold.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import { flashResponseError } from '@/utils/flashMessage'
import axiosNoLoader from '@/http-no-loading'

class Props {
  $refs!: any
}

@Component({
  components: {
    IonIcon,
    IonButton,
    IonRow,
    IonCol,
    IonSelect,
    IonSelectOption,
    PageHeader,
    EmptyListPlaceHolder,
    PageLayout,
    BillCard,
    BillFilter,
    BillTabBar,
    CheckBox,
    ClickAndHold,
    ConfirmModal,
    IonInfiniteScroll,
    IonInfiniteScrollContent
  }
})
export default class BillList extends mixins(Vue.with(Props), Pagination, SortBy) {
  invoices = [] as any[]
  isGrouping = false as boolean
  groupingBillIds = [] as number[]
  filterModalOpen = false
  confirmGroupingModalOpen = false
  noDataMessage = ''
  selectAllForProjectId: any = null
  ionInfiniteDisable = false

  params = {
    orderBy: 'asc',
    orderField: 'dueAt'
  }

  get sortByPopover() {
    this.pagination.currentPage += 1
    return {
      options: [
        {
          label: this.$t('attribute.dueAt'),
          value: 'dueAt'
        },
        {
          label: this.$t('attribute.issueAt'),
          value: 'createdAt'
        }
      ],
      optionValue: this.params.orderField
    }
  }

  get selectedGroupingProjectId() {
    const invoices = this.invoices.filter(invoice =>
      this.groupingBillIds.includes(invoice._id)
    )
    return invoices[0] && invoices[0]?.projectId
  }

  get groupingBillsPriceExceedLimit(): boolean {
    const totalPrice = this.invoices
      .filter(invoice => this.groupingBillIds.includes(invoice.id))
      .reduce(
        (total, currentInvoice) =>
          total + currentInvoice.grandTotalAfterCreditNote.decimal,
        0
      )

    return totalPrice > 20000000 || totalPrice <= 0
  }

  get visibleInvoices(): any[] {
    return this.isGrouping
      ? this.invoices.filter(invoice => invoice.state === 'pending')
      : this.invoices
  }
  async mounted() {
    this.fetchInvoices()
    this.fetchProjects()
    this.noDataMessage = this.$t('bill.no_pending_bills')
  }

  handleLoadMore(e: any) {
    this.pagination.currentPage += 1
    const filter = this.$refs.billFilter.formatFilter()
    const parameters = this.withPagination({
      isGrouped: 0,
      ...this.params,
      ...filter,
      ...this.pagination
    })
    axiosNoLoader
      .get('/invoice', {
        params: {
          ...parameters
        }
      })
      .then(res => {
        setTimeout(() => {
          this.ionInfiniteDisable = !res.data.data.length
          // res.data.data.forEach((v: any) => {
          //   this.invoices.push(v)
          // })
          this.invoices.push(...res.data.data)
          e.target.complete()
        }, 500)
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleUnselectAll() {
    this.groupingBillIds = []
    this.selectAllForProjectId = null
  }

  handleSelectAll(projectId: number) {
    if (projectId) {
      this.selectAllForProjectId = projectId
      this.groupingBillIds = this.visibleInvoices
        .filter(invoice => invoice.projectId == projectId)
        .map(invoice => invoice._id)
    }
  }

  handleToggleGrouping() {
    this.isGrouping = !this.isGrouping
    this.groupingBillIds = []
  }

  handleToggleGroupingBillId(invoice: any) {
    if (
      !this.isGrouping ||
      this.groupingProjectMismatched(invoice) ||
      invoice.state !== 'pending'
    ) {
      return false
    }

    if (this.groupingBillIds.includes(invoice._id)) {
      this.groupingBillIds = this.groupingBillIds.filter(
        _billId => _billId !== invoice._id
      )
    } else {
      this.groupingBillIds = [...this.groupingBillIds, invoice._id]
    }
  }

  handleConfirmGroupBill() {
    if (this.groupingBillIds.length >= 2) return (this.confirmGroupingModalOpen = true)

    this.$store.state.modal.alert.content = this.$t(
      'bill.modal.confirm_group.please_select_at_least_2'
    )
    this.$store.state.modal.alert.isOpen = true
  }

  handleSortBy(orderField: string) {
    this.params.orderField = orderField
    if (orderField === 'createdAt') this.params.orderBy = 'desc'
    if (orderField === 'dueAt') this.params.orderBy = 'asc'
    this.ionInfiniteDisable = false
    this.setCurrentPage(1)
    this.fetchInvoices()
  }

  handleFilter() {
    this.noDataMessage = this.$t('label.data_not_found')
    this.filterModalOpen = false
    this.ionInfiniteDisable = false
    this.setCurrentPage(1)
    this.fetchInvoices()
  }

  createInvoiceGroup() {
    this.confirmGroupingModalOpen = false

    const data = {
      invoiceIds: this.groupingBillIds
    }

    this.$http
      .post('/invoice/group', data)
      .then(response =>
        this.$router.push(`/bill_groups?highlightId=${response.data._id}`)
      )
      .catch(flashResponseError)
  }

  fetchInvoices(_params = {}) {
    const filter = this.$refs.billFilter.formatFilter()
    const params = this.withPagination({
      isGrouped: 0,
      ...this.params,
      ...filter,
      ..._params
    })
    this.$http
      .get('/invoice', { params })
      .then(res => {
        this.invoices = res.data?.data
        this.setPagination(res.data?.meta)
      })
      .catch(flashResponseError)
  }

  fetchProjects() {
    this.$http.get('/capi/project').then(res => {
      this.$store.state.projects = res.data
    })
  }

  groupingProjectMismatched(invoice: any): boolean {
    return (
      this.groupingBillIds.length > 0 &&
      invoice.projectId !== this.selectedGroupingProjectId
    )
  }
}
