
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonIcon
} from '@ionic/vue'
import PageLayout from '@/components/Layout/PageLayout.vue'
import { PageHeader } from '@/components/Layout'
import { ServiceDataInterface } from '@/interfaces/service'

class Props {
  serviceList!: ServiceDataInterface[]
}

@Component({
  components: {
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonIcon,
    PageLayout,
    PageHeader
  },
  props: ['serviceList']
})
export default class ServiceHomeMobile extends Vue.with(Props) {
  serviceClicked(name: string, iframeLink: string) {
    this.$router.push({ path: '/services/' + name, query: { iframeLink } })
  }
}
