
import { Options as Component, Vue } from 'vue-class-component'
import { IonButton, IonRow, IonCol } from '@ionic/vue'
import FloatModal from './FloatModal.vue'

class Props {}

@Component({
  components: { IonButton, FloatModal, IonRow, IonCol },
  props: ['confirm', 'cancel']
})
export default class ConfirmModal extends Vue.with(Props) {}
