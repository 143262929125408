
import { Options as Component, Vue } from 'vue-class-component'
import { IonRippleEffect, IonCard, IonRow, IonCol, IonBadge } from '@ionic/vue'

class Props {
  'isSelected'!: string
}
@Component({
  components: { IonRippleEffect, IonCard, IonRow, IonCol, IonBadge },
  props: ['isSelected']
})
export default class SettingMenuItem extends Vue.with(Props) {}
