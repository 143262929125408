
import { Options as Component, Vue } from 'vue-class-component'
import { PageLayout, PageHeader } from '@/components/Layout'
import PdfViewer from '@/components/PdfViewer.vue'
import { BASE_API } from '@/constants'

@Component({
  components: { PageLayout, PdfViewer, PageHeader },
  props: []
})
export default class BillGroupPdf extends Vue {
  invoiceGroup = {} as any

  get defaultPageCount() {
    if (this.$route.query.type === 'receipt') return 1
    return this.invoiceGroup.invoiceIds.length
  }

  get pageHeader() {
    return this.$t(`bill.label.download_${this.$route.query.type}`)
  }

  get fileUrl(): string {
    if (this.invoiceGroup?._id) {
      return `${BASE_API}/invoice/group/${this.$route.params.id}/${this.$route.query.type}`
    }
    return ''
  }

  get fileName(): string {
    const invoiceNumbers = this.invoiceGroup?.invoices
      ?.map((invoice: any) => invoice?.number)
      ?.join('_')

    return `invoice_group_${invoiceNumbers}`
  }

  mounted() {
    this.$http.get(`/invoice/group/` + this.$route.params.id).then(res => {
      this.invoiceGroup = res?.data
    })
  }
}
