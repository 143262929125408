<template>
  <PageLayout>
    <PageHeader :title="$t('service.title')" :withBackButton="true">
      <template v-slot:action>
        <IonButton mode="md" fill="clear" :style="{ float: 'right' }">
          <IonIcon color="primary" icon="duplicate" />
        </IonButton>
      </template>
    </PageHeader>

    <iframe
      title="Service Website"
      :src="$route.query.iframeLink"
      :style="` height: ${isIos() ? 'calc(100vh - 85px);' : 'calc(100vh - 65px);'}`"
      scrolling="yes"
      width="100%"
      frameborder="0"
    >
    </iframe>
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import PageLayout from '@/components/Layout/PageLayout.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import { isPlatform, IonIcon } from '@ionic/vue'

class Props {}

@Component({
  components: { PageLayout, PageHeader, IonIcon },
  props: []
})
export default class ServicesShow extends Vue.with(Props) {
  isIos() {
    return isPlatform('ios')
  }
}
</script>

<style scoped lang="scss"></style>
