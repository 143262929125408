<template>
  <div class="reddot-container" v-if="!hidden">
    <div class="dot red small" :style="wrapperStyle" />
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'

class Props {
  wrapperStyle?: string
  hidden?: boolean | false
  count!: number
}

@Component({
  components: {},
  props: ['hidden', 'wrapperStyle', 'count']
})
export default class SmallRedDot extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
.reddot-container {
  padding: 7px 5px 5px 0px;
}
.dot {
  z-index: 1000;
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  text-align: center;
}
.dot.small {
  width: 12px;
  height: 12px;
}
.dot.red {
  z-index: 1000;
  background: #d93838;
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
}
</style>
