<template>
  <PageLayout @refreshData="handleRefresh">
    <template v-slot:header>
      <PageHeader :with-back-button="true" :title="$t('announcement.announcement')">
        <template v-slot:action>
          <IonButton
            mode="md"
            fill="clear"
            size="small"
            class="page-header-action"
            :filterModal="[$t('')]"
            @click="
              () => {
                filterModal.active = true
              }
            "
          >
            <IonIcon class="ion-nav-icon" src="/assets/icon/filter.svg"></IonIcon>
          </IonButton>
        </template>
      </PageHeader>
    </template>

    <IonRow>
      <IonCol size="12">
        <BaseField
          v-model="search"
          :placeholder="$t('announcement.label.search_announcements')"
        >
          <template v-slot:append>
            <IonIcon @click="getData" color="grey" src="/assets/icon/search.svg" />
          </template>
        </BaseField>
      </IonCol>
    </IonRow>
    <div v-if="!!announcementArr.length">
      <h5 class="pt-3">{{ $t('announcement.lastest') }}</h5>
      <ContentCard
        v-for="(announcement, i) in announcementArr"
        :key="i"
        :data="announcement"
        @click="announcementCardClicked(announcement)"
      />
      <IonRow v-if="ionInfiniteDisable" class="ion-justify-content-center pt-5 pb-12">
        <IonCol size="auto">
          <p class="text-grey-smoke">{{ $t('announcement.thats_all_now') }}</p>
        </IonCol>
      </IonRow>
      <IonInfiniteScroll
        @ionInfinite="handleLoadMore"
        threshold="1px"
        id="infinite-scroll"
        :disabled="ionInfiniteDisable"
      >
        <IonInfiniteScrollContent loadingSpinner="crescent" />
      </IonInfiniteScroll>
    </div>
    <div v-else>
      <EmptyListPlaceHolder :content="$t('label.data_not_found')" />
    </div>
    <Filter
      :isOpen="filterModal.active"
      transKey="announcement"
      :title="$t('label.filter')"
      :filter-list-arr="filterModal.list"
      :filter-content="filterModal.content"
      :on-cancel="canceled"
      :on-submit="filterSubmitted"
    />
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { PageLayout, PageHeader } from '@/components/Layout'
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonItem,
  IonIcon,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonModal,
  IonContent
} from '@ionic/vue'

import BaseField from '@/components/fields/BaseField.vue'
import Card from '@/components/Card/Card.vue'
import CardImages from '@/components/image/CardImages.vue'
import axiosNoLoader from '@/http-no-loading'
import Filter from '@/components/filters/Filter.vue'
import ContentCard from '@/components/content/ContentCard.vue'
import EmptyListPlaceHolder from '@/components/EmptyListPlaceHolder.vue'

class Props {}

@Component({
  components: {
    EmptyListPlaceHolder,
    ContentCard,
    Filter,
    CardImages,
    Card,
    BaseField,
    PageHeader,
    PageLayout,
    IonGrid,
    IonButton,
    IonRow,
    IonCol,
    IonInput,
    IonItem,
    IonIcon,
    IonText,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonModal,
    IonContent
  },
  props: []
})
export default class AnnouncementHomePage extends Vue.with(Props) {
  search = ''
  announcementArr = [] as {
    id: string
    title: string
    content: string
    image: string[]
    projectId: number
    userId: number
    projectNameTh: string
    projectNameEn: string
    createdAt: string
    updatedAt: string
  }[]

  filterModal = {
    active: false,
    list: [
      // { name: 'news_types_filter', key: 'types', filters: [{ name: 'all', key: '*' }] },
      {
        name: 'date_filter',
        key: 'date',
        filters: [
          { name: 'today', key: 'today' },
          {
            name: 'yesterday',
            key: 'yesterday'
          },
          {
            name: 'last_7_days',
            key: 'last_7_days'
          },
          {
            name: 'last_14_days',
            key: 'last_14_days'
          },
          {
            name: 'last_30_days',
            key: 'last_30_days'
          },
          { name: 'all', key: 'all' }
        ]
      }
    ],
    content: { date: 'all', types: '*' }
  }
  ionInfiniteDisable = false
  pagination = 0

  params = {
    orderBy: 'desc',
    orderField: 'createdAt'
  }

  async mounted() {
    await this.fetchAnnouncements()
  }

  fetchAnnouncements() {
    this.$http
      .get('/announcement', {
        params: {
          ...this.filterModal.content,
          ...this.params,
          skip: this.pagination,
          search: this.search
        }
      })
      .then(res => {
        this.announcementArr = res.data
      })
      .catch(console.log)
  }

  handleRefresh(e: any) {
    this.pagination = 0
    setTimeout(() => {
      axiosNoLoader
        .get('/announcement', {
          params: {
            ...this.filterModal.content,
            ...this.params,
            skip: this.pagination,
            search: this.search
          }
        })
        .then(res => {
          this.announcementArr = res.data
        })
        .catch(err => {
          console.log(err)
        })
      e.target.complete()
    }, 1000)
    console.log(e)
  }

  handleLoadMore(e: any) {
    this.pagination += 1
    axiosNoLoader
      .get('/announcement', {
        params: {
          ...this.filterModal.content,
          ...this.params,
          skip: this.pagination * 20,
          search: this.search
        }
      })
      .then(res => {
        setTimeout(() => {
          this.ionInfiniteDisable = !res.data.length
          res.data.forEach((v: any) => {
            this.announcementArr.push(v)
          })
          e.target.complete()
        }, 500)
      })
      .catch(err => {
        console.log(err)
      })
  }

  announcementCardClicked(announcement: any) {
    this.$router.push({
      name: 'Announcement Show',
      params: { id: announcement.id, data: JSON.stringify(announcement) }
    })
  }

  filterSubmitted(data: any) {
    this.filterModal.content = data
    this.pagination = 0
    this.ionInfiniteDisable = false
    this.fetchAnnouncements()
    this.filterModal.active = false
  }

  canceled() {
    this.filterModal.active = false
  }
}
</script>
