import { Options as Component, Vue } from 'vue-class-component'

@Component({})
export default class AlertModal extends Vue {
  get alertModal() {
    return this.$store.state.modal.alert
  }

  openAlertModal(message: string) {
    this.alertModal.content = message
    this.alertModal.isOpen = true
  }

  closeAlertModal() {
    this.alertModal.content = ''
    this.alertModal.isOpen = false
  }
}
