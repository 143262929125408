<template>
  <PageLayout style="padding-bottom: 120px">
    <!--    Page header-->
    <PageHeader backTo="/home" :title="$t('bill.bills')" :withBackButton="true">
      <template v-slot:action>
        <div style="float: right">
          <IonButton
            mode="md"
            fill="clear"
            size="small"
            class="page-header-action"
            @click="handleToggleGrouping()"
          >
            <IonIcon src="/assets/icon/select.svg" />
          </IonButton>
          <IonButton
            mode="md"
            fill="clear"
            size="small"
            class="page-header-action"
            @click="filterModalOpen = true"
          >
            <IonIcon src="/assets/icon/filter.svg" />
          </IonButton>
          <IonButton
            mode="md"
            fill="clear"
            size="small"
            class="page-header-action"
            @click="handleShowSortByPopover"
          >
            <IonIcon src="/assets/icon/sort_by.svg" />
          </IonButton>
        </div>
      </template>
    </PageHeader>

    <BillTabBar currentTab="bills" />
    <!--Grouping function-->
    <IonRow v-if="isGrouping">
      <IonCol class="select-button-wrapper">
        <IonButton
          v-if="groupingBillIds.length > 0"
          color="medium"
          @click="handleUnselectAll"
        >
          {{ $t('button.unselect_all') }}
        </IonButton>
        <IonButton class="select-all-button" @click="$refs.selectAll.$el.click()">
          {{ $t('button.select_all') }}
        </IonButton>
        <IonSelect
          class="d-none"
          ref="selectAll"
          :okText="$t('button.select')"
          :cancelText="$t('button.cancel')"
          :placeholder="$t('label.select_project')"
          :interfaceOptions="{ header: $t('label.select_project') }"
          @ionChange="e => handleSelectAll(e.detail?.value)"
          :value="selectAllForProjectId"
        >
          <IonSelectOption
            v-for="(project, key) in $store.state.projects || []"
            :key="key"
            :value="project.id"
          >
            {{ I18N(project.nameTh, project.nameEn) }}
          </IonSelectOption>
        </IonSelect>
      </IonCol>
    </IonRow>

    <EmptyListPlaceHolder
      v-if="!visibleInvoices.length"
      style="padding-top: 100px"
      :content="noDataMessage"
    />
    <!-- loop card-->
    <div v-else class="bill-list">
      <div v-for="(invoice, i) in visibleInvoices" :key="i" class="d-flex">
        <div class="checkbox-wrapper" v-if="isGrouping">
          <CheckBox
            v-if="!groupingProjectMismatched(invoice)"
            @change="handleToggleGroupingBillId(invoice)"
            :value="groupingBillIds.includes(invoice._id)"
          />
        </div>
        <ClickAndHold
          @trigger="!isGrouping && handleToggleGrouping()"
          class="click-hold-wrapper"
        >
          <BillCard
            :invoice="invoice"
            cardStyle="outline"
            :redirectDisabled="isGrouping"
            :disabled="groupingProjectMismatched(invoice)"
            @click="handleToggleGroupingBillId(invoice)"
          >
            <template v-slot:disabled-message>
              {{ $t('bill.this_belongs_to_another_project') }} <br />
              {{ $t('bill.please_select_bill_in_same_project') }}
            </template>
          </BillCard>
        </ClickAndHold>
      </div>
    </div>

    <IonInfiniteScroll
      @ionInfinite="handleLoadMore"
      threshold="1px"
      id="infinite-scroll"
      :disabled="ionInfiniteDisable"
    >
      <IonInfiniteScrollContent loadingSpinner="crescent"> </IonInfiniteScrollContent>
    </IonInfiniteScroll>

    <IonRow v-if="isGrouping" class="sticky-footer">
      <IonCol size="6">
        <IonButton color="medium" @click="handleToggleGrouping">
          {{ $t('button.cancel') }}
        </IonButton>
      </IonCol>
      <IonCol size="6">
        <IonButton class="btn-gradient" @click="handleConfirmGroupBill">
          {{ $t('bill.label.group_bill') }}
        </IonButton>
      </IonCol>
    </IonRow>

    <BillFilter
      :isOpen="filterModalOpen"
      :projects="$store.state.projects || []"
      stateType="unpaid"
      ref="billFilter"
      v-on:close="filterModalOpen = false"
      v-on:submit="handleFilter"
    />

    <ConfirmModal
      v-if="confirmGroupingModalOpen"
      @confirm="createInvoiceGroup"
      @cancel="confirmGroupingModalOpen = false"
    >
      <template v-slot:header>
        {{
          $t('bill.modal.confirm_group.are_you_sure', { number: groupingBillIds.length })
        }}
      </template>
    </ConfirmModal>
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue, mixins } from 'vue-class-component'
import PageLayout from '@/components/Layout/PageLayout.vue'
import EmptyListPlaceHolder from '@/components/EmptyListPlaceHolder.vue'
import { BillCard, BillTabBar } from '@/modules/bills'
import PageHeader from '@/components/Layout/PageHeader.vue'
import { Pagination, SortBy } from '@/mixins'
import BillFilter from '@/components/filters/BillFilter.vue'
import {
  IonIcon,
  IonButton,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/vue'
import CheckBox from '@/components/fields/CheckBox.vue'
import ClickAndHold from '@/components/ClickAndHold.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import { flashResponseError } from '@/utils/flashMessage'
import axiosNoLoader from '@/http-no-loading'

class Props {
  $refs!: any
}

@Component({
  components: {
    IonIcon,
    IonButton,
    IonRow,
    IonCol,
    IonSelect,
    IonSelectOption,
    PageHeader,
    EmptyListPlaceHolder,
    PageLayout,
    BillCard,
    BillFilter,
    BillTabBar,
    CheckBox,
    ClickAndHold,
    ConfirmModal,
    IonInfiniteScroll,
    IonInfiniteScrollContent
  }
})
export default class BillList extends mixins(Vue.with(Props), Pagination, SortBy) {
  invoices = [] as any[]
  isGrouping = false as boolean
  groupingBillIds = [] as number[]
  filterModalOpen = false
  confirmGroupingModalOpen = false
  noDataMessage = ''
  selectAllForProjectId: any = null
  ionInfiniteDisable = false

  params = {
    orderBy: 'asc',
    orderField: 'dueAt'
  }

  get sortByPopover() {
    this.pagination.currentPage += 1
    return {
      options: [
        {
          label: this.$t('attribute.dueAt'),
          value: 'dueAt'
        },
        {
          label: this.$t('attribute.issueAt'),
          value: 'createdAt'
        }
      ],
      optionValue: this.params.orderField
    }
  }

  get selectedGroupingProjectId() {
    const invoices = this.invoices.filter(invoice =>
      this.groupingBillIds.includes(invoice._id)
    )
    return invoices[0] && invoices[0]?.projectId
  }

  get groupingBillsPriceExceedLimit(): boolean {
    const totalPrice = this.invoices
      .filter(invoice => this.groupingBillIds.includes(invoice.id))
      .reduce(
        (total, currentInvoice) =>
          total + currentInvoice.grandTotalAfterCreditNote.decimal,
        0
      )

    return totalPrice > 20000000 || totalPrice <= 0
  }

  get visibleInvoices(): any[] {
    return this.isGrouping
      ? this.invoices.filter(invoice => invoice.state === 'pending')
      : this.invoices
  }
  async mounted() {
    this.fetchInvoices()
    this.fetchProjects()
    this.noDataMessage = this.$t('bill.no_pending_bills')
  }

  handleLoadMore(e: any) {
    this.pagination.currentPage += 1
    const filter = this.$refs.billFilter.formatFilter()
    const parameters = this.withPagination({
      isGrouped: 0,
      ...this.params,
      ...filter,
      ...this.pagination
    })
    axiosNoLoader
      .get('/invoice', {
        params: {
          ...parameters
        }
      })
      .then(res => {
        setTimeout(() => {
          this.ionInfiniteDisable = !res.data.data.length
          // res.data.data.forEach((v: any) => {
          //   this.invoices.push(v)
          // })
          this.invoices.push(...res.data.data)
          e.target.complete()
        }, 500)
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleUnselectAll() {
    this.groupingBillIds = []
    this.selectAllForProjectId = null
  }

  handleSelectAll(projectId: number) {
    if (projectId) {
      this.selectAllForProjectId = projectId
      this.groupingBillIds = this.visibleInvoices
        .filter(invoice => invoice.projectId == projectId)
        .map(invoice => invoice._id)
    }
  }

  handleToggleGrouping() {
    this.isGrouping = !this.isGrouping
    this.groupingBillIds = []
  }

  handleToggleGroupingBillId(invoice: any) {
    if (
      !this.isGrouping ||
      this.groupingProjectMismatched(invoice) ||
      invoice.state !== 'pending'
    ) {
      return false
    }

    if (this.groupingBillIds.includes(invoice._id)) {
      this.groupingBillIds = this.groupingBillIds.filter(
        _billId => _billId !== invoice._id
      )
    } else {
      this.groupingBillIds = [...this.groupingBillIds, invoice._id]
    }
  }

  handleConfirmGroupBill() {
    if (this.groupingBillIds.length >= 2) return (this.confirmGroupingModalOpen = true)

    this.$store.state.modal.alert.content = this.$t(
      'bill.modal.confirm_group.please_select_at_least_2'
    )
    this.$store.state.modal.alert.isOpen = true
  }

  handleSortBy(orderField: string) {
    this.params.orderField = orderField
    if (orderField === 'createdAt') this.params.orderBy = 'desc'
    if (orderField === 'dueAt') this.params.orderBy = 'asc'
    this.ionInfiniteDisable = false
    this.setCurrentPage(1)
    this.fetchInvoices()
  }

  handleFilter() {
    this.noDataMessage = this.$t('label.data_not_found')
    this.filterModalOpen = false
    this.ionInfiniteDisable = false
    this.setCurrentPage(1)
    this.fetchInvoices()
  }

  createInvoiceGroup() {
    this.confirmGroupingModalOpen = false

    const data = {
      invoiceIds: this.groupingBillIds
    }

    this.$http
      .post('/invoice/group', data)
      .then(response =>
        this.$router.push(`/bill_groups?highlightId=${response.data._id}`)
      )
      .catch(flashResponseError)
  }

  fetchInvoices(_params = {}) {
    const filter = this.$refs.billFilter.formatFilter()
    const params = this.withPagination({
      isGrouped: 0,
      ...this.params,
      ...filter,
      ..._params
    })
    this.$http
      .get('/invoice', { params })
      .then(res => {
        this.invoices = res.data?.data
        this.setPagination(res.data?.meta)
      })
      .catch(flashResponseError)
  }

  fetchProjects() {
    this.$http.get('/capi/project').then(res => {
      this.$store.state.projects = res.data
    })
  }

  groupingProjectMismatched(invoice: any): boolean {
    return (
      this.groupingBillIds.length > 0 &&
      invoice.projectId !== this.selectedGroupingProjectId
    )
  }
}
</script>

<style scoped lang="scss">
.click-hold-wrapper {
  width: 100%;
  padding: 0px 2px 0px 2px;
}

.checkbox-wrapper {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bill-list {
  margin-top: -2px;
  margin-bottom: 8px;
  padding-bottom: 100px;
  padding-left: 5px;
  padding-right: 5px;
}

.sticky-footer {
  position: fixed;
  left: 0;
  bottom: -2px;
  height: 83px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 12px #00000029;
  padding-top: 24px;

  ion-col:first-child {
    padding-right: 5px;
    ion-button {
      float: right;
    }
  }
  ion-col:last-child {
    padding-left: 5px;
  }

  ion-button {
    width: 140px;
    height: 30px;
    --border-radius: 5px;
    letter-spacing: 0px;
    font: normal normal normal 16px Prompt;
  }
}

.select-button-wrapper {
  text-align: right;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 4px;

  ion-button {
    --border-radius: 5px;
    font: normal normal normal 14px/18px Prompt;
    letter-spacing: 0px;
    color: white;
    height: 26px;
  }
  .select-all-button {
    --background: var(--color-primary-light);
  }
}
</style>
