<template>
  <IonList>
    <IonItem
      v-for="(option, key) in options"
      :key="key"
      lines="none"
      detail="false"
      button
      :class="{ selected: option.value === optionValue }"
      @click="handleSort(option.value)"
    >
      {{ option.label }}
    </IonItem>
  </IonList>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonList, IonItem } from '@ionic/vue'

class Props {
  handleSort!: Function
  optionValue!: string
  options!: string[]
}

@Component({
  components: { IonList, IonItem },
  props: ['handleSort', 'optionValue', 'options']
})
export default class SortByPopover extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
ion-list {
  padding: 11px 0px 11px 0px;
  border: 0.5px solid $color-primary;
  border-radius: 10px;

  ion-item {
    --padding-start: 10px;
    --min-height: 25px;
    font-size: 14px;
  }

  ion-item.selected {
    --background: var(--color-primary);
    color: white;
  }
}
</style>
