<template>
  <div class="reddot-container" v-if="!hidden">
    <div v-if="count > 0" class="dot red large" :style="wrapperStyle">
      <div v-if="count < 99" class="red-dot-text"> {{ count }}</div>
      <div v-else class="red-dot-text"> 99+ </div>
    </div>
    <div v-else class="dot red small" :style="wrapperStyle" />
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'

class Props {
  wrapperStyle?: string
  hidden?: boolean | false
  count!: number
}

@Component({
  components: {},
  props: ['hidden', 'wrapperStyle', 'count']
})
export default class RedDot extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
.reddot-container {
  text-align: center;
  width: 100px;
  height: 0px;
  display: flex;
  flex-direction: row-reverse;
}
.dot {
  z-index: 1000;
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  text-align: center;
}
.dot.small {
  margin: 10px;
  width: 10px;
  height: 10px;
}
.dot.large {
  width: 25px;
  height: 25px;
}

.dot.red {
  z-index: 1000;
  background: #d93838;
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  margin-top: 5px;
}
.red-dot-text {
  text-align: center;
  font: normal normal normal 11px/10px Prompt;
  letter-spacing: 0.8px;
  color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
</style>
