
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonCard, IonImg } from '@ionic/vue'

class Props {
  size!: number | 12
}

@Component({
  components: { IonRow, IonCol, IonCard, IonImg },
  props: ['size']
})
export default class LanguageBadgeSwitch extends Vue.with(Props) {
  languages = [
    {
      locale: 'th',
      flagSrc: '/assets/icon/flag-TH.png',
      text: 'ไทย'
    },
    {
      locale: 'en',
      flagSrc: '/assets/icon/flag-EN.png',
      text: 'English'
    }
  ]
  languageBadgeStyle(lang: string) {
    return this.$i18n.locale === lang ? 'border: 2px solid #008CAA;' : null
  }
}
