<template>
  <div class="">
    <slot name="header" />
    <IonContent class="" :class="backgroundClass" :fullscreen="true" :scrollY="isScrollY">
      <!--<IonRefresher-->
      <!--  slot="fixed"-->
      <!--  @ionRefresh="doRefresh($event)"-->
      <!--  pull-min="100"-->
      <!--  pull-max="180"-->
      <!--&gt;-->
      <!--  <IonRefresherContent-->
      <!--    pulling-icon="chevronDownCircleOutline"-->
      <!--    pulling-text="Pull to refresh"-->
      <!--    refreshing-spinner="crescent"-->
      <!--  ></IonRefresherContent>-->
      <!--</IonRefresher>-->
      <IonGrid class="fill-height py-0 ma-0" :class="getClass">
        <slot></slot>
      </IonGrid>
    </IonContent>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRefresher,
  IonRefresherContent
} from '@ionic/vue'

class Props {
  background?: string
  isFullScreen?: {
    type: boolean
    default: false
  }
  isScrollY?: {
    type: boolean
    default: true
  }
}

@Component({
  components: { IonPage, IonContent, IonGrid, IonRefresher, IonRefresherContent },
  props: ['background', 'isFullScreen', 'isScrollY']
})
export default class PageLayout extends Vue.with(Props) {
  get backgroundClass() {
    return this.background ? 'st-background-' + this.background : 'st-background-grey'
  }
  get getClass() {
    if (this.isFullScreen) return 'px-0'
    else return 'px-2'
  }
}
</script>

<style scoped lang="scss">
ion-content {
  &.st-background-white {
    --background: #fff;
  }
  &.st-background-grey {
    --background: #fff url('/assets/img/background/grey_bg.png') no-repeat fixed center /
      cover;
    &::part(background) {
      background: #fff url('/assets/img/background/grey_bg.png') no-repeat fixed center /
        cover;
    }
  }
  &.st-background-color {
    --background: #fff url('/assets/img/background/city_bg.svg') no-repeat fixed center /
      cover;
    &::part(background) {
      background: #fff url('/assets/img/background/city_bg.svg') no-repeat fixed center /
        cover;
    }
  }
}
</style>
