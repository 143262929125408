<template>
  <IonModal :isOpen="true" cssClass="modal-wrapper" :backdropDismiss="false">
    <IonContent :fullscreen="true">
      <div
        :class="{ 'pa-3': true, fullHeight: true, blur: $store.state.modal.alert.isOpen }"
      >
        <div class="header">
          {{ $t('payment_method.qr_code') }}
          <IonIcon name="close" @click="handleCloseModal" />
        </div>

        <div class="details">
          <div>{{ $t('bill.label.pay_to_gb_prime') }}</div>
          <div>{{ $t('bill.label.ref') }} 1 {{ invoice.number }}</div>
        </div>

        <img v-if="imageUrl" :src="imageUrl" alt="QR Code" />
        <IonButton
          v-if="imageUrl"
          @click="isOnNativeEnvironment && handleSaveToGallery()"
          :download="!isOnNativeEnvironment && new Date().getTime() + '.png'"
          :href="!isOnNativeEnvironment ? imageUrl : null"
          expand="block"
        >
          <IonIcon class="ion-nav-icon pr-2" src="/assets/icon/save_to_album_icon.svg" />
          {{ $t('bill.label.save_to_album') }}
        </IonButton>
      </div>
    </IonContent>
  </IonModal>
</template>

<script lang="ts">
import { Options as Component, Vue, mixins } from 'vue-class-component'
import { Base64ToGallery } from '@ionic-native/base64-to-gallery'
import PageLayout from '@/components/Layout/PageLayout.vue'
import { Diagnostic } from '@ionic-native/diagnostic'
import {
  GB_PRIME_CUSTOMER_KEY,
  GB_PRIME_PUBLIC_KEY,
  GB_PRIME_URL,
  BASE_API
} from '@/constants'

import {
  IonButton,
  IonIcon,
  IonModal,
  IonRow,
  IonCol,
  isPlatform,
  IonContent
} from '@ionic/vue'
import PaymentModal from './PaymentModal.vue'
import { flashResponseError } from '@/utils/flashMessage'

class Props {
  invoice!: any
}

@Component({
  components: { IonButton, IonIcon, IonModal, IonRow, IonCol, IonContent, PageLayout },
  props: ['invoice'],
  emits: ['close', 'createTransaction']
})
export default class GbPrimeQrCodeModal extends mixins(Vue.with(Props), PaymentModal) {
  brand = 'gb_prime'
  method = 'qr_code'
  imageUrl: any = null
  paymentReference?: string
  isOnNativeEnvironment = false
  mounted() {
    this.handleGenerateGbPrimeQrCode()
    if (isPlatform('cordova')) {
      this.isOnNativeEnvironment = true
    }
  }

  handleCloseModal() {
    this.imageUrl && window.URL.revokeObjectURL(this.imageUrl)
    this.$emit('close')
  }

  async handleSaveToGallery() {
    fetch(this.imageUrl)
      .then(res => res.blob())
      .then(blob => {
        const reader: any = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = async () => {
          const base64data = reader.result.toString()

          if (isPlatform('android')) {
            const isHasStoragePermission = await Diagnostic.requestRuntimePermission(
              'WRITE_EXTERNAL_STORAGE'
            )
            if (isHasStoragePermission != 'GRANTED') {
              await Diagnostic.requestExternalStorageAuthorization()
            }
          }
          Base64ToGallery.base64ToGallery(base64data)
            .then(() => alert(`Image saved`))
            .catch(alert)
        }
      })
  }

  async handleGenerateGbPrimeQrCode() {
    const loading = await this.createLoading()
    try {
      this.paymentReference = new Date().getTime().toString()
      const payment = await this.createPayment(
        this.invoice,
        this.charge,
        this.paymentReference,
        this.paymentMethod
      )
      const qrCodeImageBlob = await this.generateQrCode(
        this.charge,
        this.paymentReference,
        payment
      )

      this.imageUrl = qrCodeImageBlob
      this.$emit('createTransaction', this.paymentReference)
      this.$emit('reload')
      loading.dismiss()
    } catch (error) {
      loading.dismiss()
      this.$emit('reload')
      flashResponseError(error)
    }
  }

  async createPayment(
    invoice: any,
    charge: any,
    paymentReference: string,
    paymentMethod: any
  ) {
    const data: any = {
      amount: charge?.amount?.decimal,
      serviceFee: charge?.serviceFee?.decimal,
      paymentGatewayFee: charge?.paymentGatewayFee?.decimal,
      referenceNo: paymentReference,
      brand: paymentMethod?.brand,
      method: paymentMethod?.method,
      invoiceId: invoice._id
    }

    if (invoice.entity === 'invoiceGroup') {
      delete data.invoiceId
      data.invoiceGroupId = invoice._id
    }

    const response = await this.$http.post(`/invoice/transaction`, data)

    const payment = response.data
    return payment
  }

  async generateQrCode(charge: any, paymentReference: string, payment: any) {
    const data = {
      token: GB_PRIME_CUSTOMER_KEY,
      amount: charge?.amount?.decimal,
      referenceNo: paymentReference,
      backgroundUrl: `${BASE_API}/invoice/transaction/${payment?._id}/receive?brand=${this.brand}&method=${this.method}`
    }

    const formBody = Object.entries(data)
      .map(([k, v]) => encodeURIComponent(k) + '=' + encodeURIComponent(v))
      .join('&')

    const qrCodeResponse = await fetch(`${GB_PRIME_URL}/gbp/gateway/qrcode`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${btoa(GB_PRIME_PUBLIC_KEY + ':')}`,
        Accept: 'image/png'
      },
      body: formBody
    })

    const imageBlob = await qrCodeResponse.blob()
    const qrCodeImageBlob = window.URL.createObjectURL(imageBlob)

    return qrCodeImageBlob
  }
}
</script>

<style scoped lang="scss">
.modal-wrapper {
  .fullHeight {
    height: 100%;
  }

  .blur {
    background: rgb(170, 170, 170) !important;
    --background: rgb(170, 170, 170) !important;
    & * {
      background: rgb(170, 170, 170) !important;
      --background: rgb(170, 170, 170) !important;
    }
  }

  & div.ion-page {
    padding: 20px;
    justify-content: flex-start;
    font-family: 'Prompt', Helvetica, Arial, sans-serif;
    padding-top: env(--ion-safe-area-top, 20px);
  }

  .header {
    font-size: 20px;
    font-weight: 700;
    width: 100%;
    margin-bottom: 20px;

    ion-icon {
      position: absolute;
      font-size: 28px;
      right: 0px;
    }
  }

  .details {
    font-size: 12px;
    color: #333333;
    letter-spacing: 0.35px;
    margin-bottom: 25px;

    div {
      margin-bottom: 3px;
    }

    div:first-child {
      font-weight: bold;
    }
  }

  ion-button {
    margin-top: 15px;
  }
}
</style>
