
import { Options as Component, Vue, mixins } from 'vue-class-component'
import { Plugins } from '@capacitor/core'
import {
  IonModal,
  IonIcon,
  IonButton,
  IonItem,
  IonSelect,
  IonLabel,
  IonSelectOption,
  IonContent
} from '@ionic/vue'

import { BASE_API } from '@/constants'
import PageLayout from '@/components/Layout/PageLayout.vue'
import PaymentModal from './PaymentModal.vue'

class Props {
  invoice!: any
}

@Component({
  components: {
    IonModal,
    IonIcon,
    PageLayout,
    IonButton,
    IonItem,
    IonSelect,
    IonLabel,
    IonSelectOption,
    IonContent
  },
  props: ['invoice'],
  emits: ['close', 'createTransaction']
})
export default class GbPrimeMobileBankingModal extends mixins(
  Vue.with(Props),
  PaymentModal
) {
  brand = 'gb_prime'
  method = 'mobile_banking'

  bankOptions = [
    {
      name: 'kbank',
      code: '004'
    },
    {
      name: 'scb',
      code: '014'
    },
    {
      name: 'krungsri',
      code: '025'
    }
  ]

  form = {
    bankCode: '004'
  }

  handleCloseModal() {
    this.$emit('close')
  }

  async handlePayment() {
    try {
      const { paymentUrl, paymentReference }: any = await this.createPayment(
        this.invoice,
        this.form.bankCode,
        this.charge,
        this.paymentMethod
      )

      this.$emit('createTransaction', paymentReference)
      await Plugins.Browser.open({ url: paymentUrl })
      this.$emit('reload')
    } catch (error) {
      this.$emit('reload')
      console.log(error)
      alert(error)
    }
  }

  async createPayment(invoice: any, bankCode: string, charge: any, paymentMethod: any) {
    const data: any = {
      bankCode,
      amount: charge?.amount?.decimal,
      serviceFee: charge?.serviceFee?.decimal,
      paymentGatewayFee: charge?.paymentGatewayFee?.decimal,
      brand: paymentMethod?.brand,
      method: paymentMethod?.method,
      invoiceId: invoice._id
    }

    if (invoice.entity === 'invoiceGroup') {
      delete data.invoiceId
      data.invoiceGroupId = invoice._id
    }

    const response: any = await this.$http.post(`/invoice/transaction`, data)
    const payment = response?.data
    if (!payment?.referenceNo) {
      throw new Error(payment?.failureCode || this.$t('bill.error.create_payment_failed'))
    }

    const paymentReference = payment?.referenceNo
    const paymentUrl = `${BASE_API}/invoice/transaction/${payment._id}/form?bankCode=${bankCode}&method=${this.method}&brand=${this.brand}`

    return { paymentUrl, paymentReference }
  }
}
