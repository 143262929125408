
import { Options as Component, Vue } from 'vue-class-component'
import { IonCol, IonRow, IonButton } from '@ionic/vue'

import { Card } from '@/components/Card'
import { PaymentsPanel } from '@/modules/bills'
import CustomerDetails from '@/modules/bills/CustomerDetails.vue'
import BillState from '@/modules/bills/BillState.vue'
import GrandTotalCard from '@/modules/bills/GrandTotalCard.vue'
import DocumentDownload from '@/modules/bills/DocumentDownload.vue'
import _ from 'lodash'

class Props {
  invoice!: any
  payment!: any
}

@Component({
  components: {
    PaymentsPanel,
    Card,
    BillState,
    CustomerDetails,
    GrandTotalCard,
    DocumentDownload,
    IonCol,
    IonRow,
    IonButton
  },
  props: ['invoice', 'payment'],
  emits: ['billPaid']
})
export default class BillShowContent extends Vue.with(Props) {
  currentTab: 'invoice_details' | 'credit_note' = 'invoice_details'

  get creditNote() {
    return _.get(this.invoice, 'creditNotes[0]')
  }

  get invoiceItems(): any[] {
    if (this.invoice?.type === 'invoice_groups') {
      return this.invoice.invoices?.flatMap((invoice: any) => invoice.invoiceItems)
    }
    return this.invoice?.invoiceItems || []
  }

  itemGotVat(item: any) {
    return item.vatType !== '0.00' && item.vatType !== 'none'
  }

  itemGotWht(item: any) {
    return (
      item.whtType != '0.00' && item.whtType != 'none' && item.whtType != 'not_specified'
    )
  }
}
