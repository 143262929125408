<template>
  <div class="desktop-nav-sidebar" style="width:128px;">
    <IonRow class="ion-justify-content-center">
      <IonCol size="auto">
        <IonImg src="assets/sky_logo.svg" style="width: 100px" class="mt-5 mb-10" />
      </IonCol>
    </IonRow>
    <IonRow>
      <IonCol v-for="(homeMenu, i) in menuData.menuList" :key="i" size="12" class="">
        <HomeMenu
          :desktop="true"
          :disabled="false"
          :src="homeMenu.src"
          :srcHover="homeMenu.srcHover"
          :redDot="homeMenu.redDot"
          :name="$t(`home.menu.${homeMenu.name}`)"
          :routerLink="homeMenu.routerLink"
        />
      </IonCol>
    </IonRow>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonImg } from '@ionic/vue'
import HomeMenu from '@/components/HomeMenu.vue'

class Props {}

@Component({
  components: { HomeMenu, IonRow, IonCol, IonImg },
  props: []
})
export default class DesktopNavBar extends Vue.with(Props) {
  menuData = {
    menuList: [
      {
        src: '/assets/icon/menu/home_icon_lb.png',
        srcHover: '/assets/icon/menu/home_icon_db.png',
        redDot: { count: 0 },
        name: 'home',
        routerLink: '/home',
        rules: []
      },
      {
        src: '/assets/icon/menu/invoice_icon_lb.png',
        srcHover: '/assets/icon/menu/invoice_icon_db.png',
        redDot: { count: 0 },
        name: 'bill',
        routerLink: '/bills',
        rules: ['bill_read', 'bill_process']
      },
      {
        src: '/assets/icon/menu/news_icon_lb.png',
        srcHover: '/assets/icon/menu/news_icon_db.png',
        redDot: { count: 0 },
        name: 'news',
        routerLink: '/news'
      },
      {
        src: '/assets/icon/menu/maintenance_icon_lb.png',
        srcHover: '/assets/icon/menu/maintenance_icon_db.png',
        redDot: { count: 0 },
        name: 'maintenance',
        routerLink: '/maintenance'
      },
      {
        src: '/assets/icon/menu/services_icon_lb.png',
        srcHover: '/assets/icon/menu/services_icon_db.png',
        redDot: { hidden: true },
        name: 'service',
        routerLink: '/services'
      }
    ],
    countUnreadNotifications: { postCount: 0, newsCount: 0, invoiceCount: 0 }
  }

  async mounted() {
    await this.getData()
  }

  async getData() {
    return new Promise(resolve => {
      this.$http
        .all([
          this.$http
            .get('/invoice', {
              params: {
                isGrouped: 0,
                state: ['pending', 'overdue'],
                page: 1,
                perPage: 3,
                orderBy: 'asc',
                orderField: 'dueAt'
              }
            })
            .catch(console.log)
        ])
        .then(resArr => {
          //menu notification
          if (resArr[0]) {
            const { unreadInvoiceCount } = resArr[0].data
            this.menuData.menuList[1].redDot.count = unreadInvoiceCount
          }
          const { newsViewCount, postViewCount } = this.$store.state.user
          this.menuData.menuList[2].redDot.count = newsViewCount
          this.menuData.menuList[3].redDot.count = postViewCount

          resolve(true)
        })
    })
  }
}
</script>

<style scoped lang="scss">
.desktop-nav-sidebar {
  position: fixed !important;
  height: 100%;
  background-color: #fff;
  box-shadow: 4px 0px 5px -1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 4px 0px 5px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 4px 0px 5px -1px rgba(0, 0, 0, 0.12);
}
</style>
