
import { Options as Component, Vue } from 'vue-class-component'
import { IonCol, IonRow } from '@ionic/vue'

import { Card } from '@/components/Card'

class Props {
  grandTotal!: string
}

@Component({
  components: {
    Card,
    IonCol,
    IonRow
  },
  props: ['grandTotal']
})
export default class GrandTotalCard extends Vue.with(Props) {}
