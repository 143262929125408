<template>
  <IonCard button :class="`setting-menu-item mx-auto ${isSelected ? 'selected' : null}`">
    <IonRow class="ion-justify-content-between ion-align-items-center">
      <IonCol size="auto">
        <slot />
      </IonCol>
      <IonCol size="auto">
        <IonBadge class="mb-n1" color="primary" v-if="isSelected">
          {{ $t(`setting.selected`) }}
        </IonBadge>
      </IonCol>
    </IonRow>

    <IonRippleEffect />
  </IonCard>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonRippleEffect, IonCard, IonRow, IonCol, IonBadge } from '@ionic/vue'

class Props {
  'isSelected'!: string
}
@Component({
  components: { IonRippleEffect, IonCard, IonRow, IonCol, IonBadge },
  props: ['isSelected']
})
export default class SettingMenuItem extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
.selected {
  border: 2px solid var(--color-primary);
}
.setting-menu-item {
  text-align: left;
  font: normal normal 300 16px Prompt;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 15px;
  padding: 12px 12px 12px 12px;

  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}
</style>
