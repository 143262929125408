
import { Options as Component, Vue } from 'vue-class-component'
import { BillShowContent, BillShowHeader } from '@/modules/bills'
import { PageLayout } from '@/components/Layout'

@Component({
  components: { BillShowContent, PageLayout, BillShowHeader },
  props: []
})
export default class BillShow extends Vue {
  invoice = {} as any
  payment = {} as any

  mounted() {
    this.fetchInvoice()
  }

  fetchInvoice() {
    this.$http.get(`/invoice/` + this.$route.params.id).then(res => {
      this.invoice = res?.data
      this.payment = this.invoice?.payments?.find(
        (payment: any) => payment?.state === 'received'
      )
    })
  }
}
