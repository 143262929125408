<template>
  <PageLayout>
    <IonCard class="pa-5">
      This is a form
      <Form @submit="formSubmitted" :validation-schema="formDataValidate">
        <IonRow class="ion-justify-content-center">
          <IonCol size="12">
            <Field name="firstName" v-slot="{ field }" v-model="formData.firstName">
              <IonItem class="pa-0 ma-0">
                <IonLabel> ชื่อ: </IonLabel>
                <IonInput v-bind="field" />
                <ErrorMessage name="firstName" />
              </IonItem>
            </Field>
          </IonCol>
        </IonRow>
        <IonButton class="btn-gradient" type="submit">Submit</IonButton>
      </Form>
    </IonCard>
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import PageLayout from '@/components/Layout/PageLayout.vue'
import * as yup from 'yup'
import { Form, Field, ErrorMessage } from 'vee-validate'
import {
  IonRow,
  IonCol,
  IonCard,
  IonItem,
  IonLabel,
  IonInput,
  IonButton
} from '@ionic/vue'
class Props {}

@Component({
  components: {
    PageLayout,
    Form,
    Field,
    ErrorMessage,
    IonRow,
    IonCol,
    IonCard,
    IonItem,
    IonLabel,
    IonInput,
    IonButton
  },
  props: []
})
export default class Forms extends Vue.with(Props) {
  formData = {
    firstName: ''
  }
  formDataValidate = yup.object({
    firstName: yup.string().typeError('must be string').required('Required')
  })
  formSubmitted() {
    console.log('formSubmitted')
  }
}
</script>

<style scoped lang="scss"></style>
