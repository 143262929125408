<template>
  <IonToolbar>
    <IonRow class="px-2 ion-justify-content-between ion-align-items-center fill-height">
      <IonCol size="auto">
        <IonIcon
          v-if="withBackButton"
          @click="onBack()"
          class="ion-nav-icon"
          src="/assets/icon/chevron-back.svg"
        />
      </IonCol>

      <IonCol size="auto" class="ion-nav-icon">
        <slot name="action" />
      </IonCol>
    </IonRow>
    <IonRow class="ion-justify-content-center" style="margin-top: -36px">
      <IonCol size="auto">
        <p style="font-size: 24px" class="ma-0">{{ title }} </p>
      </IonCol>
    </IonRow>
  </IonToolbar>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonBackButton,
  IonRow,
  IonCol,
  IonIcon
} from '@ionic/vue'

class Props {
  title!: string
  backTo?: string
  withBackButton?: boolean | false
}

@Component({
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonBackButton,
    IonRow,
    IonCol,
    IonIcon
  },
  props: {
    title: {
      type: String,
      default: 'aa'
    },
    withBackButton: {
      type: Boolean,
      default: true
    },
    backTo: {
      type: String
    }
  }
})
export default class PageHeader extends Vue.with(Props) {
  onBack() {
    if (this.backTo) {
      return this.$router.push(this.backTo)
    }
    this.$router.go(-1)
  }
}
</script>

<style scoped lang="scss">
.page-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.0125em;
}

.page-header {
  --min-height: 36px;
}

.page-header__secondary {
  --padding-bottom: 0px;
  --padding-end: 0px;
  --padding-start: 0px;
  --padding-top: 0px;
}

.ion-nav-icon {
  padding-top: 7px;
  font-size: 20px;
  color: #333333;
}
</style>
