<template>
  <IonModal :backdropDismiss="false" :isOpen="isOpen" cssClass="bill-filter-modal">
    <PageLayout :isScrollY="false" background="none" class="filter-wrapper">
      <IonRow>
        <IonCol size="12" class="under-line f-18">
          <b> {{ $t('label.filter') }}</b>
        </IonCol>
      </IonRow>

      <p class="ion-no-margin">{{ $t('bill.label.project') }}</p>
      <IonRow class="pb-2">
        <IonCol size="12">
          <IonSelect
            v-model="filter.projectId"
            :okText="$t('button.select')"
            :cancelText="$t('button.cancel')"
            :placeholder="$t('label.select_project')"
            :interfaceOptions="{ header: $t('label.select_project') }"
            style="
              border: 2px solid #01b3bf;
              border-radius: 5px;
              height: 27px;
              font-size: 16px;
            "
          >
            <IonSelectOption
              v-for="(project, key) in [...options.projects, ...projects]"
              :key="key"
              :value="project.id"
              color="primary"
              fill="outline"
            >
              {{
                project.id === 'all'
                  ? $t('bill.status.all')
                  : I18N(project.nameTh, project.nameEn)
              }}
            </IonSelectOption>
          </IonSelect>
        </IonCol>
      </IonRow>

      <p class="ion-no-margin">{{ $t('bill.label.status') }}</p>

      <IonRow class="pb-2">
        <IonButton
          v-for="(option, i) in options.state"
          :key="i"
          :fill="filter.state === option ? 'solid' : 'outline'"
          color="primary"
          size="small"
          class="f-16"
          @click="filter.state = option"
        >
          {{ $t(`bill.status.${option}`) }}
        </IonButton>
      </IonRow>

      <p class="ion-no-margin">{{ $t('bill.label.due_date') }}</p>
      <IonRow class="pb-2">
        <IonButton
          v-for="(option, i) in options.dueDate"
          :key="i"
          :fill="filter.dueAt === option ? 'solid' : 'outline'"
          color="primary"
          size="small"
          style="font-size: 16px"
          @click="selectDueDate(option)"
        >
          {{ $t(`bill.due_date.${option}`) }}
        </IonButton>

        <IonDatetime
          ref="dueDatePicker"
          v-model="filter.customDueDate"
          :max="options.maxCustomDueDate"
          class="d-none"
          displayFormat="MM/DD/YYYY"
          pickerFormat="MMMM YYYY"
          :monthNames="[
            $t('utils.months1'),
            $t('utils.months2'),
            $t('utils.months3'),
            $t('utils.months4'),
            $t('utils.months5'),
            $t('utils.months6'),
            $t('utils.months7'),
            $t('utils.months8'),
            $t('utils.months9'),
            $t('utils.months10'),
            $t('utils.months11'),
            $t('utils.months12')
          ]"
          @ionChange="filter.dueAt = 'custom'"
        />
      </IonRow>

      <!--<div class="footer pb-3">-->
      <IonRow class="ion-justify-content-center pb-4">
        <IonCol size="auto">
          <IonButton
            color="secondary"
            style="width: 125px; height: 30px; font-size: 16px"
            @click="$emit('close')"
          >
            {{ $t('button.cancel') }}
          </IonButton>
          <IonButton
            color="primary"
            style="width: 125px; height: 30px; font-size: 16px"
            @click="handleSubmit"
          >
            {{ $t('button.submit') }}
          </IonButton>
        </IonCol>
      </IonRow>
      <!--</div>-->
    </PageLayout>
  </IonModal>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { PageLayout } from '@/components/Layout'
import dayjs from '@/utils/dayjs'

import {
  IonButton,
  IonCard,
  IonCol,
  IonDatetime,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption
} from '@ionic/vue'

class Props {
  projects!: any[]
  isOpen!: boolean
  defaultOptions?: any
  defaultFilter?: any
  stateType!: string | 'unpaid' | 'unpaidGroup' | 'history'
  $refs!: any
}

@Component({
  components: {
    IonModal,
    IonCard,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonButton,
    PageLayout,
    IonDatetime,
    IonRow,
    IonCol
  },
  props: {
    projects: {
      type: Array
    },
    isOpen: {
      type: Boolean
    },
    defaultOptions: {
      type: Object,
      default: {}
    },
    defaultFilter: {
      type: Object,
      default: {}
    },
    stateType: {
      type: String
    }
  },
  emits: ['close', 'submit']
})
export default class BillFilter extends Vue.with(Props) {
  options = {
    projects: [{ id: 'all', name: 'All' }],
    state: ['all'],
    dueDate: ['last_30_days', 'last_90_days', 'last_180_days', 'all', 'custom'],
    maxCustomDueDate: dayjs().format('YYYY-MM')
  }

  filter = {
    projectId: null,
    state: 'all',
    dueAt: 'all',
    customDueDate: ''
  }

  billStates = {
    unpaid: ['overdue', 'pending_not_due', 'all'],
    unpaidGroup: ['overdue', 'pending_not_due', 'all'],
    history: ['paid', 'voided', 'processing', 'all']
  } as { [key: string]: any }

  mounted() {
    this.options.state = this.billStates[this.stateType]
    this.overwriteDefaultFilter()
  }

  handleSubmit() {
    this.$emit('submit')
  }

  overwriteDefaultFilter() {
    this.options = {
      ...this.options,
      ...this.defaultOptions
    }

    this.filter = {
      ...this.filter,
      ...this.defaultFilter
    }
  }

  formatFilter() {
    const filter = { ...this.filter } as { [key: string]: any }

    if (filter.projectId === 'all') {
      delete filter.projectId
    }

    if (filter.state === 'all') {
      filter.state = this.options.state.filter(state => state !== 'all')
    }

    if (filter.dueAt === 'custom') {
      filter.dueAt = this.filter.customDueDate
      delete filter.customDueDate
    } else if (filter.dueAt === 'all') {
      delete filter.dueAt
    }

    for (const key in filter) {
      if (!filter[key]) {
        delete filter[key]
      }
    }

    return filter
  }

  selectDueDate(value: string) {
    if (value === 'custom') {
      this.openCustomDueDatePicker()
    } else {
      this.filter.dueAt = value
      this.filter.customDueDate = ''
    }
  }

  openCustomDueDatePicker() {
    this.$refs.dueDatePicker.$el.click()
  }
}
</script>

<style lang="scss">
.bill-filter-modal {
  padding-top: calc(100vh - 450px);
}
</style>
