<template>
  <IonRow class="ion-justify-content-between ion-align-items-center">
    <IonCol size="auto" class="f-18 text-black">
      {{ $t('announcement.announcement') }}
    </IonCol>
    <IonCol size="auto" class="f-18">
      <IonButton
        size="small"
        class="f-12"
        color="primary"
        @click="$router.push('/announcements')"
      >
        {{ $t('home.see_more') }}
      </IonButton>
    </IonCol>
    <IonCol size="12" class="px-5 mt-4">
      <carousel
        :items-to-show="1"
        v-model="slideIndex"
        class="announcement-carousel"
        v-if="!!announcements.length"
      >
        <slide v-for="(announcement, i) in announcements" :key="i">
          <DesktopAnnouncementCard :announcement="announcement" />
        </slide>

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
      <carousel
        :items-to-show="1"
        v-model="slideIndex"
        class="announcement-carousel"
        v-else
      >
        <slide v-for="announcement in [defaultAnnouncement]" :key="announcement">
          <DesktopAnnouncementCard :announcement="announcement" />
        </slide>
      </carousel>
    </IonCol>
  </IonRow>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonButton } from '@ionic/vue'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import Card from '@/components/Card/Card.vue'
import DesktopAnnouncementCard from '@/components/desktop/Announcement/DesktopAnnouncementCard.vue'
class Props {
  announcements!: any[]
}

@Component({
  components: {
    DesktopAnnouncementCard,
    Card,
    IonRow,
    IonCol,
    IonButton,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  props: ['announcements']
})
export default class DesktopAnnouncement extends Vue.with(Props) {
  slideIndex = 0
  defaultAnnouncement = {
    highlightOrder: 1,
    isActive: true,
    _id: '618b406de2f423b50ac7823e',
    title: 'ยินดีตอนรับสู่ SkyCommercial',
    content: '',
    imagePaths: [],
    projectId: 2,
    userId: 1,
    companyId: 1,
    createdAt: '2021-11-10T03:45:49.581Z',
    updatedAt: '2021-11-09T03:45:49.582Z',
    company: {}
  }
}
</script>

<style lang="scss">
.carousel__next {
  transform: translate(50%, -100%);
}
.carousel__prev {
  transform: translate(-50%, -100%);
}
.announcement-carousel {
  /* Color */
  --vc-clr-primary: #01b3bf;
  --vc-clr-secondary: #abdcde;
  --vc-clr-white: #ffffff;

  /* Icon */
  --vc-icn-width: 2em;

  /* Navigation */
  --vc-nav-width: 40px;
  --vc-nav-color: #ffffff;
  --vc-nav-background-color: var(--vc-clr-primary);

  /* Pagination */
  --vc-pgn-width: 10px;
  --vc-pgn-height: 5px;
  --vc-pgn-margin: 5px;
  --vc-pgn-border-radius: 0;
  --vc-pgn-background-color: var(--vc-clr-secondary);
  --vc-pgn-active-color: var(--vc-clr-primary);
}
</style>
