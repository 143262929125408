<template>
  <PageLayout>
    <PageHeader :title="$t('home.navbar.settings')" :withBackButton="true" backTo="/" />
    <IonRow class="pt-3">
      <IonCol size="12">
        <SettingMenuItem @click="$router.push('/user')">
          {{ $t('setting.edit_profile') }}
        </SettingMenuItem>
        <SettingMenuItem @click="$router.push('/settings/change_language')">
          {{ $t('setting.change_language') }}
        </SettingMenuItem>
        <SettingMenuItem class="logout-text" @click="logOut">
          {{ $t('setting.log_out') }}
        </SettingMenuItem>
      </IonCol>
    </IonRow>
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonGrid, IonRow, IonCol, modalController } from '@ionic/vue'
import Profile from '@/components/ProfileBanner.vue'
import { PageLayout, PageHeader } from '@/components/Layout'
import ConfirmLogout from '@/components/settings/ConfirmLogout.vue'
import SettingMenuItem from '@/components/ListItem/SettingMenuItem.vue'

class Props {}

@Component({
  components: {
    PageLayout,
    PageHeader,
    Profile,
    IonGrid,
    IonRow,
    IonCol,
    SettingMenuItem
  },
  props: []
})
export default class Setting extends Vue.with(Props) {
  logoutModal = null as any

  async logOut() {
    this.logoutModal = await modalController.create({
      component: ConfirmLogout,
      cssClass: 'modal-windowed',
      componentProps: {
        closeModal: this.closeModal
      }
    })
    return this.logoutModal.present()
  }

  closeModal() {
    if (this.logoutModal)
      this.logoutModal.dismiss().then(() => {
        this.logoutModal = null
      })
  }
}
</script>

<style scoped lang="scss">
.logout-text {
  color: #d93838;
}
</style>
