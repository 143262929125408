<template>
  <Card
    :cardStyle="cardStyle"
    :cssStyle="cssStyle"
    :class="['bill-group-card', { highlighted: highlighted }]"
  >
    <IonGrid class="bill-group-content my-3">
      <IonRow class="bill-group-header ion-justify-content-between">
        <IonCol class="bill-group-number">
          {{ $t('bill.label.group') }} {{ invoiceGroup.number }}
        </IonCol>
      </IonRow>

      <!-- Minimized Content -->
      <IonRow
        v-if="minimized"
        class="minimized-content"
        @click="$router.push(`/bill_groups/${invoiceGroup._id}`)"
      >
        <IonCol size="7">
          <div
            v-for="(invoice, i) in invoiceGroup.invoices.slice(0, 5)"
            :key="i"
            class="bill-number read-more--1ln"
          >
            {{ invoice.number }}
          </div>
          <!--<div class="more-details">-->
          <!--  {{ $t('bill.label.more_details') }}-->
          <!--</div>-->
          <!--mockup fill height-->
          <br />
          <div v-for="i in 5 - invoiceGroup.invoices.length" :key="'br' + i">
            <br />
          </div>
        </IonCol>
        <IonCol size="5">
          <div class="bill-group-total-wrapper">
            <div>
              {{ invoiceGroup?.grandTotalAfterCreditNote?.fullDisplay }}
            </div>
            <!--            view detail button-->
            <IonButton class="btn-gradient view-details-button ">
              {{ $t('button.view_details') }}
            </IonButton>
          </div>
        </IonCol>
      </IonRow>

      <!-- Full Content -->
      <IonRow
        v-else
        v-for="(invoice, i) in invoiceGroup.invoices"
        :key="i"
        class="bill-content ion-justify-content-between"
        @click="$router.push(`/bill_groups/${invoiceGroup._id}`)"
      >
        <IonCol size="7">
          <div class="bill-number">{{ invoice.number }}</div>
          <CustomerDetails :invoice="invoice" />
          <div v-if="invoice?.totalCreditNoteCents" class="credit-note">
            <span>{{ $t('bill.label.credit_note') }} : </span>
            <span>{{ invoice?.totalCreditNote.fullDisplay }}</span>
          </div>
        </IonCol>
        <IonCol size="5">
          <div class="bill-grand-total">
            {{ invoice?.grandTotalAfterCreditNote?.fullDisplay }}
          </div>
        </IonCol>
      </IonRow>
      <IonRow v-if="!minimized" class="bill-group-total-wrapper">
        <IonCol size="6">
          {{ $t('bill.label.grand_total') }}
        </IonCol>
        <IonCol size="6">
          {{ invoiceGroup?.grandTotalAfterCreditNote?.fullDisplay }}
        </IonCol>
      </IonRow>

      <!-- Action Buttons -->
      <IonRow
        class="bill-group-action"
        v-if="!minimized && invoiceGroup.state === 'pending'"
      >
        <IonCol size="6">
          <IonButton
            class="action-button darker-secondary-button ungroup-button"
            expand="block"
            @click="$emit('ungroup', invoiceGroup._id)"
          >
            {{ $t('bill.label.ungroup') }}
          </IonButton>
        </IonCol>
        <IonCol size="6">
          <IonButton
            v-if="invoiceGroup?.state === 'paid' || invoiceGroup?.stat === 'processing'"
            class="btn-gradient view-details-button"
          >
            {{ $t('button.view_details') }}
          </IonButton>
          <IonButton
            v-else
            class="btn-gradient action-button"
            expand="block"
            @click="$router.push(`/bill_groups/${invoiceGroup._id}`)"
          >
            {{ $t('bill.label.pay_now') }}
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
    <BillState :invoice="invoiceGroup" style="position: absolute; top: 5px; right: 5px" />
  </Card>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonCard, IonCardContent, IonCol, IonRow, IonButton, IonGrid } from '@ionic/vue'
import SmallRedDot from '@/components/badge/SmallRedDot.vue'
import { Card } from '@/components/Card'
import BillState from '@/modules/bills/BillState.vue'
import CustomerDetails from '@/modules/bills/CustomerDetails.vue'
import _ from 'lodash'

class Props {
  invoiceGroup!: any
  cardStyle?: string
  cssStyle?: string
  highlighted?: boolean
  minimized?: boolean
}

@Component({
  components: {
    IonCard,
    IonCardContent,
    IonCol,
    IonRow,
    SmallRedDot,
    BillState,
    IonButton,
    IonGrid,
    Card,
    CustomerDetails
  },
  props: ['invoiceGroup', 'cardStyle', 'cssStyle', 'highlighted', 'minimized'],
  emits: ['ungroup']
})
export default class BillGroupCard extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
ion-card.bill-group-card {
  width: 100%;
  margin-bottom: 10px;

  .bill-group-header {
    .bill-group-number {
      white-space: nowrap;
      font-size: 18px;
      letter-spacing: 0px;
      font-weight: 500;
      color: #333333;
    }
    .bill-state-wrapper {
      padding-top: 2px;
    }
  }

  .bill-group-content {
    padding: 10px 10px 12px 10px;
  }

  .bill-content {
    margin-bottom: 8px;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #cacaca;
    font-size: 16px;
    font-family: Prompt;

    ion-col {
      .bill-number {
        font-size: 18px;
        color: $color-primary-dark;
      }

      .bill-grand-total {
        position: absolute;
        right: 0;
        bottom: 0;
        letter-spacing: 0px;
        color: #333333;
        font-weight: bold;
      }
    }
  }

  .credit-note {
    font-size: 14px;

    span:first-child {
      color: $color-primary;
    }
    span:last-child {
      color: $color-primary;
    }
  }

  .bill-group-total-wrapper {
    font-size: 18px;
    margin-bottom: 10px;

    ion-col:first-child {
      font-weight: 500;
      color: #333333;
    }

    ion-col:last-child {
      text-align: right;
      color: $color-primary-dark;
      font-weight: bold;
    }
  }

  .bill-group-action {
    ion-col {
      .action-button {
        --border-radius: 5px;
        text-transform: uppercase;
        font-size: 16px;
        width: 140px;
        height: 30px;
        --padding-top: 2px;
      }

      .ungroup-button {
        float: right;
      }
    }
  }

  .minimized-content {
    ion-col {
      .more-details {
        color: $color-primary-dark;
      }
      .bill-group-total-wrapper {
        vertical-align: bottom;
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: 0px;

        div {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          text-align: right;
        }
      }
    }
  }
}

.view-details-button {
  --border-radius: 4px;
  text-transform: uppercase;
  font-size: 12px;
  height: 30px;
  width: 110px;
  float: right;
  --padding-top: 2px;
  margin-bottom: 0px;
}

ion-card.bill-group-card.highlighted {
  border: 5px solid $color-primary;
}
</style>
