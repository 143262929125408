<template>
  <IonModal :isOpen="true" :backdropDismiss="false" size="large">
    <IonContent>
      <div class="pa-3 full-height">
        <!--header-->
        <IonRow class="ion-justify-content-between mb-4">
          <IonCol size="auto" class="f-18 text-primary">
            {{ title }}
          </IonCol>
          <IonCol size="auto" class="f-14 text-primary">
            <span
              :style="`color:${content === contentEn ? '$color-primary' : '#cdcdcd'} `"
              @click="content = contentEn"
            >
              EN
            </span>
            |
            <span
              :style="`color:${content === contentTh ? '$color-primary' : '#cdcdcd'} `"
              @click="content = contentTh"
            >
              ไทย
            </span>
          </IonCol>
          <IonCol size="12">
            <div class="title-underline"></div>
          </IonCol>
        </IonRow>
        <!--content-->
        <IonCard
          class="outlined ma-0 pa-2"
          style="height: calc(100% - 100px); !important; overflow-y: scroll;"
        >
          <div :id="id" v-html="content"></div>
        </IonCard>
        <IonRow class="ion-justify-content-end">
          <IonCol size="auto">
            <IonButton @click="handleClose" color="medium">
              {{ forceRead ? $t('button.accept') : $t('button.close') }}
            </IonButton>
          </IonCol>
        </IonRow>
      </div>
    </IonContent>
  </IonModal>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonButton, IonModal, IonContent, IonCard } from '@ionic/vue'

class Props {
  id!: string
  title!: string
  contentEn!: string
  contentTh!: string
  forceRead?: boolean
}

@Component({
  components: {
    IonRow,
    IonCol,
    IonButton,
    IonModal,
    IonContent,
    IonCard
  },
  props: ['id', 'title', 'contentEn', 'contentTh', 'forceRead'],
  emits: ['close', 'accept']
})
export default class TermsModal extends Vue.with(Props) {
  contentRead = false
  content = ''

  mounted() {
    if (this.$i18n.locale === 'en') {
      this.content = this.contentEn
    } else {
      this.content = this.contentTh
    }
  }

  handleScroll(event: any) {
    const currentPosition = event.target.scrollTop + event.target.clientHeight
    const contentHeight = event.target.scrollHeight

    const isAtBottom = currentPosition >= contentHeight
    if (isAtBottom && !this.contentRead && this.forceRead) {
      this.contentRead = true
    }
  }

  handleClose() {
    if (this.forceRead) {
      this.$emit('accept')
    }
    this.$emit('close')
  }
}
</script>

<style scoped lang="scss">
.title-underline {
  background: $color-primary 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 49px;
  height: 4px;
}
</style>
