<template>
  <ErrorModal v-on:close="$emit('close')">
    <template v-slot:content>
      <div>
        <strong>{{ $t('login.modal.login_error.unable_to_login') }}</strong>
      </div>
      <div>{{ $t('login.modal.login_error.please_contact_admin') }}</div>
    </template>
  </ErrorModal>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonButton } from '@ionic/vue'
import ErrorModal from '@/components/modals/ErrorModal.vue'

@Component({
  components: { IonButton, ErrorModal },
  props: ['errorCode'],
  emits: ['close']
})
export default class LogInErrorModal extends Vue {}
</script>

<style scoped lang="scss"></style>
