
import { Options as Component, Vue } from 'vue-class-component'
import { IonCol, IonRow, IonButton } from '@ionic/vue'
import { BillShowHeader } from '@/modules/bills'
import { PageLayout } from '@/components/Layout'
import { Card } from '@/components/Card'
import BillState from '@/modules/bills/BillState.vue'
import CustomerDetails from '@/modules/bills/CustomerDetails.vue'
import GrandTotalCard from '@/modules/bills/GrandTotalCard.vue'
import DocumentDownload from '@/modules/bills/DocumentDownload.vue'
import { PaymentsPanel } from '@/modules/bills'

@Component({
  components: {
    PageLayout,
    BillShowHeader,
    Card,
    BillState,
    CustomerDetails,
    GrandTotalCard,
    DocumentDownload,
    PaymentsPanel,
    IonCol,
    IonRow,
    IonButton
  },
  props: []
})
export default class BillGroupShow extends Vue {
  invoiceGroup = {} as any
  payment = {} as any

  get creditNoteCount() {
    if (!this.invoiceGroup) return 0
    return this.invoiceGroup.invoices?.reduce(
      (sum: number, invoice: any) => sum + invoice.creditNotes.length,
      0
    )
  }

  mounted() {
    this.fetchInvoiceGroup()
  }

  fetchInvoiceGroup() {
    this.$http.get(`/invoice/group/` + this.$route.params.id).then(res => {
      this.invoiceGroup = res?.data
      this.payment = this.invoiceGroup?.payment_groups?.find(
        (payment: any) => payment?.state === 'received'
      )
    })
  }
}
