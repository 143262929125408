import store from '@/store'
import { getPlatforms } from '@ionic/core'
const platform = {
  install(app: any, options: any) {
    console.log(getPlatforms(), 'platform')
    app.config.globalProperties.$platform = {
      is: {
        desktop: !getPlatforms().includes('ios') && !getPlatforms().includes('android')
      }
    }
  }
}
export default platform
