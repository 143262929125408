
import { Options as Component, Vue } from 'vue-class-component'
import { IonButton, IonRow, IonCol } from '@ionic/vue'

class Props {
  currentTab!: string
}

@Component({
  components: {
    IonButton,
    IonRow,
    IonCol
  },
  props: ['currentTab']
})
export default class BillTabBar extends Vue.with(Props) {}
