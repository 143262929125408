
import { Options as Component, Vue } from 'vue-class-component'
import { IonLabel } from '@ionic/vue'
import { IonPage, IonIcon, IonTabBar, IonTabButton, IonTabs } from '@ionic/vue'
import FreshChat from '@/components/FreshChat.vue'
class Props {
  $refs!: any
}

@Component({
  components: { IonPage, IonIcon, IonTabBar, IonTabButton, IonTabs, IonLabel, FreshChat }
})
export default class BottomNavigationMenu extends Vue.with(Props) {
  get freshChatData() {
    const { _id, isEnabled, freshChatToken, userTokens } =
      this.$store.state.currentProject?.freshChatIntegration || {}
    const userRestoreId = userTokens?.find(
      userToken => userToken?.userId == this.$store.state.user.id
    )?.restoreId
    return { _id, isEnabled, freshChatToken, userTokens, userRestoreId }
  }
}
