<template>
  <Attribute :label="label">
    <div class="text-area">
      <slot />
    </div>
  </Attribute>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import Attribute from './Attribute.vue'

class Props {
  label?: string
}

@Component({
  components: { Attribute },
  props: ['label']
})
export default class TextAreaAttribute extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
.text-area {
  white-space: pre-wrap;
}
</style>
