
import { Options as Component, Vue } from 'vue-class-component'
import { IonIcon, isPlatform } from '@ionic/vue'
import { PageLayout, PageHeader } from '@/components/Layout/'
class Props {}

@Component({
  components: { PageLayout, PageHeader, IonIcon },
  props: []
})
export default class Maintenance extends Vue.with(Props) {
  isIos() {
    return isPlatform('ios')
  }

  checkIframeLoading() {
    const iframeEle = document.getElementById('freshwidget-embedded-form')
    iframeEle!.addEventListener('load', function() {
      iframeEle!.style.opacity = 1 as any
    })
  }
  mounted() {
    let e = null
    ;(e = document.createElement('script')).id = 'freshwidget-embedded-form'
    ;(e.async = !0),
      (e.src = 'https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js'),
      document.head.appendChild(e)
    this.checkIframeLoading()
  }
}
