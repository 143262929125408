
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonImg } from '@ionic/vue'

class Props {
  src!: string
}

@Component({
  components: { IonRow, IonCol, IonImg },
  props: {
    src: {
      default: ['https://th-test-11.slatic.net/p/5568633b84a71986d67de8c5f290ddae.jpg'],
      type: Array
    }
  }
})
export default class CardImages extends Vue.with(Props) {}
