<template>
  <PageLayout :background="'color'">
    <IonRow
      class="ion-align-items-center ion-align-self-center fill-height"
      style="height: 80vh"
    >
      <IonCol size="12">
        <IonRow class="ion-justify-content-center my-4">
          <IonCol size="auto">
            <img src="/assets/sky_logo.svg" width="170" alt="logo" />
          </IonCol>
        </IonRow>
        <IonRow class="ion-justify-content-center my-4">
          <IonCol size="12" class="ion-text-center">
            โปรดเลือกภาษาในการใช้งาน
          </IonCol>
          <IonCol size="12" class="ion-text-center">
            Please select the language
          </IonCol>
        </IonRow>
        <!--Language switch-->
        <LanguageBadgeSwitch class="my-4" size="4" />
        <IonRow class="ion-justify-content-center my-4">
          <IonCol size="auto">
            <IonButton class="btn btn-gradient" @click="startedBtnClicked()">
              {{ $t('login.label.get_started') }}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import PageLayout from '@/components/Layout/PageLayout.vue'
import {
  IonPage,
  IonContent,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonButton,
  IonCard,
  IonImg
} from '@ionic/vue'
import LanguageBadgeSwitch from '@/components/language/LanguageBadgeSwitch.vue'

class Props {}

@Component({
  components: {
    LanguageBadgeSwitch,
    PageLayout,
    IonPage,
    IonContent,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonItem,
    IonButton,
    IonCard
  },
  props: []
})
export default class LandingPage extends Vue.with(Props) {
  startedBtnClicked() {
    try {
      console.log('STARTD: get start')
      this.$router.push('/login')
    } catch (e) {
      console.log('STARTD: get start error', e)
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  width: 200px;
  height: 43px;
  font-size: 16px;
}
</style>
