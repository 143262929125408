
import { Options as Component, Vue } from 'vue-class-component'
import { PageLayout, PageHeader } from '@/components/Layout'
import ProjectCard from '@/components/ProjectCard.vue'

class Props {}

@Component({
  components: { PageLayout, PageHeader, ProjectCard },
  props: []
})
export default class MyProjects extends Vue.with(Props) {
  mounted() {
    this.fetchProjects()
  }
  fetchProjects() {
    this.$http.get('/capi/project').then(res => {
      this.$store.state.projects = res.data
    })
  }
}
