
import { Options as Component, Vue } from 'vue-class-component'
import { IonCardContent } from '@ionic/vue'

import { Attribute } from '@/components/Attribute'

class Props {
  invoice?: any | {}
  wrapper?: string | 'well'
}

@Component({
  components: { IonCardContent, Attribute },
  props: ['invoice', 'wrapper']
})
export default class CustomerDetails extends Vue.with(Props) {
  get unitNames() {
    const unit = this.invoice?.invoiceUnits
    if (Array.isArray(unit)) {
      return unit.map(unit => unit.unitName).join(' / ')
    }
    return ''
  }
}
