<template>
  <Card class="mb-5 ma-0" cssClass="pa-1">
    <IonGrid>
      <!--images-->
      <CardImages :src="data.imagePaths" class="mb-2" />
      <!--project name + time-->
      <div>
        <IonText color="primary">
          {{ data.project?.name || data.company?.name }}
        </IonText>
        <IonText class="text-grey"> - {{ DATE_TIME_RELATIVE(data.updatedAt) }} </IonText>
      </div>
      <!--title-->
      <h5 class="my-1 text-black">{{ data.title }}</h5>
      <!--content-->

      <p class="pa-0 ma-0 text-grey read-more" v-html="contentRender(data.content)"></p>
      <IonRow class="ion-justify-content-end">
        <IonCol size="auto">
          <IonText class="pa-0" color="primary">
            <b>
              {{ $t('button.read_more') }}
            </b>
          </IonText>
        </IonCol>
      </IonRow>
    </IonGrid>
  </Card>
</template>
<script lang="ts">
import Card from '@/components/Card/Card.vue'
import CardImages from '@/components/image/CardImages.vue'
import { Options as Component, Vue } from 'vue-class-component'
import Filter from '@/components/filters/Filter.vue'
import BaseField from '@/components/fields/BaseField.vue'
import { PageHeader, PageLayout } from '@/components/Layout'
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonInput,
  IonItem,
  IonModal,
  IonRow,
  IonText
} from '@ionic/vue'

class Props {
  data!: {}
}
@Component({
  components: {
    Filter,
    CardImages,
    Card,
    BaseField,
    PageHeader,
    PageLayout,
    IonGrid,
    IonButton,
    IonRow,
    IonCol,
    IonInput,
    IonItem,
    IonIcon,
    IonText,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonModal,
    IonContent
  },
  props: ['data']
})
export default class ContentCard extends Vue.with(Props) {
  contentRender(content: string) {
    if (content) {
      let linkStart = content.search('target="_blank">')
      let linkEnd = content.search('</a>')

      // no link found in content
      if (linkStart < 0) return content.replace(/<[^>]*>/g, '')

      content =
        content.substr(0, linkStart + 16) +
        'LINK_START' +
        content.substr(linkStart + 16, linkEnd - (linkStart + 16)) +
        'LINK_END' +
        content.substr(linkEnd)

      content = content.replace(/<[^>]*>/g, '')

      linkStart = content.search('LINK_START')
      linkEnd = content.search('LINK_END')

      content =
        content.substr(0, linkStart) +
        '<span style="color: #01B3BF !important">' +
        content.substr(linkStart + 10, linkEnd - (linkStart + 10)) +
        '</span>' +
        content.substr(linkEnd + 8)

      return content
    } else {
      return ''
    }
  }
}
</script>
