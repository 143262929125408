
import { Options as Component, Vue } from 'vue-class-component'
import { IonCard, IonText, IonIcon, IonRow, IonCol } from '@ionic/vue'

import { GbPrimeCardModal, GbPrimeQrCodeModal, GbPrimeMobileBankingModal } from './modals'
import firebase from 'firebase/app'
import CenterVerticalAlign from '@/components/CenterVerticalAlign.vue'

class Props {
  invoice!: any
}

@Component({
  components: {
    IonCard,
    IonText,
    IonIcon,
    IonRow,
    IonCol,
    CenterVerticalAlign,
    GbPrimeCardModal,
    GbPrimeQrCodeModal,
    GbPrimeMobileBankingModal
  },
  props: ['invoice'],
  emits: ['billPaid']
})
export default class PaymentsPanel extends Vue.with(Props) {
  modalOpen = {
    gbPrimeCard: false,
    gbPrimeQrCode: false,
    gbPrimeMobileBanking: false
  } as any

  transactionListener = () => {}

  async mounted() {
    // init firebase
    await firebase.firestore().collection('payment_transactions')
  }

  get paymentMethods() {
    const projectPaymentMethods = this.invoice?.projectPaymentMethods || []
    return projectPaymentMethods.map((projectPaymentMethod: any) => {
      const totalFee = projectPaymentMethod?.charge?.totalFee
      const { paymentMethod } = projectPaymentMethod
      const paymentMethodName = `${paymentMethod?.brand}_${paymentMethod?.method}`
      const label = this.$t(`payment_method.${paymentMethod.method}`)

      const isUnderPrice = projectPaymentMethod.isUnderPrice
      const isActive = projectPaymentMethod.isActive
      const isOpened = projectPaymentMethod.isOpened
      const openedAt = paymentMethod.paymentTimeOpenedAt

      const iconName = isOpened ? paymentMethodName : `${paymentMethodName}_grey`

      const showUnderPriceWarning = () =>
        alert(`Minimum payment is ${paymentMethod.minimumRate} Baht`)

      const paymentPanel = {
        label,
        iconName,
        totalFee,
        isActive,
        isOpened,
        openedAt,
        openPaymentModal: () => {},
        action: () => {
          if (isUnderPrice) {
            showUnderPriceWarning()
          } else {
            paymentPanel.openPaymentModal()
            this.showPaymentDelayWarning()
          }
        }
      }

      switch (paymentMethodName) {
        case 'gb_prime_card':
          paymentPanel.openPaymentModal = () => (this.modalOpen.gbPrimeCard = true)
          return paymentPanel

        case 'gb_prime_qr_code':
          paymentPanel.openPaymentModal = () => (this.modalOpen.gbPrimeQrCode = true)
          return paymentPanel

        case 'gb_prime_mobile_banking':
          paymentPanel.openPaymentModal = () =>
            (this.modalOpen.gbPrimeMobileBanking = true)
          return paymentPanel

        default:
          return paymentPanel
      }
    })
  }

  emitReload() {
    this.$emit('reload')
  }

  showPaymentDelayWarning() {
    // set delay to show warning modal on top of any other modal
    setTimeout(() => {
      this.$store.state.modal.alert.content = this.$t('bill.modal.payment_delay_warning')
      this.$store.state.modal.alert.isOpen = true
    }, 100)
  }

  handleCloseModal(modalName: string) {
    if (modalName === 'all') {
      for (const modalName in this.modalOpen) {
        this.modalOpen[modalName] = false
      }
    } else {
      this.modalOpen[modalName] = false
    }
    // unsubscribe firebase payment transaction listener
    this.transactionListener && this.transactionListener()
  }

  async createTransaction(paymentReference: any, data = {}) {
    try {
      await firebase
        .firestore()
        .collection('payment_transactions')
        .doc(paymentReference)
        .set(data)

      this.transactionListener = firebase
        .firestore()
        .collection('payment_transactions')
        .doc(paymentReference)
        .onSnapshot(snapshot => {
          if (!snapshot.exists && !snapshot.data()) {
            this.handleCloseModal('all')
            this.$emit('billPaid')
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  scrollToTop() {
    const ionContent = document.querySelector('ion-content')
    ionContent && ionContent.scrollToTop(500)
  }
}
